import { forwardRef } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteResource = ({ open, handleClose, handleSubmit, resourceToBeDeleted }) => {

    return( 
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="Delete Resource Dialog"
        >
                <DialogTitle sx={{fontWeight: 'bold', fontSize: '20px',color: 'primary.main'}}>Delete Resource</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom variant='h5' textAlign={'center'}>{'Are you sure you want to delete this resource?'}</Typography>
                    <Typography gutterBottom variant='h6' textAlign={'center'} fontStyle='italic'>{!!resourceToBeDeleted && resourceToBeDeleted.name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button type='button' color="primary" variant='contained' onClick={handleClose}>No</Button>
                    <Button type='submit' color="primary" variant='contained' onClick={handleSubmit}>Yes</Button>
                </DialogActions>
        </Dialog>
    )
}

export default DeleteResource;