import { Helmet } from 'react-helmet';
import ReportComponent from '../../components/reports/Report-iframeComponent'
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Typography, Box } from '@mui/material';


const BreezeNet = () => {
    const [loading, loader] = useState(false)
    const [url, setUrl] = useState('')

    const fetchUrl = async() => {
        loader(true);
        try{
            const response = await axios.post('sem',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setUrl(response.data.data.url)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    useEffect(() => {
        fetchUrl();
    }, [])

    return(
    <>
        <Helmet>
            <title>BreezeNet | BreezeDash</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
        {!!url ? <ReportComponent product='BreezeNet (Google Ads)' url={url} /> : <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>}
        </Box>
        {loading && <Loader />}
    </>
    )
};

export default BreezeNet;