import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Fab, Typography } from '@mui/material';
import Loader from '../auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../auxiliary/toaster'
import ProspectList from './ProspectList';
import CreateLeadDialog from './CreateLeadDialog';
import AddIcon from '@mui/icons-material/Add';

const initLeadObj = {
  id:0,
  client_name: '',
  client_email: ''
}

const BreezeproLeads = ({createProposal, ...rest}) => {

    const [loading, loader] = useState(false);
    const { authToken } = useSelector(state => state.auth);
    const [leads, setLeads] = useState([])
    const [createLeadDialogOpen, setCreateLeadDialogOpen] = useState(false);
    const [leadToBeEdited, setLeadToBeEdited] = useState(initLeadObj);

    const fetchProfiles = useCallback(async() => {
        try{
            loader(true);
            const response = await axios.get('leads',{headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                setLeads(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
            }else{
              toaster(false, "An error occurred while fetching the prospects!")
            }
          }catch(err){
            toaster(false, "An error occurred while fetching the prospects!")
          }finally{
            loader(false);
          }
    },[authToken])

    const createNewLead = async(values, {resetForm}) => {
      try{
          loader(true);
          const response = await axios.post('leads', values, {headers: {'Authorization': 'Bearer '+ authToken}});
          if(response?.data){
            if(response.data.success){
              fetchProfiles();
              setCreateLeadDialogOpen(false);
              resetForm();
            }
            toaster(response.data.success, response.data.message)
          }else{
            toaster(false, "An error occurred while creating the prospect!")
          }
      }catch(err){
          toaster(false, "An error occurred while creating the prospect!")
      }finally{
          loader(false);
      }
    }

    const handleEditLead = (prospect) => {
      setLeadToBeEdited(prospect);
      setCreateLeadDialogOpen(true);
    }

    const handleDialogClose = () => {
      setCreateLeadDialogOpen(false);
      if(leadToBeEdited.id){
        setLeadToBeEdited(initLeadObj);
      }
    }

    const updateLead = async(values, {resetForm}) => {
      try{
        loader(true);
        let postData = {
          ...values,
          _method: 'put'
        }
        const response = await axios.post('leads/'+leadToBeEdited.id, postData, {headers: {'Authorization': 'Bearer '+ authToken}});
        if(response?.data){
          if(response.data.success){
            fetchProfiles();
            setCreateLeadDialogOpen(false);
            resetForm();
          }
          toaster(response.data.success, response.data.message)
        }else{
          toaster(false, "An error occurred while updating the prospect!")
        }
      }catch(err){
        toaster(false, "An error occurred while updating the prospect!")
      }finally{
        loader(false);
      }
    }

    const handleDeleteLead = async(prospect) => {
      try{
        const response = await axios.delete('leads/'+prospect.id, {headers: {'Authorization': 'Bearer '+ authToken}});
        if(response?.data){
          if(response.data.success){
            fetchProfiles();
          }
          toaster(response.data.success, response.data.message)
        }else{
          toaster(false, "An error occurred while deleting the prospect!")
        }
      }catch(err){
        toaster(false, "An error occurred while deleting the prospect!")
      }finally{
        loader(false);
      }
    }

    useEffect(() => {
      fetchProfiles()
    }, [fetchProfiles])

    return (
      <>
      <Container maxWidth={false}>
        <Typography variant='h2'>Prospects List</Typography>
        <Box sx={{ pt: 3 }}>
            <ProspectList prospects={leads} editLead={handleEditLead} deleteLead={handleDeleteLead} createProposal={createProposal} />
        </Box>
      </Container>
      <Fab onClick={() => setCreateLeadDialogOpen(true)} sx={{position: 'fixed', bottom: '16px', right: '24px', boxShadow: '0 0 1px 0 #888, 0 4px 6px -2px #888'}} color="primary" aria-label="add" variant='extended'>
          <AddIcon sx={{ mr: 1 }} />
          Create new Proposal
      </Fab>
      <CreateLeadDialog open={createLeadDialogOpen} handleClose={handleDialogClose} handleCreateLead={createNewLead} handleEditLead={updateLead} leadToBeEdited={leadToBeEdited} />
      { loading && <Loader /> }
      </>
    )
}

export default BreezeproLeads;