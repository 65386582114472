import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles'
import { ArrowDownCircle as DownTrend, ArrowUpCircle as UpTrend } from 'react-feather'

const TableHeadCell = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    fontSize: '16px'
  }
}))(TableCell)

const KeywordsRanking = ({ keywords, ...rest }) => {

  const sortBasedOnCurrentRank = (keyword1, keyword2) => {
    if(!keyword1.currRank){
      return 1
    }else if(!keyword2.currRank){    
      return -1
    }
    return keyword1.currRank - keyword2.currRank;
  }

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {keywords.length ? <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>
                  Keywords
                </TableHeadCell>
                <TableHeadCell>
                  Region
                </TableHeadCell>
                <TableHeadCell>
                  Current Rank
                </TableHeadCell>
                <TableHeadCell>
                  Old Rank
                </TableHeadCell>
                <TableHeadCell>
                  Difference
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywords.sort(sortBasedOnCurrentRank).map((keyword) => (
                <TableRow
                  hover
                  key={keyword.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {keyword.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                        {keyword.region}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                        {keyword.currRank}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                        {keyword.oldRank}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {keyword.trend === 'up' ? <UpTrend color='#25ab25' /> : <DownTrend color='#c71e1e' />}
                      <Typography
                          color="textPrimary"
                          variant="body1"
                          ml={1}
                          >
                          {keyword.difference}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> :
          <Typography variant='h5' sx={{textAlign: 'center',p:2}}>No data available!</Typography>}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

KeywordsRanking.propTypes = {
  keywords: PropTypes.array.isRequired
};

export default KeywordsRanking;
