import { useFormikContext, Field } from 'formik';
import {
    Box,
    Card,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    Checkbox,
    RadioGroup,
    FormGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const BreezeTrackProducts = [
    {
        name: 'BreezePix',
        description: 'Social Media Analytics and Tracking',
        payment_type: 'One-time Payment',
        cost: '$499'
    },
    {
        name: 'BreezeTrail',
        description: 'Recorded call tracking',
        payment_type: 'Monthly Payment',
        cost: '$75/m'
    },
    {
        name: 'BreezeHeat',
        description: 'Heat Map Tracking',
        payment_type: 'Monthly Payment',
        cost: '$99/m'
    },
    {
        name: 'BreezeWho',
        description: 'B2B Website Tracking',
        payment_type: 'Monthly Payment',
        cost: '$199/m'
    }
]

const BoxLabel = ({name, description, payment_type, cost}) => (
    <Box py={1}>
        <Typography variant='body1'>{name+'® '+cost}</Typography>
        <Typography variant='subtitle2'>{description}</Typography>
        <Typography variant='caption'>{'('+payment_type+')'}</Typography>
    </Box>
)

const BreezeSocial = ({noteMaxLimit}) => {
    const { errors, touched, values, handleBlur, handleChange } = useFormikContext();
    const { breezesocial: breezesocialValues } = values;
    const breezesocialErrors = errors.breezesocial || {};
    const breezesocialTouched = touched.breezesocial || {};

    return (
        <Card sx={{my: 2, p:2, boxShadow: 2}} variant='outlined'>
            <Typography my={1} variant='h3' fontWeight={700}>BreezeSocial</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Geo Targeting"
                        name="breezesocial.geo_targeting"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.geo_targeting}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.geo_targeting && breezesocialErrors.geo_targeting)}
                        helperText={breezesocialTouched.geo_targeting && breezesocialErrors.geo_targeting}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Number of Key Phrases"
                        name="breezesocial.number_of_key_phrases"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.number_of_key_phrases}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.number_of_key_phrases && breezesocialErrors.number_of_key_phrases)}
                        helperText={breezesocialTouched.number_of_key_phrases && breezesocialErrors.number_of_key_phrases}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Schedule"
                        name="breezesocial.schedule"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.schedule}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.schedule && breezesocialErrors.schedule)}
                        helperText={breezesocialTouched.schedule && breezesocialErrors.schedule}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Industry"
                        name="breezesocial.industry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.industry}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.industry && breezesocialErrors.industry)}
                        helperText={breezesocialTouched.industry && breezesocialErrors.industry}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required variant="outlined">
                        <FormLabel component="legend">Social Media Platforms</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesocial.breezepost_platform'} onChange={handleChange} value='Facebook' />
                                }
                                label='Facebook'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesocial.breezepost_platform'} onChange={handleChange} value='Instagram' />
                                }
                                label='Instagram'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesocial.breezepost_platform'} onChange={handleChange} value='Linkedin' />
                                }
                                label='Linkedin'
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Media Buy (Monthly budget)"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name="breezesocial.media_buy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.media_buy}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.media_buy && breezesocialErrors.media_buy)}
                        helperText={breezesocialTouched.media_buy && breezesocialErrors.media_buy}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Starting Budget"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name="breezesocial.start_budget"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezesocialValues.start_budget}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.start_budget && breezesocialErrors.start_budget)}
                        helperText={breezesocialTouched.start_budget && breezesocialErrors.start_budget}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Additional Notes"
                        InputProps={{sx: {minHeight: '80px'}}}
                        inputProps={{maxLength: noteMaxLimit}}
                        name="breezesocial.additional_notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.additional_notes}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.additional_notes && breezesocialErrors.additional_notes)}
                        helperText={breezesocialTouched.additional_notes && breezesocialErrors.additional_notes}
                    />
                    <Typography variant='caption'>{`${breezesocialValues.additional_notes.length}/${noteMaxLimit}`}</Typography>
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>BreezePost (Social Media Management)</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Number of posts"
                        name="breezesocial.breezepost_number_posts"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.breezepost_number_posts}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.breezepost_number_posts && breezesocialErrors.breezepost_number_posts)}
                        helperText={breezesocialTouched.breezepost_number_posts && breezesocialErrors.breezepost_number_posts}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Cost per month"
                        name="breezesocial.breezepost_monthly_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.breezepost_monthly_cost}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.breezepost_monthly_cost && breezesocialErrors.breezepost_monthly_cost)}
                        helperText={breezesocialTouched.breezepost_monthly_cost && breezesocialErrors.breezepost_monthly_cost}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>BreezeTrack</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container>
                {
                    BreezeTrackProducts.map(product => (
                        <Grid key={product.name} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                sx={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox name={'breezesocial.breezetrack'} onChange={handleChange} value={product.name} />
                                }
                                label={<BoxLabel {...product} />}
                            />
                        </Grid>
                    ))
                }
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>Creative Packages</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Sets of Ads"
                        name="breezesocial.creative_sets"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.creative_sets}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.creative_sets && breezesocialErrors.creative_sets)}
                        helperText={breezesocialTouched.creative_sets && breezesocialErrors.creative_sets}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Cost per month"
                        name="breezesocial.creative_monthly_rate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.creative_monthly_rate}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.creative_monthly_rate && breezesocialErrors.creative_monthly_rate)}
                        helperText={breezesocialTouched.creative_monthly_rate && breezesocialErrors.creative_monthly_rate}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Creative and Research Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Setup Fee"
                        name="breezesocial.setup_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.setup_fee}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.setup_fee && breezesocialErrors.setup_fee)}
                        helperText={breezesocialTouched.setup_fee && breezesocialErrors.setup_fee}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Agency Retainer Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Fee Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Fee Type"
                        >
                            <FormControlLabel name="breezesocial.AR_fee_type" value="fixed" control={<Field required type='radio' component={MyRadioInput} />} label="Fixed ($)" />
                            <FormControlLabel name="breezesocial.AR_fee_type" value="percent" control={<Field required type='radio' component={MyRadioInput} />} label="Variable (%)" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{breezesocialValues.AR_fee_type === 'fixed' ? '$' : '%'}</InputAdornment>,
                        }}
                        required
                        label="A.R Fee"
                        name="breezesocial.AR_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.AR_fee}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.AR_fee && breezesocialErrors.AR_fee)}
                        helperText={breezesocialTouched.AR_fee && breezesocialErrors.AR_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Term (minimum 1 year)"
                        name="breezesocial.breezesocial_term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesocialValues.breezesocial_term}
                        variant="outlined"
                        error={Boolean(breezesocialTouched.breezesocial_term && breezesocialErrors.breezesocial_term)}
                        helperText={breezesocialTouched.breezesocial_term && breezesocialErrors.breezesocial_term}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Payment Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Payment Type"
                        >
                            <FormControlLabel name="breezesocial.payment_type" value="Credit Card" control={<Field required type='radio' component={MyRadioInput} />} label="Credit Card" />
                            <FormControlLabel name="breezesocial.payment_type" value="Void Cheque" control={<Field required type='radio' component={MyRadioInput} />} label="Void Cheque" />
                            <FormControlLabel name="breezesocial.payment_type" value="e-Transfer" control={<Field required type='radio' component={MyRadioInput} />} label="e-Transfer" />
                            <FormControlLabel name="breezesocial.payment_type" value="Term Paid in Full" control={<Field required type='radio' component={MyRadioInput} />} label="Term Paid in Full" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BreezeSocial;