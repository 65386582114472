import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { Grid, FormControl, Select, MenuItem, InputLabel, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
const FileInput = styled('input')({
    display: 'none',
});

const AttachmentBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: '5px',
    margin: '4px 0px'
})

const CreateResource = ({ resourceCategories, open, handleClose, handleCreateResource, handleEditResource, resourceToBeEdited, fileInputRef, fileUploadHandler, attachment }) => {

    let isEditing = false;
    let initialValues = {
        id: 0,
        name: '',
        category: ''
    }
    if(resourceToBeEdited.id){
        isEditing = true;
        initialValues = {
            id: resourceToBeEdited.id,
            name: resourceToBeEdited.name,
            category: resourceToBeEdited.category
        }
    }

    return( 
        <Dialog
            open={open}
            fullWidth
            maxWidth='md'
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(100).required('This field is mandatory'),
                    category: Yup.string().max(10).required('This field is mandatory')
                })}
                onSubmit={ isEditing ? handleEditResource : handleCreateResource }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <Box component='form' onSubmit={handleSubmit}>
                        <DialogTitle sx={{fontWeight: 'bold', fontSize: '20px'}}>{isEditing ? "Edit Resource" : "Create New Resource"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Enter the resource name and select a category
                            </DialogContentText>
                                <Grid container spacing={2}>
                                    <input hidden id='id' name='id' value={values.id} onChange={handleChange} />
                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            name='name'
                                            label="Resource Name"
                                            fullWidth
                                            variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="category">Category</InputLabel>
                                            <Select
                                                labelId="category"
                                                id="category"
                                                name='category'
                                                value={values.category}
                                                label="Category"
                                                onChange={handleChange}
                                                required
                                            >
                                                {
                                                    resourceCategories.map(category => (
                                                        <MenuItem key={category.name} value={category.value}>{category.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                    >
                                        {
                                            attachment && 
                                            <AttachmentBox>
                                                <Typography>{attachment.name}</Typography>
                                            </AttachmentBox>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label htmlFor='attachments'>
                                            <FileInput ref={fileInputRef} onChange={fileUploadHandler} accept="image/*, application/pdf" id="attachments" multiple type="file" />
                                            <Button
                                                variant="contained"
                                                component="span"
                                                startIcon={<AttachFileOutlinedIcon />}
                                            >
                                                Attach file
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type='button' color="primary" variant='contained' onClick={handleClose}>Cancel</Button>
                            <Button type='submit' color="primary" variant='contained'  disabled={isSubmitting}>Save</Button>
                        </DialogActions>
                    </Box>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateResource;