import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../auxiliary/toaster';
import Loader from '../auxiliary/loader/loader';

const regexPhone = "^([+]?[0-9]{1,2})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$";

const AccountProfileDetails = (props) => {

  const [loading, loader] = useState(true)
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });
  
  const fetchProfileDetails = async() => {
    try{
      const response = await axios.get('my_profile',{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          setValues(response.data.data)
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while fetching the profile details!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while fetching the profile details!")
      loader(false);
    }
  }

  const updateProfileDetails = async(values) => {
    try{
      loader(true)
      const response = await axios.post('update_my_profile',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Profile details updated successfully!')
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while updating the profile details!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while updating the profile details!")
      loader(false);
    }
  }

  useEffect(() => {
    fetchProfileDetails();
  }, [])

  return (
    <>
    <Formik
      enableReinitialize
      initialValues={{
        ...values,
        phone: values.phone ?? ''
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().max(100).required('First Name is required'),
        last_name: Yup.string().max(100).required('Last Name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        phone: Yup.string().matches(regexPhone,'Enter a valid phone number').min(8)
      })}
      onSubmit={updateProfileDetails}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Card>
            <CardHeader
              subheader="The profile information can be edited"
              title="Profile Details"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="First name"
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.first_name}
                    variant="outlined"
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Last name"
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.last_name}
                    variant="outlined"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled
                    value={values.email}
                    variant="outlined"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
    {loading && <Loader />}
    </>
  );
};

export default AccountProfileDetails;
