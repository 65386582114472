import { useFormikContext, Field } from 'formik';
import {
    Box,
    Card,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const BreezeTrackProducts = [
    {
        name: 'BreezeTag',
        description: 'Google Analytics and Search console',
        payment_type: 'One-time Payment',
        cost: '$499'
    },
    {
        name: 'BreezeTrail',
        description: 'Recorded call tracking',
        payment_type: 'Monthly Payment',
        cost: '$75/m'
    },
    {
        name: 'BreezeHeat',
        description: 'Heat Map Tracking',
        payment_type: 'Monthly Payment',
        cost: '$99/m'
    },
    {
        name: 'BreezeWho',
        description: 'B2B Website Tracking',
        payment_type: 'Monthly Payment',
        cost: '$199/m'
    }
]

const BoxLabel = ({name, description, payment_type, cost}) => (
    <Box py={1}>
        <Typography variant='body1'>{name+'® '+cost}</Typography>
        <Typography variant='subtitle2'>{description}</Typography>
        <Typography variant='caption'>{'('+payment_type+')'}</Typography>
    </Box>
)

const BreezeNet = ({noteMaxLimit}) => {
    const { errors, touched, values, handleBlur, handleChange } = useFormikContext();
    const { breezenet: breezenetValues } = values;
    const breezenetErrors = errors.breezenet || {};
    const breezenetTouched = touched.breezenet || {};

    return (
        <Card sx={{my: 2, p:2, boxShadow: 2}} variant='outlined'>
            <Typography my={1} variant='h3' fontWeight={700}>BreezeNet</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Geo Targeting"
                        name="breezenet.geo_targeting"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.geo_targeting}
                        variant="outlined"
                        error={Boolean(breezenetTouched.geo_targeting && breezenetErrors.geo_targeting)}
                        helperText={breezenetTouched.geo_targeting && breezenetErrors.geo_targeting}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Number of Key Phrases"
                        name="breezenet.number_of_key_phrases"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.number_of_key_phrases}
                        variant="outlined"
                        error={Boolean(breezenetTouched.number_of_key_phrases && breezenetErrors.number_of_key_phrases)}
                        helperText={breezenetTouched.number_of_key_phrases && breezenetErrors.number_of_key_phrases}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Schedule"
                        name="breezenet.schedule"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.schedule}
                        variant="outlined"
                        error={Boolean(breezenetTouched.schedule && breezenetErrors.schedule)}
                        helperText={breezenetTouched.schedule && breezenetErrors.schedule}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Industry"
                        name="breezenet.industry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.industry}
                        variant="outlined"
                        error={Boolean(breezenetTouched.industry && breezenetErrors.industry)}
                        helperText={breezenetTouched.industry && breezenetErrors.industry}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Media Buy (Monthly budget)"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name="breezenet.media_buy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.media_buy}
                        variant="outlined"
                        error={Boolean(breezenetTouched.media_buy && breezenetErrors.media_buy)}
                        helperText={breezenetTouched.media_buy && breezenetErrors.media_buy}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Starting Budget"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name="breezenet.start_budget"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={breezenetValues.start_budget}
                        variant="outlined"
                        error={Boolean(breezenetTouched.start_budget && breezenetErrors.start_budget)}
                        helperText={breezenetTouched.start_budget && breezenetErrors.start_budget}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Additional Notes"
                        InputProps={{sx: {minHeight: '80px'}}}
                        inputProps={{maxLength: noteMaxLimit}}
                        name="breezenet.additional_notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.additional_notes}
                        variant="outlined"
                        error={Boolean(breezenetTouched.additional_notes && breezenetErrors.additional_notes)}
                        helperText={breezenetTouched.additional_notes && breezenetErrors.additional_notes}
                    />
                    <Typography variant='caption'>{`${breezenetValues.additional_notes.length}/${noteMaxLimit}`}</Typography>
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>BreezeTrack</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container>
                {
                    BreezeTrackProducts.map(product => (
                        <Grid key={product.name} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                sx={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox name={'breezenet.breezetrack'} onChange={handleChange} value={product.name} />
                                }
                                label={<BoxLabel {...product} />}
                            />
                        </Grid>
                    ))
                }
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>Creative Packages</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Sets of Ads"
                        name="breezenet.creative_sets"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.creative_sets}
                        variant="outlined"
                        error={Boolean(breezenetTouched.creative_sets && breezenetErrors.creative_sets)}
                        helperText={breezenetTouched.creative_sets && breezenetErrors.creative_sets}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Cost per month"
                        name="breezenet.creative_monthly_rate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.creative_monthly_rate}
                        variant="outlined"
                        error={Boolean(breezenetTouched.creative_monthly_rate && breezenetErrors.creative_monthly_rate)}
                        helperText={breezenetTouched.creative_monthly_rate && breezenetErrors.creative_monthly_rate}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Creative and Research Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Setup Fee"
                        name="breezenet.setup_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.setup_fee}
                        variant="outlined"
                        error={Boolean(breezenetTouched.setup_fee && breezenetErrors.setup_fee)}
                        helperText={breezenetTouched.setup_fee && breezenetErrors.setup_fee}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Agency Retainer Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Fee Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Fee Type"
                        >
                            <FormControlLabel name="breezenet.AR_fee_type" value="fixed" control={<Field required type='radio' component={MyRadioInput} />} label="Fixed ($)" />
                            <FormControlLabel name="breezenet.AR_fee_type" value="percent" control={<Field required type='radio' component={MyRadioInput} />} label="Variable (%)" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{breezenetValues.AR_fee_type === 'fixed' ? '$' : '%'}</InputAdornment>,
                        }}
                        required
                        label="A.R Fee"
                        name="breezenet.AR_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.AR_fee}
                        variant="outlined"
                        error={Boolean(breezenetTouched.AR_fee && breezenetErrors.AR_fee)}
                        helperText={breezenetTouched.AR_fee && breezenetErrors.AR_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Term (minimum 1 year)"
                        name="breezenet.breezenet_term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezenetValues.breezenet_term}
                        variant="outlined"
                        error={Boolean(breezenetTouched.breezenet_term && breezenetErrors.breezenet_term)}
                        helperText={breezenetTouched.breezenet_term && breezenetErrors.breezenet_term}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Payment Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Payment Type"
                        >
                            <FormControlLabel name="breezenet.payment_type" value="Credit Card" control={<Field required type='radio' component={MyRadioInput} />} label="Credit Card" />
                            <FormControlLabel name="breezenet.payment_type" value="Void Cheque" control={<Field required type='radio' component={MyRadioInput} />} label="Void Cheque" />
                            <FormControlLabel name="breezenet.payment_type" value="e-Transfer" control={<Field required type='radio' component={MyRadioInput} />} label="e-Transfer" />
                            <FormControlLabel name="breezenet.payment_type" value="Term Paid in Full" control={<Field required type='radio' component={MyRadioInput} />} label="Term Paid in Full" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BreezeNet;