import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { Public as Web, Home, Phone  } from '@mui/icons-material'

const initProfile = {
    profile_id:0,
    uid: '',
    url:'',
    business_name: '',
    area: '',
    start_date: '',
    image: '',
    rep_id: '',
    active: '1',
    phone: ''
}

const CompanyProfile = () => {
    const [loading, loader] = useState(false)
    const [profile, setProfile] = useState(initProfile)
    const [products, setProducts] = useState([])

    const fetchProfile = async() => {
        loader(true);
        try{
            const response = await axios.get('profile/'+localStorage.profileId,
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setProfile(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the profile Info!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the profile Info!")
            loader(false);
        }
    }

    const fetchProductsInfo = async() => {
        try{
          loader(true);
          const response = await axios.post('product',{profile_id: localStorage.profileId},{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
          if(response?.data){
            if(response.data.success){
              setProducts(response.data.data);
              loader(false);
            }else{
              toaster(false, response.data.message)
              loader(false);
            }
          }else{
            toaster(false, "An error occurred while fetching the products!")
            loader(false);
          }
        }catch(err){
          toaster(false, "An error occurred while fetching the products!")
          loader(false);
        }
      }

    useEffect(() => {
        fetchProfile();
        fetchProductsInfo();
    }, [])

    return(
    <>
        <Helmet>
            <title>Profile | BreezeDash</title>
        </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'Welcome '+ profile.business_name +'!'}</Typography>
                <Grid container spacing={3} justifyContent='space-between' my={2}>
                    <Grid item lg={4} md={6} xs={12} >
                        <Card>
                            <CardContent sx={{display: 'flex'}}>
                                <Web sx={{marginRight: '4px', color:'primary.main'}} />
                                <Typography>{profile.url}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} >
                        <Card>
                            <CardContent sx={{display: 'flex'}}>
                                <Home sx={{marginRight: '4px', color:'primary.main'}} />
                                <Typography>{profile.area}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} >
                        <Card>
                            <CardContent sx={{display: 'flex'}}>
                                <Phone sx={{marginRight: '4px', color:'primary.main'}} />
                                <Typography>{profile.phone}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Card sx={{width: 1, height:'100%'}}>
                    <CardContent>
                        <Typography variant='h5' textAlign='right'>{'Service Start Date: '+ profile.start_date}</Typography>
                        <Typography variant='h4'>{'Your product(s)'}</Typography>
                        <Grid container spacing={3} justifyContent='space-between' my={2}>
                            {products.map(product => (
                                <Grid item lg={3} md={4} xs={6} key={product.name} >
                                    <img alt={product.name} src={product.url} width='auto' height='60px' />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </Box>
        {loading && <Loader />}
    </>
    )
};

export default CompanyProfile;