export const login = (token,name,role) => {
    return {type: 'authenticate',data:{
        isAuthenticated: true,
        authToken: token,
        name,
        role,
    }}
}

export const logout = () => {
    return {type: 'authenticate',data:{
        isAuthenticated: false,
        authToken: '',
        name: '',
        role: '',
    }}      
}

export const setProfile = (profileId, profileName) => {
    return {type: 'SET',data:{
        profileId,
        profileName
    }}
}