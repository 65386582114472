import {Bar} from 'react-chartjs-2';
import { Card, useTheme } from '@mui/material';

const VisitorsBarChart = ({data}) => {

    const theme = useTheme();

    const options = {
        title:{
            display:true,
            text:'Visitors Overview',
            fontSize:20
          },
          legend:{
            display:true,
            position:'right'
          },
          layout: { padding: 0 },
          maintainAspectRatio: false,
          responsive: true,
          plugins:{
            tooltip: {
                backgroundColor: theme.palette.background.paper,
                bodyColor: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                enabled: true,
                footerColor: theme.palette.text.secondary,
                intersect: true,
                mode: 'index',
                titleColor: theme.palette.text.primary,
            },
        }
    }

    return(
        <Card elevation={3} sx={{minHeight: 500, p:2}}>
            <Bar
                data={data}
                options={options}
            />
        </Card>
    )
}

export default VisitorsBarChart;