import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const CreateLeadDialog = ({ open, handleClose, handleCreateLead, handleEditLead, leadToBeEdited }) => {

    let isEditing = false;
    let initialValues = {
        client_name: '',
        client_email: ''
    }
    if(leadToBeEdited.id){
        isEditing = true;
        initialValues = {
            client_name: leadToBeEdited.client_name,
            client_email: leadToBeEdited.client_email
        }
    }

    return( 
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    client_name: Yup.string().max(100).required('This field is mandatory'),
                    client_email: Yup.string().email('Must be a valid email address').max(255).required('This field is mandatory')
                })}
                onSubmit={ isEditing ? handleEditLead : handleCreateLead }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <Box component='form' onSubmit={handleSubmit}>
                        <DialogTitle sx={{fontWeight: 'bold', fontSize: '20px'}}>{isEditing ? "Edit Prospect" : "Create New Prospect"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Enter the prospect's name and email address
                            </DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            autoFocus
                                            margin="dense"
                                            id="client_name"
                                            name='client_name'
                                            label="Prospect Name"
                                            fullWidth
                                            variant="outlined"
                                            value={values.client_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            error={Boolean(touched.client_name && errors.client_name)}
                                            helperText={touched.client_name && errors.client_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            autoFocus
                                            margin="dense"
                                            id="client_email"
                                            name='client_email'
                                            label="Prospect Email"
                                            fullWidth
                                            variant="outlined"
                                            value={values.client_email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            error={Boolean(touched.client_email && errors.client_email)}
                                            helperText={touched.client_email && errors.client_email}
                                        />
                                    </Grid>
                                </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type='button' color="primary" variant='contained' onClick={handleClose}>Cancel</Button>
                            <Button type='submit' color="primary" variant='contained'  disabled={isSubmitting}>Save</Button>
                        </DialogActions>
                    </Box>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateLeadDialog;