import { useFormikContext, Field } from 'formik';
import {
    Box,
    Card,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const BreezeTrackProducts = [
    {
        name: 'BreezeTag',
        description: 'Google Analytics and Search console',
        payment_type: 'One-time Payment',
        cost: '$499'
    },
    {
        name: 'BreezeTrail',
        description: 'Recorded call tracking',
        payment_type: 'Monthly Payment',
        cost: '$75/m'
    },
    {
        name: 'BreezeHeat',
        description: 'Heat Map Tracking',
        payment_type: 'Monthly Payment',
        cost: '$99/m'
    },
    {
        name: 'BreezeWho',
        description: 'B2B Website Tracking',
        payment_type: 'Monthly Payment',
        cost: '$199/m'
    }
]

const seoPackages = [
    {
        name: 'Local',
        description: '(Google Business Profile Mgmt.)',
        cost: '$299/m'
    },
    {
        name: 'Basic',
        description: '(3 keywords)',
        cost: '$799/m'
    },
    {
        name: 'Standard',
        description: '(5 keywords)',
        cost: '$999/m'
    },
    {
        name: 'Professional',
        description: '(7 keywords)',
        cost: '$1499/m'
    },
    {
        name: 'Enterprise',
        description: '(10 keywords)',
        cost: '$1999/m'
    },
]

const addOns = [
    {
        name: 'Premium Blog',
        description: '(Upto 500 words)',
        cost: '$499/m'
    },
    {
        name: 'CRO',
        description: '(Conversion Rate Optimization)',
        cost: '$499/m'
    },
    {
        name: 'Social Bookmarking',
        description: '(20 Bookmarks per month)',
        cost: '$599/m'
    },
    {
        name: 'Guest Blogging',
        description: '(Once per month includes Blog)',
        cost: '$699/m'
    }
]

const BreezeMobileProducts = [
    {
        name: 'BreezeTact',
        description: 'Mobile Conversion Tool',
        payment_type: 'Monthly Payment',
        cost: '$49/m'
    },
    {
        name: 'BreezeView',
        description: 'Reputation Management Tool',
        payment_type: 'Monthly Payment',
        cost: '$49/m'
    },
    {
        name: 'BreezeChat',
        description: 'Website Visitor Chat',
        payment_type: 'Monthly Payment',
        cost: '$99/m'
    },
]

const BoxLabel = ({name, description, payment_type, cost}) => (
    <Box py={1}>
        <Typography variant='body1'>{name+'® '+cost}</Typography>
        <Typography variant='subtitle2'>{description}</Typography>
        {!!payment_type && <Typography variant='caption'>{'('+payment_type+')'}</Typography>}
    </Box>
)

const InputLabel = ({name, description}) => (
    <Box py={1}>
        <Typography variant='body1'>{name}</Typography>
        <Typography variant='subtitle2'>{description}</Typography>
    </Box>
)

const BreezeQuest = ({noteMaxLimit}) => {
    const { errors, touched, values, handleBlur, handleChange } = useFormikContext();
    const { breezequest: breezequestValues } = values;
    const breezequestErrors = errors.breezequest || {}
    const breezequestTouched = touched.breezequest || {};
    
    return (
        <Card sx={{my: 2, p:2, boxShadow: 2}} variant='outlined'>
            <Typography my={1} variant='h3' fontWeight={700}>BreezeQuest</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        multiline
                        required
                        label="Campaign Geography"
                        name="breezequest.campaign_geography"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.campaign_geography}
                        variant="outlined"
                        error={Boolean(breezequestTouched.campaign_geography && breezequestErrors.campaign_geography)}
                        helperText={breezequestTouched.campaign_geography && breezequestErrors.campaign_geography}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        multiline
                        required
                        label="GBP Location(s)"
                        name="breezequest.gbp_locations"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.gbp_locations}
                        variant="outlined"
                        error={Boolean(breezequestTouched.gbp_locations && breezequestErrors.gbp_locations)}
                        helperText={breezequestTouched.gbp_locations && breezequestErrors.gbp_locations}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Additional Notes"
                        InputProps={{sx: {minHeight: '80px'}}}
                        inputProps={{maxLength: noteMaxLimit}}
                        name="breezequest.additional_notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.additional_notes}
                        variant="outlined"
                        error={Boolean(breezequestTouched.additional_notes && breezequestErrors.additional_notes)}
                        helperText={breezequestTouched.additional_notes && breezequestErrors.additional_notes}
                    />
                    <Typography variant='caption'>{`${breezequestValues.additional_notes.length}/${noteMaxLimit}`}</Typography>
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>SEO Packages</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container spacing={1}>
                    {
                        seoPackages.map(product => (
                            <Grid key={product.name} item xs={12} md={6} lg={3}>
                                <FormControlLabel
                                    sx={{alignItems: 'flex-start'}}
                                    control={
                                        <Checkbox name={'breezequest.seo_packages'} onChange={handleChange} value={product.name} />
                                    }
                                    label={<BoxLabel {...product} />}
                                />
                            </Grid>
                        ))
                    }
                    <Grid item xs={12} md={6}  lg={3}>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Custom"}
                            name={"breezequest.custom_seo_pack"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['custom_seo_pack']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['custom_seo_pack'] && breezequestErrors['custom_seo_pack'])}
                            helperText={breezequestTouched['custom_seo_pack'] && breezequestErrors['custom_seo_pack']}
                        />
                    </Grid>
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>Add-Ons</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container spacing={1}>
                    {
                        addOns.map(product => (
                            <Grid key={product.name} item xs={12} md={6} lg={3}>
                                <FormControlLabel
                                    sx={{alignItems: 'flex-start'}}
                                    control={
                                        <Checkbox name={'breezequest.add_ons'} onChange={handleChange} value={product.name} />
                                    }
                                    label={<BoxLabel {...product} />}
                                />
                            </Grid>
                        ))
                    }
                    <Grid item xs={12} md={6}  lg={3}>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Backlinks Budget"}
                            name={"breezequest.backlinks_budget"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['backlinks_budget']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['backlinks_budget'] && breezequestErrors['backlinks_budget'])}
                            helperText={breezequestTouched['backlinks_budget'] && breezequestErrors['backlinks_budget']}
                        />
                    </Grid>
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>BreezeTrack</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container>
                {
                    BreezeTrackProducts.map(product => (
                        <Grid key={product.name} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                sx={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox name={'breezequest.breezetrack'} onChange={handleChange} value={product.name} />
                                }
                                label={<BoxLabel {...product} />}
                            />
                        </Grid>
                    ))
                }
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>BreezeMobile</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container>
                {
                    BreezeMobileProducts.map(product => (
                        <Grid key={product.name} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                sx={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox name={'breezequest.breezemobile'} onChange={handleChange} value={product.name} />
                                }
                                label={<BoxLabel {...product} />}
                            />
                        </Grid>
                    ))
                }
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>BreezeMarket</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezemarket'} onChange={handleChange} value={"BreezeReview"} />
                            }
                            label={<InputLabel name='BreezeReview' description={'Review Management Software'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Cost"}
                            defaultValue={399}
                            variant="outlined"
                            disabled
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            label={"Quantity"}
                            name={"breezequest.breezereview_quantity"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezereview_quantity']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezereview_quantity'] && breezequestErrors['breezereview_quantity'])}
                            helperText={breezequestTouched['breezereview_quantity'] && breezequestErrors['breezereview_quantity']}
                            disabled={!breezequestValues.breezemarket.includes('BreezeReview')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezemarket'} onChange={handleChange} value={"BreezeBlast"} />
                            }
                            label={<InputLabel name='BreezeBlast' description={'Email Marketing'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Cost"}
                            name={"breezequest.breezeblast_cost"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezeblast_cost']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezeblast_cost'] && breezequestErrors['breezeblast_cost'])}
                            helperText={breezequestTouched['breezeblast_cost'] && breezequestErrors['breezeblast_cost']}
                            disabled={!breezequestValues.breezemarket.includes('BreezeBlast')}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            label={"E-blasts per month"}
                            name={"breezequest.breezeblast_quantity"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezeblast_quantity']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezeblast_quantity'] && breezequestErrors['breezeblast_quantity'])}
                            helperText={breezequestTouched['breezeblast_quantity'] && breezequestErrors['breezeblast_quantity']}
                            disabled={!breezequestValues.breezemarket.includes('BreezeBlast')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezemarket'} onChange={handleChange} value={"BreezeText"} />
                            }
                            label={<InputLabel name='BreezeText' description={'SMS Marketing'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Cost"}
                            name={"breezequest.breezetext_cost"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezetext_cost']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezetext_cost'] && breezequestErrors['breezetext_cost'])}
                            helperText={breezequestTouched['breezetext_cost'] && breezequestErrors['breezetext_cost']}
                            disabled={!breezequestValues.breezemarket.includes('BreezeText')}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            label={"Campaigns per month"}
                            name={"breezequest.breezetext_quantity"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezetext_quantity']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezetext_quantity'] && breezequestErrors['breezetext_quantity'])}
                            helperText={breezequestTouched['breezetext_quantity'] && breezequestErrors['breezetext_quantity']}
                            disabled={!breezequestValues.breezemarket.includes('BreezeText')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezemarket'} onChange={handleChange} value={"BreezeProof"} />
                            }
                            label={<InputLabel name='BreezeProof' description={'Website Activity Tool'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/m</InputAdornment>
                            }}
                            label={"Cost"}
                            defaultValue={99}
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>BreezeTask</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezetask'} onChange={handleChange} value={"BreezeBook"} />
                            }
                            label={<InputLabel name='BreezeBook ($399/m)' description={'Booking system'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            label={"Quantity"}
                            name={"breezequest.breezebook_quantity"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezebook_quantity']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezebook_quantity'] && breezequestErrors['breezebook_quantity'])}
                            helperText={breezequestTouched['breezebook_quantity'] && breezequestErrors['breezebook_quantity']}
                            disabled={!breezequestValues.breezetask.includes('BreezeBook')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezetask'} onChange={handleChange} value={"BreezeAlert"} />
                            }
                            label={<InputLabel name='BreezeAlert ($399/m)' description={'Appointment Reminder'} />}
                        />
                        <TextField
                            sx={{my:1}}
                            fullWidth
                            label={"Quantity"}
                            name={"breezequest.breezealert_quantity"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={breezequestValues['breezealert_quantity']}
                            variant="outlined"
                            error={Boolean(breezequestTouched['breezealert_quantity'] && breezequestErrors['breezealert_quantity'])}
                            helperText={breezequestTouched['breezealert_quantity'] && breezequestErrors['breezealert_quantity']}
                            disabled={!breezequestValues.breezetask.includes('BreezeAlert')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox name={'breezequest.breezetask'} onChange={handleChange} value={"BreezeBack"} />
                            }
                            label={<InputLabel name='BreezeBack ($399/m)' description={'Automated Data mining software'} />}
                        />
                    </Grid>
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>Keyword Suggestions</Typography>
            <Grid container spacing={3}>
                {
                    Array.from({length: 6}, (el, i) => i+1).map(elem => (
                        <Grid key={elem} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={"Keyword Phrase "+ elem}
                                name={"breezequest.keywordPhrase"+elem}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={breezequestValues['keywordPhrase'+elem]}
                                variant="outlined"
                                error={Boolean(breezequestTouched['keywordPhrase'+elem] && breezequestErrors['keywordPhrase'+elem])}
                                helperText={breezequestTouched['keywordPhrase'+elem] && breezequestErrors['keywordPhrase'+elem]}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <Typography my={1} variant='h5'>Creative and Research Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Setup Fee"
                        name="breezequest.setup_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.setup_fee}
                        variant="outlined"
                        error={Boolean(breezequestTouched.setup_fee && breezequestErrors.setup_fee)}
                        helperText={breezequestTouched.setup_fee && breezequestErrors.setup_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="BreezeQuest Monthly Fee"
                        name="breezequest.breezequest_monthly_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.breezequest_monthly_fee}
                        variant="outlined"
                        error={Boolean(breezequestTouched.breezequest_monthly_fee && breezequestErrors.breezequest_monthly_fee)}
                        helperText={breezequestTouched.breezequest_monthly_fee && breezequestErrors.breezequest_monthly_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Term (minimum 1 year)"
                        name="breezequest.term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezequestValues.term}
                        variant="outlined"
                        error={Boolean(breezequestTouched.term && breezequestErrors.term)}
                        helperText={breezequestTouched.term && breezequestErrors.term}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Payment Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Payment Type"
                        >
                            <FormControlLabel name="breezequest.payment_type" value="Credit Card" control={<Field required type='radio' component={MyRadioInput} />} label="Credit Card" />
                            <FormControlLabel name="breezequest.payment_type" value="Void Cheque" control={<Field required type='radio' component={MyRadioInput} />} label="Void Cheque" />
                            <FormControlLabel name="breezequest.payment_type" value="e-Transfer" control={<Field required type='radio' component={MyRadioInput} />} label="e-Transfer" />
                            <FormControlLabel name="breezequest.payment_type" value="Term Paid in Full" control={<Field required type='radio' component={MyRadioInput} />} label="Term Paid in Full" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BreezeQuest;