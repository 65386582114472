import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';
import BreezeNet from '../../components/docusignContracts/breezenet';
import BreezeSocial from '../../components/docusignContracts/breezesocial';
import BreezeSearch from '../../components/docusignContracts/breezeSearch';
import BreezeQuest from '../../components/docusignContracts/breezequest';
import BreezeSites from '../../components/docusignContracts/breezesites'

const products = ['BreezeNet', 'BreezeSocial', 'BreezeSearch', 'BreezeQuest', 'BreezeSites']

const NOTE_MAX_LIMIT = 1000;

const DocusignRequest = (props) => {

  const [loading, loader] = useState(false)

  const submitRequest = async(values, {resetForm}) => {

    try{
      loader(true)
      const response = await axios.post('bmw_contract',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Your request has been submitted!')
          resetForm();
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while creating the request!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while creating the request!")
      loader(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Docusign Request | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Formik
                initialValues={{
                    client_fullname: '',
                    client_email: '',
                    business_name: '',
                    website_url: '',
                    source_sale: '',
                    assisted_by: '',
                    client_address: '',
                    client_phone: '',
                    products: [],
                    breezenet: {
                        geo_targeting: '',
                        number_of_key_phrases: '',
                        schedule: '',
                        industry: '',
                        media_buy: '',
                        start_budget: '',
                        additional_notes: '',
                        breezetrack: [],
                        creative_sets: '',
                        creative_monthly_rate: '',
                        setup_fee: '',
                        AR_fee_type: 'fixed',
                        AR_fee: '',
                        payment_type: '',
                        breezenet_term: ''
                    },
                    breezesocial: {
                        geo_targeting: '',
                        number_of_key_phrases: '',
                        schedule: '',
                        industry: '',
                        media_buy: '',
                        start_budget: '',
                        additional_notes: '',
                        breezetrack: [],
                        breezepost_platform: [],
                        breezepost_number_posts: '',
                        breezepost_monthly_cost: '',
                        creative_sets: '',
                        creative_monthly_rate: '',
                        setup_fee: '',
                        AR_fee_type: 'fixed',
                        AR_fee: '',
                        payment_type: '',
                        breezesocial_term: ''
                    },
                    breezesearch: {
                        keywordPhrase1: '',
                        keywordPhrase2: '',
                        keywordPhrase3: '',
                        keywordPhrase4: '',
                        keywordPhrase5: '',
                        keywordPhrase6: '',
                        keywordPhrase7: '',
                        keywordPhrase8: '',
                        keywordPhrase9: '',
                        keywordPhrase10: '',
                        search_additional_notes: '',
                        search_breezetrack: [],
                        search_setup_fee: '',
                        search_monthly_fee: '',
                        search_term: '',
                        search_payment_type: ''
                    },
                    breezequest: {
                        campaign_geography: '',
                        gbp_locations: '',
                        additional_notes: '',
                        seo_packages: [],
                        custom_seo_pack: '',
                        backlinks_budget: '',
                        add_ons: [],
                        breezetrack: [],
                        breezemobile: [],
                        breezemarket: [],
                        breezereview_quantity: '',
                        breezeblast_cost: '',
                        breezeblast_quantity: '',
                        breezetext_cost: '',
                        breezetext_quantity: '',
                        breezetask: [],
                        breezebook_quantity: '',
                        breezealert_quantity: '',
                        keywordPhrase1: '',
                        keywordPhrase2: '',
                        keywordPhrase3: '',
                        keywordPhrase4: '',
                        keywordPhrase5: '',
                        keywordPhrase6: '',
                        setup_fee: '',
                        breezequest_monthly_fee: '',
                        payment_type: '',
                        term: ''
                    },
                    breezesites: {
                        required_services: [],
                        other_required_service: '',
                        current_domain: '',
                        domain_to_purchase: '',
                        no_of_pages: '',
                        logo_design: '',
                        business_description: '',
                        admired_websites: '',
                        platform: [],
                        other_platform: '',
                        additional_info: '',
                        development_fee: '',
                        add_ons: [],
                        add_on_total_fee: '',
                        add_on_term: '',
                        monthly_payment_addons: [],
                        breezeaccess: '',
                        breezemail_quantity: '',
                        breezeIT_cost: '',
                        breezeIT_hours: '',
                        breezeblast_cost: '',
                        breezeblast_quantity: '',
                        web_maintenance_cost: '',
                        web_maintenance_hours: '',
                        breezesite_rental_options: [],
                        breezesite_rental_cost: '',
                        breezesite_finance_options: [],
                        breezesite_finance_cost: '',
                        setup_fee: '',
                        monthly_fee: '',
                        term: '',
                        buyout_cost: '',
                        payment_type: ''
                    }
                }}
                validationSchema={Yup.object().shape({
                    client_fullname: Yup.string().max(100).required('This field is required'),
                    client_email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    business_name: Yup.string().max(255).required('Company Name is required'),
                    website_url: Yup.string().max(255).required('Website URL is required'),
                    source_sale: Yup.string().max(255).required('This field is required'),
                    assisted_by: Yup.string().max(100).required('This field is required'),
                    client_address: Yup.string().max(500).required('This field is required'),
                    client_phone: Yup.string().min(8).required('Phone number is required'),
                    products: Yup.array(Yup.string()).required('This field is required'),
                    breezenet: Yup.object().when('products',{
                        is: val => val.includes('BreezeNet'),
                        then: Yup.object().shape({
                            geo_targeting: Yup.string().max(255).required('This field is required'),
                            number_of_key_phrases: Yup.string().max(100).required('This field is required'),
                            schedule: Yup.string().max(100).required('This field is required'),
                            industry: Yup.string().max(100).required('This field is required'),
                            media_buy: Yup.string().max(20).required('This field is required'),
                            start_budget: Yup.string().max(20).required('This field is required'),
                            additional_notes: Yup.string().max(NOTE_MAX_LIMIT),
                            creative_sets: Yup.string().max(20).required('This field is required'),
                            creative_monthly_rate: Yup.string().max(20).required('This field is required'),
                            setup_fee: Yup.string().max(20).required('This field is required'),
                            AR_fee_type: Yup.string().required('This field is required'),
                            AR_fee: Yup.string().max(20).required('This field is required'),
                            payment_type: Yup.string().max(20).required('This field is required'),
                            breezenet_term: Yup.string().max(20).required('This field is required')
                        })
                    }),
                    breezesocial: Yup.object().when('products',{
                        is: val => val.includes('BreezeSocial'),
                        then: Yup.object().shape({
                            geo_targeting: Yup.string().max(255).required('This field is required'),
                            number_of_key_phrases: Yup.string().max(100).required('This field is required'),
                            schedule: Yup.string().max(100).required('This field is required'),
                            industry: Yup.string().max(100).required('This field is required'),
                            media_buy: Yup.string().max(20).required('This field is required'),
                            start_budget: Yup.string().max(20).required('This field is required'),
                            additional_notes: Yup.string().max(NOTE_MAX_LIMIT),
                            breezepost_number_posts: Yup.string().max(20).required('This field is required'),
                            breezepost_monthly_cost: Yup.string().max(20).required('This field is required'),
                            creative_sets: Yup.string().max(20).required('This field is required'),
                            creative_monthly_rate: Yup.string().max(20).required('This field is required'),
                            setup_fee: Yup.string().max(20).required('This field is required'),
                            AR_fee_type: Yup.string().required('This field is required'),
                            AR_fee: Yup.string().max(20).required('This field is required'),
                            payment_type: Yup.string().max(20).required('This field is required'),
                            breezesocial_term: Yup.string().max(20).required('This field is required')
                        })
                    }),
                    breezesearch: Yup.object().when('products',{
                        is: val => val.includes('BreezeSearch'),
                        then: Yup.object().shape({
                            keywordPhrase1: Yup.string().max(50).required('This field is required'),
                            keywordPhrase2: Yup.string().max(50),
                            keywordPhrase3: Yup.string().max(50),
                            keywordPhrase4: Yup.string().max(50),
                            keywordPhrase5: Yup.string().max(50),
                            keywordPhrase6: Yup.string().max(50),
                            keywordPhrase7: Yup.string().max(50),
                            keywordPhrase8: Yup.string().max(50),
                            keywordPhrase9: Yup.string().max(50),
                            keywordPhrase10: Yup.string().max(50),
                            search_additional_notes: Yup.string().max(NOTE_MAX_LIMIT),
                            search_setup_fee: Yup.string().max(20).required('This field is required'),
                            search_monthly_fee: Yup.string().max(20).required('This field is required'),
                            search_term: Yup.string().max(20).required('This field is required'),
                            search_payment_type: Yup.string().max(20).required('This field is required')
                        })
                    }),
                    breezequest: Yup.object().shape({})
                })}
                onSubmit={submitRequest}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                    onSubmit={handleSubmit}
                    >
                    <Card sx={{p: 2}}>
                        <Typography variant='h2' textAlign='center' color={'primary.main'}>Docusign Request</Typography>
                        <Typography variant='body1' textAlign='center'>Please note: DocuSign requests without full information needed to fill DocuSign will not be sent as a priority.</Typography>
                        <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Client's Full Name"
                                    name="client_fullname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.client_fullname}
                                    variant="outlined"
                                    error={Boolean(touched.client_fullname && errors.client_fullname)}
                                    helperText={touched.client_fullname && errors.client_fullname}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Client's Email (the person who is signing DocuSign)"
                                    name="client_email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.client_email}
                                    variant="outlined"
                                    error={Boolean(touched.client_email && errors.client_email)}
                                    helperText={touched.client_email && errors.client_email}
                                />
                            </Grid>
                            <Grid
                            item
                            xs={12}
                            md={6}
                            >
                            <TextField
                                fullWidth
                                label="Full Company Name (check their website)"
                                name="business_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.business_name}
                                variant="outlined"
                                error={Boolean(touched.business_name && errors.business_name)}
                                helperText={touched.business_name && errors.business_name}
                            />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Website URL"
                                    name="website_url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.website_url}
                                    variant="outlined"
                                    error={Boolean(touched.website_url && errors.website_url)}
                                    helperText={touched.website_url && errors.website_url}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Source of Sale"
                                    name="source_sale"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.source_sale}
                                    variant="outlined"
                                    error={Boolean(touched.source_sale && errors.source_sale)}
                                    helperText={touched.source_sale && errors.source_sale}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Full address"
                                    name="client_address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.client_address}
                                    variant="outlined"
                                    error={Boolean(touched.client_address && errors.client_address)}
                                    helperText={touched.client_address && errors.client_address}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Assisted by"
                                    name="assisted_by"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.assisted_by}
                                    variant="outlined"
                                    error={Boolean(touched.assisted_by && errors.assisted_by)}
                                    helperText={touched.assisted_by && errors.assisted_by}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Phone number"
                                    name="client_phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.client_phone}
                                    variant="outlined"
                                    error={Boolean(touched.client_phone && errors.client_phone)}
                                    helperText={touched.client_phone && errors.client_phone}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <Card sx={{p:2}}>
                                    <FormControl fullWidth required variant="outlined">
                                        <FormLabel component="legend">Select Products</FormLabel>
                                        <FormGroup row>
                                            {
                                                products.map(product => (
                                                    <FormControlLabel
                                                        key={product}
                                                        control={
                                                            <Checkbox name={'products'} onChange={handleChange} value={product} />
                                                        }
                                                        label={product}
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </Card>
                            </Grid>
                        </Grid>
                        {
                            values.products.includes("BreezeNet") && <BreezeNet noteMaxLimit={NOTE_MAX_LIMIT} />
                        }
                        {
                            values.products.includes("BreezeSocial") && <BreezeSocial noteMaxLimit={NOTE_MAX_LIMIT} />
                        }
                        {
                            values.products.includes("BreezeSearch") && <BreezeSearch noteMaxLimit={NOTE_MAX_LIMIT} />
                        }
                        {
                            values.products.includes("BreezeQuest") && <BreezeQuest noteMaxLimit={NOTE_MAX_LIMIT} />
                        }
                        {
                            values.products.includes("BreezeSites") && <BreezeSites noteMaxLimit={NOTE_MAX_LIMIT} />
                        }
                        </CardContent>
                        <Divider />
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                        >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit Request
                        </Button>
                        </Box>
                    </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>
  );
};

export default DocusignRequest;
