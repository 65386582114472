import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import axios from '../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../redux/actionCreator'

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isAuthenticated, authToken} = useSelector(state => state.auth)

  const logoutHandler = async() => {
    try{
      const response = await axios.post('logout',null,{headers: {'Authorization': 'Bearer '+ authToken}});
      if(response?.data){
        if(response.data.success){
          dispatch(logout())
        }else{
          toaster(false, response.data.message)
        }
      }else{
        toaster(false, "An error occurred while logging out!")
      }
    }catch(err){
      toaster(false, "An error occurred while logging out!")
    }
  }

  useEffect(() => {
    if(!isAuthenticated)
      navigate('/login', { replace: true });
  // eslint-disable-next-line
  },[isAuthenticated])

  return (
    <AppBar
      elevation={0}
      sx={{minHeight: '75px'}}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
        <Typography variant='h1' color='#fff'><span style={{fontWeight: 700}}>Breeze </span><span style={{fontWeight: 350, letterSpacing: '10px'}}>DASH</span></Typography>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={logoutHandler} color="inherit" size="large">
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
