import { Navigate } from 'react-router-dom';
import BreezeReportsLayout from './components/BreezeReportsLayout';
import MainLayout from './components/MainLayout';
import HubLayout from './components/HubLayout';
import Login from './pages/Login';
import AMLogin from './pages/AMLogin';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import ComingSoon from './components/ComingSoon';

import Reports from './pages/hub/Reports';
import Account from './pages/Account';
import Settings from './pages/Settings';
import ManageAccounts from './pages/hub/ManageAccounts';
import AutomaticSeoAudit from './pages/hub/AutomaticSeoAudit';
import ManualSeoAudit from './pages/hub/ManualSeoAudit';
import AdwordsAudit from './pages/hub/AdwordsAudit';
import SearchAdsQuote from './pages/hub/SearchAdsQuote';
import SocialMediaAudit from './pages/hub/SocialMediaAudit';
import WebsiteQuote from './pages/hub/WebsiteQuote';
import ResourceCentre from './pages/hub/ResourceCentre';
import DocusignRequest from './pages/hub/DocusignRequest';
import SupportTicket from './pages/hub/SupportTicket';
import BreezePro from './pages/hub/BreezePro';
import Notes from './pages/hub/Notes';

import CompanyProfile from './pages/reports/CompanyProfile';
import BreezeNet from './pages/reports/BreezeNet'
import BreezeSocial from './pages/reports/BreezeSocial'
import QuestAnalytics from './pages/reports/QuestAnalytics'
import QuestRanking from './pages/reports/QuestRanking';
import QuestBacklinks from './pages/reports/QuestBacklinks';
import BreezeHeat from './pages/reports/BreezeHeat';
import BreezeTact from './pages/reports/BreezeTact';
import BreezeView from './pages/reports/BreezeView';
import BreezePress from './pages/reports/BreezePress';
import BreezeGuest from './pages/reports/BreezeGuest';
import BreezeLocal from './pages/reports/BreezeLocal';
import SocialBookmark from './pages/reports/SocialBookmark';
import BreezeWho from './pages/reports/BreezeWho';

const routes = (isAuthenticated) => [
  {
    path: 'reports',
    element: isAuthenticated ? <BreezeReportsLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'profile', element: <CompanyProfile /> },
      { path: 'breezenet', element: <BreezeNet /> },
      { path: 'breezesocial', element: <BreezeSocial /> },
      { path: 'quest_analytics', element: <QuestAnalytics /> },
      { path: 'quest_ranking', element: <QuestRanking /> },
      { path: 'quest_backlinks', element: <QuestBacklinks /> },
      { path: 'breezeheat', element: <BreezeHeat /> },
      { path: 'breezetact', element: <BreezeTact /> },
      { path: 'breezeview', element: <BreezeView /> },
      { path: 'breezepress', element: <BreezePress /> },
      { path: 'breezeguest', element: <BreezeGuest /> },
      { path: 'breezelocal', element: <BreezeLocal /> },
      { path: 'social_book', element: <SocialBookmark /> },
      { path: 'breezewho', element: <BreezeWho /> },
      { path: '', element: <Navigate to="profile" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'hub',
    element: isAuthenticated ? <HubLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'reports', 
        element: <Reports />,
      },
      { path: 'account', element: <Account /> },
      { path: 'settings', element: <Settings /> },
      { path: 'clients', element: <ComingSoon /> },
      { path: 'manage', element: <ManageAccounts /> },
      { path: 'auto_seo_audit', element: <AutomaticSeoAudit /> },
      { path: 'manual_seo_audit', element: <ManualSeoAudit /> },
      { path: 'ads_audit', element: <AdwordsAudit /> },
      { path: 'search_ads_quote', element: <SearchAdsQuote /> },
      { path: 'social_media_audit', element: <SocialMediaAudit /> },
      { path: 'website_quote', element: <WebsiteQuote /> },
      { path: 'docusign', element: <DocusignRequest /> },
      { path: 'resources', element: <ResourceCentre /> },
      { path: 'learning', element: <ComingSoon /> },
      { path: 'tickets_management', element: <ComingSoon /> },
      { path: 'breezepro/*', element: <BreezePro /> },
      { path: 'support', element: <SupportTicket /> },
      { path: 'notes', element: <Notes /> },
      { path: '', element: <Navigate to="reports" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'am_login', element: <AMLogin /> },
      { path: 'forgot_password', element: <ForgotPassword /> },
      { path: 'reset_password', element: <ResetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
