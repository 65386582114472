import { Box, Grid, Link, Typography} from '@mui/material';
import { Phone, Mail, Facebook, Twitter, Youtube, Instagram, Linkedin } from 'react-feather'

const Footer = () => (
    <Box sx={{backgroundColor:'primary.main', color: '#fff', p:2}}>
        <Grid container spacing={3} justifyContent='space-between'>
            <Grid item lg={4} md={6} xs={12}>
                <Box sx={{p:1}}>
                    <Typography variant='h4' mb={1} >Headquarters:</Typography>
                    <Typography variant='body2'>80 Galaxy Blvd. Unit 15,</Typography>
                    <Typography variant='body2'>Toronto, Ontario M9W 5R7</Typography>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
                <Box sx={{display:'flex', alignItems:'center',p:1}}>
                    <Phone style={{marginRight:'4px'}} />
                    <Link href='tel:1-888-466-4167' variant='body1' color='inherit' underline="hover">1-888-466-4167</Link>
                </Box>
                <Box sx={{display:'flex', alignItems:'center',p:1}}>
                    <Mail style={{marginRight:'4px'}} />
                    <Link href='mailto:breezereports@breezemaxweb.com' variant='body1' color='inherit' underline="hover">breezereports@breezemaxweb.com</Link>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
                <Box sx={{display:'flex', justifyContent:'center', p: 1}}>
                    <Link href='https://www.facebook.com/breezemaxweb?fref=nf' color='inherit' underline="none" sx={{mx:1}} target='_blank' rel="noopener noreferrer"><Facebook /></Link>
                    <Link href='https://twitter.com/breezemaxweb?lang=en' color='inherit' underline="none" sx={{mx:1}} target='_blank' rel="noopener noreferrer"><Twitter /></Link>
                    <Link href='https://www.linkedin.com/company/breezemaxweb-ca-ltd-' color='inherit' underline="none" sx={{mx:1}} target='_blank' rel="noopener noreferrer"><Linkedin /></Link>
                    <Link href='https://www.youtube.com/user/BreezeMaxWeb' color='inherit' underline="none" sx={{mx:1}} target='_blank' rel="noopener noreferrer"><Youtube /></Link>
                    <Link href='https://www.instagram.com/breezemaxweb/' color='inherit' underline="none" sx={{mx:1}} target='_blank' rel="noopener noreferrer"><Instagram /></Link>
                </Box>
            </Grid>
        </Grid>
    </Box>
)

export default Footer;