import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import ImageCard from 'src/components/reports/ImageCard';
import LightBox from '../../components/auxiliary/lightBox'

const BreezeLocal = () => {
    const [loading, loader] = useState(false)
    const [data, setData] = useState({})

    const fetchImages = async() => {
        loader(true);
        try{
            const response = await axios.post('gmb',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setData(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    useEffect(() => {
        fetchImages();
    }, [])

    return(
        <LightBox>
            <Helmet>
                <title>Breezelocal | BreezeDash</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'BreezeLocal (Google Business Profile)'}</Typography>
                <Box sx={{p: 1, width:1}}>
                    {data ? <>
                    <Typography my={1} variant='h3' align="center" color="text.primary">{'Overview'}</Typography>
                    <Typography my={1} variant='h5' align="center" color="text.primary">{data.overview}</Typography>
                    <Grid container spacing={3} justifyContent='space-between'>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file1} title='Listing Search' description='How customers find your listing.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file2} title='Customer Actions' description='The most common actions customers take on your listing.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file3} title='Directions Request' description='The places where customers request directions to your business.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file4} title='Directions Request' description='The places where customers request directions to your business.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file5} title='Phone Calls' description='When and how many times a customer called your business.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file6} title='Google View' description='Google service that customers use to find your business.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file7} title='Photo Quantity' description='Number of photos that apper on your business, compared to photos from other businesses.' />
                        </Grid>
                        <Grid item lg={6} md={12} >
                            <ImageCard url={data.file8} title='Photo Views' description='The number of times your business photos has been viewed, compared to photos from other businesses.' />
                        </Grid>
                    </Grid>
                    <Card sx={{display: 'flex',flexDirection: 'column',mt:3}}>
                        <CardContent>
                            <Typography my={2} variant='h3' align="center" color="text.primary">{'What can we do better?'}</Typography>
                            <Typography my={2} variant='body1' color="text.primary">{data.conclusion}</Typography>
                        </CardContent>
                    </Card>
                    </> : 
                    <Typography my={2} variant='h3' align="center" color="text.primary">{'No Data Available!'}</Typography>}
                </Box>
            </Container>
            </Box>
            {loading && <Loader />}
        </LightBox>
        )

}

export default BreezeLocal;