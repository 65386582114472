import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TextField,
    Grid
} from '@mui/material';

const NOTE_MAX_LIMIT = 1000;

const TotalSummaryForm = ({ handleBack, handleSubmit }) => {
    return(
        <Formik
            initialValues={{
                cost1: '',
                total1: '',
                total_m_1: '',
          
                cost2: '',
                total2: '',
                total_m_2: '',
          
                cost3: '',
                total3: '',
                total_m_3: '',
          
                cost4: '',
                total4: '',
                total_m_4: '',
          
                cost5: '',
                total5: '',
                total_m_5: '',
          
                note: '',
                total_agency_fee: '',
                valid_for : ''
            }}
            validationSchema={Yup.object().shape({
                cost1: Yup.string().max(90),
                total1: Yup.string(),
                total_m_1: Yup.string(),
          
                cost2: Yup.string().max(90),
                total2: Yup.string(),
                total_m_2: Yup.string(),
          
                cost3: Yup.string().max(90),
                total3: Yup.string(),
                total_m_3: Yup.string(),
          
                cost4: Yup.string().max(90),
                total4: Yup.string(),
                total_m_4: Yup.string(),
          
                cost5: Yup.string().max(90),
                total5: Yup.string(),
                total_m_5: Yup.string(),
          
                note: Yup.string().max(NOTE_MAX_LIMIT),
                total_agency_fee: Yup.string(),
                valid_for : Yup.string()
            })}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Box component='form' onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        #
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Description
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Total One Time Fee
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Total Monthly Fee
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from({length: 5}, (el, i) => i+1).map((elem) => (
                                    <TableRow
                                        hover
                                        key={elem}
                                    >
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {elem}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                inputProps={{maxLength: 90}}
                                                name={'cost'+elem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['cost'+elem]}
                                                variant="outlined"
                                                error={Boolean(touched['cost'+elem] && errors['cost'+elem])}
                                                helperText={touched['cost'+elem] && errors['cost'+elem]}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={'total'+elem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['total'+elem]}
                                                variant="outlined"
                                                error={Boolean(touched['total'+elem] && errors['total'+elem])}
                                                helperText={touched['total'+elem] && errors['total'+elem]}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={'total_m_'+elem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['total_m_'+elem]}
                                                variant="outlined"
                                                error={Boolean(touched['total_m_'+elem] && errors['total_m_'+elem])}
                                                helperText={touched['total_m_'+elem] && errors['total_m_'+elem]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{my:1, p:2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h5' my={1}>Total Monthly Agency Retainer</Typography>
                                <TextField
                                    fullWidth
                                    name={'total_agency_fee'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.total_agency_fee}
                                    variant="outlined"
                                    error={Boolean(touched.total_agency_fee && errors.total_agency_fee)}
                                    helperText={touched.total_agency_fee && errors.total_agency_fee}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h5' my={1}>Quote valid for?</Typography>
                                <TextField
                                    fullWidth
                                    name={'valid_for'}
                                    placeholder='Days'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.valid_for}
                                    variant="outlined"
                                    error={Boolean(touched.valid_for && errors.valid_for)}
                                    helperText={touched.valid_for && errors.valid_for}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{my:1, p:2}}>
                        <Typography variant='h5' my={1}>Campaign Notes:</Typography>
                        <TextField
                            inputProps={{maxLength: NOTE_MAX_LIMIT}}
                            fullWidth
                            multiline
                            InputProps={{sx: {minHeight: '80px'}}}
                            name={'note'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            variant="outlined"
                            error={Boolean(touched.note && errors.note)}
                            helperText={touched.note && errors.note}
                        />
                        <Typography variant='caption'>{`${values.note.length}/${NOTE_MAX_LIMIT}`}</Typography>
                    </Box>
                    <Box sx={{p:1, my: 1}}>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** DO NOT USE COMMAS IN NUMBERS.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** For any field left unfilled a N/A will appear automatically.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body1'>Quotes valid for 30 days</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Generate Pdf
                        </Button>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

export default TotalSummaryForm;