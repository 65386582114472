import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState, useReducer } from 'react'
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography, Card, CardContent,
} from '@mui/material';
import { withStyles } from '@mui/styles'
import axios from '../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../redux/actionCreator'
import BreezeDashText from '../assets/BreezeDashText.png'
import Loader from '../components/auxiliary/loader/loader';

const CustomTextField = withStyles({
  root:{
    '& label,& label.Mui-focused, &:hover label': {
      color: '#fff',   
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -14px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    } 
  }
})(TextField)

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector(state => state.auth)
  const [loading, loader] = useState(false)
  const [isHovered, setHovered] = useReducer(state => !state, false)

  const loginHandler = async({email, password}) => {
    try{
      loader(true)
      const response = await axios.post('login',{email, password});
      if(response?.data){
        if(response.data.success){
          let {data} = response.data;
          dispatch(login(data.token, data.first_name+' '+data.last_name, data.role))
        }else{
          toaster(false, response.data.message)
        }
      }else{
        toaster(false, "An error occurred while logging in!")
      }
    }catch(err){
      toaster(false, "An error occurred while logging in!")
    }finally{
      loader(false);
    }
  }

  const accountManagerLogin = () => {
    // Redirecting to account manager login page
    navigate('/am_login', { replace: true });
  }

  useEffect(() => {
    if(isAuthenticated)
      navigate('/hub/reports?first=true', { replace: true });
  // eslint-disable-next-line
  },[isAuthenticated])

  return (
    <>
      <Helmet>
        <title>Login | BreezeDash</title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={loginHandler}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3, display: 'flex', justifyContent:'center' }}>
                      <img src={BreezeDashText} alt='' style={{width: 'auto', height: '6.25rem'}} />
                    </Box>
                    <CustomTextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <CustomTextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Typography
                      color="primary.contrastText"
                      variant="body1"
                      fontStyle='italic'
                    >
                      Forgot password?
                      {' '}
                      <Link color='primary.contrastText' component={RouterLink} to="/forgot_password" variant="h6" underline="hover">
                        Click here to reset
                      </Link>
                    </Typography>
                    <Box sx={{ py: 2 }}>
                      <Button
                        color='signin_button'
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Sign in now
                      </Button>
                    </Box>
                    <Box sx={{ py: 2 }}>
                      <Button
                        onMouseLeave={setHovered}
                        onMouseEnter={setHovered}
                        onClick={accountManagerLogin}
                        color='contrast_button'
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                      >
                        {isHovered ? 'BreezeMaxWeb staff members only!' : 'Account Manager Login'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
      {loading && <Loader />}
    </>
  );
};

export default Login;
