import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const NotesDialog = ({ open, handleClose, handleSubmit }) => {
    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Formik
                initialValues={{
                    note: ''
                }}
                validationSchema={Yup.object().shape({
                    note: Yup.string().max(5000).required('This field is mandatory'),
                })}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <Box component='form' onSubmit={handleSubmit}>
                        <DialogTitle sx={{fontWeight: 'bold', fontSize: '20px'}}>{"Add Notes"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                * Please note that once added, the note cannot be edited or removed. 
                            </DialogContentText>
                                <TextField
                                    multiline
                                    autoFocus
                                    margin="dense"
                                    id="note"
                                    name='note'
                                    label="Comments"
                                    fullWidth
                                    variant="outlined"
                                    value={values.note}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    InputProps={{sx: {minHeight: '80px'}}}
                                    error={Boolean(touched.note && errors.note)}
                                    helperText={touched.note && errors.note}
                                />
                        </DialogContent>
                        <DialogActions>
                            <Button type='button' color="primary" variant='contained' onClick={handleClose}>Cancel</Button>
                            <Button type='submit' color="primary" variant='contained'  disabled={isSubmitting}>Save</Button>
                        </DialogActions>
                    </Box>
                )}
            </Formik>
        </Dialog>
    )
}

export default NotesDialog;