import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';

const productsArray = [
    {
        id: 1,
        name: 'BreezeQuest',
        inputName: 'BreezeQuest',
        description: 'Search Engine Optimization (SEO)',
        rate_card: {
            dropdown: true,
            options: [
                'Basic - $799', 'Standard - $999','Professional - $1499','Enterprise - $1999', 'Custom'
            ],
            disabled: false
        }
    },
    {
        id: 2,
        name: 'BreezeLocal',
        inputName: 'BreezeLocal',
        description: 'Local SEO- GBP Page Management',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 3,
        name: 'BreezeReview',
        inputName: 'BreezeReview',
        description: 'Reputation Management Software',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 4,
        name: 'BreezeBack',
        inputName: 'BreezeBack',
        description: 'Automated Data Mining Software',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 5,
        name: 'BreezeAlert',
        inputName: 'BreezeAlert',
        description: 'Appointment Booking & Reminder Tool',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 6,
        name: 'BreezeTact',
        inputName: 'BreezeTact',
        description: 'Mobile Conversion Tool',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 7,
        name: 'BreezeView',
        inputName: 'BreezeView',
        description: 'Reputation Management Tool',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 8,
        name: 'BreezeChat',
        inputName: 'BreezeChat',
        description: 'Website Visitor Chat',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 9,
        name: 'BreezeBlast',
        inputName: 'BreezeBlast',
        description: 'E-mail Marketing',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 10,
        name: 'BreezeText',
        inputName: 'BreezeText',
        description: 'SMS Marketing',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 11,
        name: 'BreezeSite Rental',
        inputName: 'BreezeRent',
        description: '0% Website Financing',
        rate_card: {
            dropdown: true,
            options: ['4 pages - $99/m', '6 pages - $149/m', '10 pages - $175/m', 'E-comm - $499/m', 'Custom'],
            disabled: false
        }
    },
    {
        id: 12,
        name: 'BreezeCloud',
        inputName: 'BreezeCloud',
        description: 'Cloud Hosting',
        rate_card: {
            dropdown: true,
            options: ['$30', 'Custom'],
            disabled: false
        }
    },
    {
        id: 13,
        name: 'BreezeIT',
        inputName: 'BreezeIT',
        description: 'Network Solutions and Website Maintenance',
        rate_card: {
            dropdown: true,
            options: ['1 Hr - $125/m', '2 Hrs - $199/m', '4 Hrs - $299/m', '6 Hrs - $399/m', 'Custom'],
            disabled: false
        }
    },
    {
        id: 14,
        name: 'BreezeMail',
        inputName: 'BreezeMail',
        description: 'G Suite (Google Suite of Productivitiy Tools)',
        rate_card: {
            dropdown: true,
            options: ['Basic $15/m', 'Business $25/m', 'Enterprise $40/m', 'Custom'],
            disabled: false
        }
    },
    {
        id: 15,
        name: 'BreezeTrail',
        inputName: 'BreezeTrail',
        description: 'Recorded Call Tracking',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 16,
        name: 'BreezeHeat',
        inputName: 'BreezeHeat',
        description: 'Website Heat Map Tracking',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 17,
        name: 'BreezeWho',
        inputName: 'BreezeWho',
        description: 'B2B Website Visitor Tracking',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
    {
        id: 18,
        name: 'BreezeReports',
        inputName: 'BreezeReports',
        description: 'Reporting Dashboard',
        rate_card: {
            dropdown: false,
            options: [],
            disabled: true
        }
    },
]

const OneTimeAndMonthlyForm = ({ handleBack, handleSubmit }) => {
    return(
        <Formik
            initialValues={{
                BreezeQuest_discount: '',
                BreezeLocal_discount: '',
                BreezeReview_discount: '',
                BreezeBack_discount: '',
                BreezeAlert_discount: '',
                BreezeTact_discount: '',
                BreezeView_discount: '',
                BreezeChat_discount: '',
                BreezeBlast_discount: '',
                BreezeText_discount: '',
                BreezeCloud_discount: '',
                BreezeIT_discount: '',
                BreezeRent_discount: '',
                BreezeMail_discount: '',
                BreezeTrail_discount: '',
                BreezeHeat_discount: '',
                BreezeWho_discount: '',
                BreezeReports_discount: 'Free',

                BreezeQuest_total: '',
                BreezeLocal_total: '',
                BreezeReview_total: '',
                BreezeBack_total: '',
                BreezeTact_total: '',
                BreezeView_total: '',
                BreezeChat_total: '',
                BreezeBlast_total: '',
                BreezeText_total: '',
                BreezeCloud_total: '',
                BreezeIT_total: '',
                BreezeAlert_total: '',
                BreezeRent_total: '',
                BreezeMail_total: '',
                BreezeTrail_total: '',
                BreezeHeat_total: '',
                BreezeWho_total: '',
                BreezeReports_total: 'Free',

                BreezeQuest_rate: 'Custom',
                BreezeLocal_rate: '$299',
                BreezeReview_rate: '$399',
                BreezeBack_rate: '$399',
                BreezeTact_rate: '$49',
                BreezeView_rate: '$49',
                BreezeChat_rate: '$99',
                BreezeBlast_rate: 'Custom',
                BreezeText_rate: 'Custom',
                BreezeCloud_rate: 'Custom',
                BreezeIT_rate: 'Custom',
                BreezeAlert_rate: '$399',
                BreezeRent_rate: 'Custom',
                BreezeMail_rate: 'Custom',
                BreezeTrail_rate: '$75',
                BreezeHeat_rate: '$99',
                BreezeWho_rate: '$199',
                BreezeReports_rate: 'Free',

                BreezeQuest_res: '',
                BreezeLocal_res: '',
                BreezeReview_res: '',
                BreezeBack_res: '',
                BreezeTact_res: '',
                BreezeView_res: '',
                BreezeChat_res: '',
                BreezeBlast_res: '',
                BreezeText_res: '',
                BreezeCloud_res: '',
                BreezeIT_res: '',
                BreezeAlert_res: '',
                BreezeRent_res: '',
                BreezeMail_res: '',
                BreezeTrail_res: '',
                BreezeHeat_res: '',
                BreezeWho_res: '',
                BreezeReports_res: 'Free',
                
            }}
            validationSchema={Yup.object().shape({
                BreezeQuest_discount: Yup.string(),
                BreezeLocal_discount: Yup.string(),
                BreezeReview_discount: Yup.string(),
                BreezeBack_discount: Yup.string(),
                BreezeAlert_discount: Yup.string(),
                BreezeTact_discount: Yup.string(),
                BreezeView_discount: Yup.string(),
                BreezeChat_discount: Yup.string(),
                BreezeBlast_discount: Yup.string(),
                BreezeText_discount: Yup.string(),
                BreezeCloud_discount: Yup.string(),
                BreezeIT_discount: Yup.string(),
                BreezeRent_discount: Yup.string(),
                BreezeMail_discount: Yup.string(),
                BreezeTrail_discount: Yup.string(),
                BreezeHeat_discount: Yup.string(),
                BreezeWho_discount: Yup.string(),
                BreezeReports_discount: Yup.string(),

                BreezeQuest_total: Yup.string(),
                BreezeLocal_total: Yup.string(),
                BreezeReview_total: Yup.string(),
                BreezeBack_total: Yup.string(),
                BreezeTact_total: Yup.string(),
                BreezeView_total: Yup.string(),
                BreezeChat_total: Yup.string(),
                BreezeBlast_total: Yup.string(),
                BreezeText_total: Yup.string(),
                BreezeCloud_total: Yup.string(),
                BreezeIT_total: Yup.string(),
                BreezeAlert_total: Yup.string(),
                BreezeRent_total: Yup.string(),
                BreezeMail_total: Yup.string(),
                BreezeTrail_total: Yup.string(),
                BreezeHeat_total: Yup.string(),
                BreezeWho_total: Yup.string(),
                BreezeReports_total: Yup.string(),

                BreezeQuest_rate: Yup.string(),
                BreezeLocal_rate: Yup.string(),
                BreezeReview_rate: Yup.string(),
                BreezeBack_rate: Yup.string(),
                BreezeTact_rate: Yup.string(),
                BreezeView_rate: Yup.string(),
                BreezeChat_rate: Yup.string(),
                BreezeBlast_rate: Yup.string(),
                BreezeText_rate: Yup.string(),
                BreezeCloud_rate: Yup.string(),
                BreezeIT_rate: Yup.string(),
                BreezeAlert_rate: Yup.string(),
                BreezeRent_rate: Yup.string(),
                BreezeMail_rate: Yup.string(),
                BreezeTrail_rate: Yup.string(),
                BreezeHeat_rate: Yup.string(),
                BreezeWho_rate: Yup.string(),
                BreezeReports_rate: Yup.string(), 
                
                BreezeQuest_res: Yup.string(),
                BreezeLocal_res: Yup.string(),
                BreezeReview_res: Yup.string(),
                BreezeBack_res: Yup.string(),
                BreezeTact_res: Yup.string(),
                BreezeView_res: Yup.string(),
                BreezeChat_res: Yup.string(),
                BreezeBlast_res: Yup.string(),
                BreezeText_res: Yup.string(),
                BreezeCloud_res: Yup.string(),
                BreezeIT_res: Yup.string(),
                BreezeAlert_res: Yup.string(),
                BreezeRent_res: Yup.string(),
                BreezeMail_res: Yup.string(),
                BreezeTrail_res: Yup.string(),
                BreezeHeat_res: Yup.string(),
                BreezeWho_res: Yup.string(),
                BreezeReports_res: Yup.string(),
            })}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Box component='form' onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        #
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Product
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Description
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Creative + Research
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Rate Card
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Discount
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Total Agency Fee
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsArray.map((product) => (
                                    <TableRow
                                        hover
                                        key={product.id}
                                    >
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.description}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.inputName+'_res'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.inputName+'_res']}
                                                variant="outlined"
                                                error={Boolean(touched[product.inputName+'_res'] && errors[product.inputName+'_res'])}
                                                helperText={touched[product.inputName+'_res'] && errors[product.inputName+'_res']}
                                                disabled={product.inputName === 'BreezeReports'}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {
                                                product.rate_card.dropdown ? 
                                                <FormControl fullWidth>
                                                    <Select
                                                        id={product.inputName+'_rate'}
                                                        name={product.inputName+'_rate'}
                                                        value={values[product.inputName+'_rate']}
                                                        onChange={handleChange}
                                                        disabled={product.rate_card.disabled}
                                                    >
                                                        {
                                                            product.rate_card.options.map((option, i) => (
                                                                <MenuItem key={i} value={option}>{option}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl> : 
                                                <TextField
                                                    fullWidth
                                                    name={product.inputName+'_rate'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[product.inputName+'_rate']}
                                                    variant="outlined"
                                                    error={Boolean(touched[product.inputName+'_rate'] && errors[product.inputName+'_rate'])}
                                                    helperText={touched[product.inputName+'_rate'] && errors[product.inputName+'_rate']}
                                                    disabled = {product.rate_card.disabled}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.inputName+'_discount'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.inputName+'_discount']}
                                                variant="outlined"
                                                error={Boolean(touched[product.inputName+'_discount'] && errors[product.inputName+'_discount'])}
                                                helperText={touched[product.inputName+'_discount'] && errors[product.inputName+'_discount']}
                                                disabled={product.inputName === 'BreezeReports'}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.inputName+'_total'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.inputName+'_total']}
                                                variant="outlined"
                                                error={Boolean(touched[product.inputName+'_total'] && errors[product.inputName+'_total'])}
                                                helperText={touched[product.inputName+'_total'] && errors[product.inputName+'_total']}
                                                disabled={product.inputName === 'BreezeReports'}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{p:1, my: 1}}>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** DO NOT USE COMMAS IN NUMBERS.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** For any field left unfilled a N/A will appear automatically.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** In the total agency fees you can indicate a numerical value or the wording - FREE & Included if you choose.</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

export default OneTimeAndMonthlyForm;