import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper
} from '@mui/material';

const ContactsList = ({contacts}) => {

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
  
    const handleLimitChange = (event) => {
      setLimit(event.target.value);
    };
  
    const handlePageChange = (event, newPage) => {
      setPage(newPage);
    };

    return(
        <>
        <PerfectScrollbar>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                    Name
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Email
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Phone
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Position
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.slice(page * limit, page * limit + limit).map((contact) => (
                  <TableRow
                    hover
                    key={contact.cid}
                  >
                    <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {contact.first_name ? contact.first_name+' '+contact.last_name : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                          >
                          {contact.email || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                      >
                        {contact.phone_number || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                      >
                        {contact.position || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={contacts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      </>
    )
}

export default ContactsList;