import { Card, CardContent, Typography } from '@mui/material'

const ImageCard = ({url, title, description}) => (
    <Card sx={{display: 'flex',flexDirection: 'column',height: '100%'}}>
        <CardContent sx={{p: 0}}>
            <img alt={title} src={url} width='100%' height={200} />
        </CardContent>
        <Typography p={0.5} variant='h5' align="center" color="primary.main">
            {title}
        </Typography>
        <Typography p={0.5} variant='body1' align="center" color="text.primary">
            {description}
        </Typography>
    </Card>
)

export default ImageCard;