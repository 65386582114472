import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    IconButton,
    Card,
    CardContent,
    CardActions,
    Typography,
    Divider,
    Grid,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    CardMedia,
    Tooltip,
    Link,
    Fab
  } from '@mui/material';
import { withStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Helmet } from 'react-helmet';
import Loader from '../../components/auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../../components/auxiliary/toaster'
import defaultThumbnail from '../../assets/defaultThumb.png'
import AddIcon from '@mui/icons-material/Add';
import CreateResourceDialog from 'src/components/resourceCentre/createResource'
import DeleteResourceDialog from 'src/components/resourceCentre/deleteResource'

const LaunchNewLink = withStyles({
    root: {
        '&':{
            marginLeft: 'auto'
        }
    }
})(Link)

const initResourceObj = {
    id: 0,
    name: '',
    category: ''
}

const RESOURCE_CATEGORIES = [
    {
        name: 'SEO',
        value: 'seo'
    },
    {
        name: 'SEM',
        value: 'sem'
    },
    {
        name: 'SMM',
        value: 'smm'
    },
    {
        name: 'Websites',
        value: 'website'
    },
    {
        name: 'Network Solutions',
        value: 'network'
    },
    {
        name: 'Breeze products',
        value: 'labs'
    },
    {
        name: 'Creative',
        value: 'creative'
    },
    {
        name: 'Content',
        value: 'content'
    },
    {
        name: 'Sales',
        value: 'sales'
    },
]

const ResourceCentre = () => {

    const [loading, loader] = useState(true);
    const [category, setCategory] = useState('all');
    const [resources, setResources] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const { authToken, role} = useSelector(state => state.auth);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [attachment, setAttachment] = useState(null)
    const [resourceToBeEdited, setResourceToBeEdited] = useState(initResourceObj)
    const [resourceToBeDeleted, setResourceToBeDeleted] = useState(null)

    const resourceInputRef = useRef(null)

    const handleDialogClose = () => {
        setAttachment(null);
        setDialogOpen(false);
        if(resourceToBeEdited.id){
            setResourceToBeEdited(initResourceObj);
        }
    }

    const clearFileInput = () => {
        if(resourceInputRef.current){
            resourceInputRef.current.value = null;
        }
    }

    const resourceUploadHandler = (ev) => {
        setAttachment(ev.target.files[0]);
        clearFileInput();
    }

    const fetchResources = useCallback(async() => {
        try{
            loader(true);
            const response = await axios.get('resource?category='+category,{headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                setResources(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the linked profiles!")
              loader(false);
            }
          }catch(err){
            toaster(false, "An error occurred while fetching the linked profiles!")
            loader(false);
          }
    }, [authToken, category])

    const createResource = async(values, {resetForm}) => {
        if(attachment){
            loader(true);

            let postData = new FormData();
            postData.append('name', values.name);
            postData.append('category', values.category);
            postData.append('file', attachment);
    
            try{
                const response = await axios.post('resource',postData,{
                    headers: {
                        'Authorization': 'Bearer '+ authToken,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(response?.data){
                  if(response.data.success){
                    toaster(true,'The resource has been added!')
                    resetForm();
                    handleDialogClose();
                    fetchResources();
                  }else{
                    toaster(false, response.data.message)
                  }
                }else{
                  toaster(false, "An error occurred while creating the resource!")
                }
            }catch(err){
                toaster(false, "An error occurred while creating the resource!")
            }finally{
                loader(false);
            }
        }else{
            toaster(false, 'Please select a resource to upload');
        }
    }

    const handleEditResourceClick = (resource) => {
        setResourceToBeEdited(resource);
        setDialogOpen(true);
    }

    const updateResource = async(values) => {
        loader(true);

        let postData = new FormData();
        postData.append('name', values.name);
        postData.append('category', values.category);
        postData.append('_method', 'put')
        if(attachment){
            postData.append('file', attachment);
        }

        try{
            const response = await axios.post('resource/'+values.id,postData,{
                headers: {
                    'Authorization': 'Bearer '+ authToken,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response?.data){
              if(response.data.success){
                toaster(true,'The resource has been updated!')
                handleDialogClose();
                fetchResources();
              }else{
                toaster(false, response.data.message)
              }
            }else{
              toaster(false, "An error occurred while updating the resource!")
            }
        }catch(err){
            toaster(false, "An error occurred while updating the resource!")
        }finally{
            loader(false);
        }
    }

    const handleDeleteResourceClick = (resource) => {
        setResourceToBeDeleted(resource);
    }

    const deleteResource = async() => {
        loader(true)
        try{
            const response = await axios.delete('resource/'+resourceToBeDeleted.id,{
                headers: {
                    'Authorization': 'Bearer '+ authToken,
                }
            });
            if(response?.data){
              if(response.data.success){
                toaster(true,'The resource has been deleted!')
                setResourceToBeDeleted(null);
                fetchResources();
              }else{
                toaster(false, response.data.message)
              }
            }else{
              toaster(false, "An error occurred while deleting the resource!")
            }
        }catch(err){
            toaster(false, "An error occurred while deleting the resource!")
        }finally{
            loader(false);
        }
    }

    useEffect(() => {
        fetchResources();
    }, [fetchResources])

    let filteredResources = resources.filter(resource => (!searchKey || resource.name.toLowerCase().includes(searchKey.toLowerCase())))

    let isAdmin = role === 'admin' || role === 'manager';

    return <>
    <Helmet>
        <title>Resource Centre | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            p: 3
        }}
    >
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="category">Category</InputLabel>
                            <Select
                                labelId="category"
                                id="category"
                                value={category}
                                label="Category"
                                onChange={ev => setCategory(ev.target.value)}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {
                                    RESOURCE_CATEGORIES.map(category => (
                                        <MenuItem key={category.name} value={category.value}>{category.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Search resources"
                            name="search"
                            onChange={ev => setSearchKey(ev.target.value)}
                            value={searchKey}
                            variant="outlined"
                            type="search"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Box py={2}>
            {
                filteredResources.length ?
                <Grid container spacing={2}> 
                {
                    filteredResources.map((resource) => (
                        <Grid key={resource.id} item xs={6} sm={4} md={3} lg={2}>
                            <Card>
                                <CardMedia 
                                    component="img"
                                    height={160}
                                    image={defaultThumbnail}
                                    alt=''
                                    sx={{objectFit: 'contain'}}
                                />
                                <CardContent>
                                    <Tooltip title={resource.name} arrow>
                                        <Typography noWrap variant="h5" component="div">
                                            {resource.name}
                                        </Typography>
                                    </Tooltip>
                                </CardContent>
                                <Divider variant='middle' />
                                <CardActions disableSpacing>
                                    {isAdmin && 
                                    <>
                                        <Tooltip title={'Edit'} arrow>
                                            <IconButton onClick={() => handleEditResourceClick(resource)} size="large">
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Delete'} arrow>
                                            <IconButton onClick={() => handleDeleteResourceClick(resource)} size="large">
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>}
                                    <Tooltip title={'Open'} arrow>
                                        <LaunchNewLink href={resource.path} underline='none' target='_blank' rel="noopener noreferrer">
                                            <IconButton size="large">
                                                <LaunchIcon />
                                            </IconButton>
                                        </LaunchNewLink>
                                    </Tooltip>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid> :
            <Typography textAlign={'center'} variant='h3' my={2}>
                No resource available!
            </Typography>}
        </Box>
    </Box>
    {isAdmin &&
    <>
        <Fab onClick={() => setDialogOpen(true)} sx={{position: 'fixed', bottom: '16px', right: '24px', boxShadow: '0 0 1px 0 #888, 0 4px 6px -2px #888'}} color="primary" aria-label="add" variant='extended'>
        <AddIcon sx={{ mr: 1 }} />
        Add Resource
        </Fab>
        <CreateResourceDialog 
            resourceCategories = {RESOURCE_CATEGORIES}
            open={dialogOpen} 
            handleClose={handleDialogClose} 
            handleCreateResource={createResource} 
            handleEditResource={updateResource} 
            resourceToBeEdited={resourceToBeEdited} 
            fileInputRef = {resourceInputRef}
            fileUploadHandler = {resourceUploadHandler}
            attachment = {attachment}
        />
        <DeleteResourceDialog
            open={!!resourceToBeDeleted}
            handleClose={() => setResourceToBeDeleted(null)}
            handleSubmit={deleteResource}
            resourceToBeDeleted={resourceToBeDeleted}
        />
    </>}
    {loading && <Loader />}
    </>;
}

export default ResourceCentre;