import { useMemo } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactLightbox from 'simple-react-lightbox'

// eslint-disable-next-line
Date.prototype.toMySqlDate = function(){
  var month = '' + (this.getMonth() + 1),
  day = '' + this.getDate(),
  year = this.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}

const App = () => {

  const {isAuthenticated} = useSelector(state => state.auth);

  const content = useRoutes(useMemo(() => routes(isAuthenticated), [isAuthenticated]));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SimpleReactLightbox>
          <GlobalStyles />
          {content}
        </SimpleReactLightbox>
      </ThemeProvider>
      <ToastContainer hideProgressBar={true} />
    </StyledEngineProvider>
  );
};

export default App;
