import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Card, CardHeader, CardContent, Typography, Fab, IconButton } from '@mui/material';
import Loader from '../../components/auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../../components/auxiliary/toaster'
import AddIcon from '@mui/icons-material/Add';
import NotesDialog from '../../components/notes/NotesDialog' 
import { ArrowLeft } from 'react-feather'

const Notes = () => {

    const navigate = useNavigate()

    const [loading, loader] = useState(false);
    const { authToken } = useSelector(state => state.auth);
    const [notes, setNotes] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const fetchNotes = useCallback(async() => {
        try{
            loader(true);
            const response = await axios.get('note?profile_id='+localStorage.profileId,{headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                setNotes(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the notes!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the notes!")
            loader(false);
        }
    },[authToken])

    const handleAddNote = async(values, {resetForm}) => {
        let postData = {
            profile_id: localStorage.profileId,
            text: values.note
        }
        try{
            loader(true);
            const response = await axios.post('note', postData, {headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                fetchNotes();
                setDialogOpen(false);
                resetForm();
              }
              toaster(response.data.success, response.data.message)
            }else{
              toaster(false, "An error occurred while adding the notes!")
            }
        }catch(err){
            toaster(false, "An error occurred while adding the notes!")
        }finally{
            loader(false);
        }
    }

    useEffect(() => {
        fetchNotes();
    },[fetchNotes])

    return <>
        <Helmet>
            <title>Notes | BreezeDash</title>
        </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{display: 'flex', alignItems:'center',p:1}}>
                    <IconButton
                        sx={{color:'primary.main'}}
                        onClick={() => navigate('/hub/reports', { replace: true })}
                        size="large">
                        <ArrowLeft />
                    </IconButton>
                    <Typography variant='h2' sx={{p:1, my: 1}}>{localStorage.profileName}</Typography>
                </Box>
                    {notes.length ? 
                        notes.map(note => {
                            let dateStringArr = new Date(note.created_at).toDateString().split(' '),
                                timeString = new Date(note.created_at).toLocaleTimeString();
                            return(
                            <Card key={note.id} sx={{my: 2}}>
                                <CardHeader 
                                    title={note.created_by} 
                                    subheader={dateStringArr[1]+' '+dateStringArr[2]+', '+dateStringArr[3]+' '+timeString} 
                                    titleTypographyProps={{variant: 'h4',color: 'primary.main'}} 
                                    sx={{py:1}}
                                />
                                <CardContent>
                                    <Typography variant='body1'>
                                        {note.text}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}) : 
                        <Typography variant='h4' textAlign={'center'}>No prior notes available!</Typography>
                    }
            </Container>
            <Fab onClick={() => setDialogOpen(true)} sx={{position: 'fixed', bottom: '16px', right: '24px', boxShadow: '0 0 1px 0 #888, 0 4px 6px -2px #888'}} color="primary" aria-label="add" variant='extended'>
                <AddIcon sx={{ mr: 1 }} />
                Add Notes
            </Fab>
        </Box>
        <NotesDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} handleSubmit={handleAddNote} />
        {loading && <Loader />}
    </>;
}

export default Notes;