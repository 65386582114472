import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography, Card, CardContent
} from '@mui/material';
import { withStyles } from '@mui/styles';
import axios from '../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import { useSelector } from 'react-redux'
import BreezeDashText from '../assets/BreezeDashText.png'

const CustomTextField = withStyles({
  root:{
    '& label,& label.Mui-focused, &:hover label': {
      color: '#fff'
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -14px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    } 
  }
})(TextField)

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {authToken} = useSelector(state => state.auth)

  const email = useRef('');
  const token = useRef('');

  const loginHandler = async({password, confirmPassword}) => {
    try{
      const response = await axios.post('reset-password',{email: email.current, token: token.current, password, password_confirmation: confirmPassword}, {headers: {'Authorization': 'Bearer '+ authToken}});
      if(response?.data){
        if(response.data.success){
            toaster(true, response.data.message)
            navigate('/login', { replace: true });
        }else{
          toaster(false, response.data.message)
        }
      }else{
        toaster(false, "An error occurred while resetting your password!")
      }
    }catch(err){
      toaster(false, "An error occurred while resetting your password!")
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if(params.get('email') && params.get('token')){
        email.current = params.get('email');
        token.current = params.get('token');
    }else{
        navigate('/login', { replace: true });
    }
  },[navigate,location.search])

  return (
    <>
      <Helmet>
        <title>Reset Password | BreezeDash</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: ''
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().min(8).max(255).required('Password is required.'),
                  confirmPassword: Yup.string().min(8).max(255).required('Please re-enter the password.').when('password',{
                      is: val => (val && val.length > 0 ? true : false),
                      then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match.")
                  })
                })}
                onSubmit={loginHandler}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3, display: 'flex', justifyContent:'center' }}>
                        <img src={BreezeDashText} alt='' style={{width: 'auto', height: '6.25rem'}} />
                    </Box>
                    <CustomTextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="New Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <CustomTextField
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      fullWidth
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      label="Re-enter Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="button"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Reset Password
                      </Button>
                    </Box>
                    <Typography
                      color="primary.contrastText"
                      variant="body1"
                      textAlign='center'
                    >
                      <Link color='primary.contrastText' component={RouterLink} to="/login" variant="h6" underline="hover">
                        Back to Login
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
