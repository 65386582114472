import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';

const regexURL = '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

const SearchAdsQuote = (props) => {

  const [loading, loader] = useState(false)

  const submitQuoteRequest = async(values) => {
    try{
      loader(true)
      const response = await axios.post('search_ads_quote',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Your request has been submitted!')
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while creating the request!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while creating the request!")
      loader(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Search Ads Quote | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Formik
                initialValues={{
                    company_name: '',
                    website_url: '',
                    phrase_type: '',
                    location: '',
                    budget: '',
                }}
                validationSchema={Yup.object().shape({
                    company_name: Yup.string().max(100).required('Company Name is required'),
                    website_url: Yup.string().matches(regexURL,'Enter a valid website URL').required('Website URL is required'),
                    phrase_type: Yup.string().max(255).required('Phrase type is required'),
                    location: Yup.string().max(255).required('Location is required'),
                    budget: Yup.string().max(100).required('Budget is required'),
                })}
                onSubmit={submitQuoteRequest}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                    onSubmit={handleSubmit}
                    >
                    <Card sx={{p: 2}}>
                        <Typography variant='h2' textAlign='center' color={'primary.main'}>Search Ads Quote Request</Typography>
                        <Typography variant='body1' textAlign='center'>Please fill out the client's details</Typography>
                        <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Company Name"
                                name="company_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.company_name}
                                variant="outlined"
                                error={Boolean(touched.company_name && errors.company_name)}
                                helperText={touched.company_name && errors.company_name}
                            />
                            </Grid>
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Website URL"
                                name="website_url"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.website_url}
                                variant="outlined"
                                error={Boolean(touched.website_url && errors.website_url)}
                                helperText={touched.website_url && errors.website_url}
                            />
                            </Grid>
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Location"
                                name="location"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                type="text"
                                value={values.location}
                                variant="outlined"
                                error={Boolean(touched.location && errors.location)}
                                helperText={touched.location && errors.location}
                            />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Budget"
                                    name="budget"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    type="text"
                                    value={values.budget}
                                    variant="outlined"
                                    error={Boolean(touched.budget && errors.budget)}
                                    helperText={touched.budget && errors.budget}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Products / Services / Keyword ideas"
                                    name="phrase_type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.phrase_type}
                                    variant="outlined"
                                    error={Boolean(touched.phrase_type && errors.phrase_type)}
                                    helperText={touched.phrase_type && errors.phrase_type}
                                />
                            </Grid>
                        </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                        >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit Request
                        </Button>
                        </Box>
                    </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>
  );
};

export default SearchAdsQuote;
