import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import NavItem from './NavItem';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const items = [
  {
    href: '/hub/reports',
    title: 'Home',
    icon: HomeOutlinedIcon
  },
  {
    href: '/reports/profile',
    title: 'Company Profile'
  },
  {
    href: '/reports/breezenet',
    title: 'BreezeNet (Google Ads)'
  },
  {
    href: '/reports/breezesocial',
    title: 'BreezeSocial (Social Media Ads)'
  },
  {
    href: '/reports/quest_analytics',
    title: 'BreezeQuest (Analytics)'
  },
  {
    href: '/reports/quest_ranking',
    title: 'BreezeQuest (Ranking)'
  },
  {
    href: '/reports/quest_backlinks',
    title: 'BreezeQuest (Backlinks)'
  },
  {
    href: '/reports/breezeheat',
    title: 'BreezeHeat (Heat Mapping)'
  },
  {
    href: '/reports/breezetact',
    title: 'BreezeTact (Mobile Conversions)'
  },
  {
    href: '/reports/breezeview',
    title: 'BreezeView (Review Lookups)'
  },
  {
    href: '/reports/breezepress',
    title: 'BreezePress (Blog)'
  },
  {
    href: '/reports/breezeguest',
    title: 'GuestBlogs (Blog)'
  },
  {
    href: '/reports/breezelocal',
    title: 'BreezeLocal (Google Business Profile)'
  },
  {
    href: '/reports/social_book',
    title: 'Social Bookmark (Social)'
  },
  {
    href: '/reports/breezewho',
    title: 'BreezeWho (Lead Generation)'
  }
];

const BreezeReportsSideBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  const user = {
    name: localStorage.name
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {'Hi,'}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {user.name}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>

          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 75,
            height: 'calc(100% - 64px)'
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

BreezeReportsSideBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

BreezeReportsSideBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default BreezeReportsSideBar;
