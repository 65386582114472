import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';

const regexURL = '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

const NOTE_MAX_LIMIT = 1000;

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const WebsiteQuote = (props) => {

  const [loading, loader] = useState(false)

  const submitQuoteRequest = async(values) => {
    const { business_name, have_website, website_url, pages, ecommerce, cms, product_type, product_org, shipping, payment_method, additional_notes } = values;
    let postData = {
        business_name,
        have_website,
        pages,
        ecommerce,
        cms,
        additional_notes
    }
    if(have_website === 'Yes'){
        postData = { ...postData, website_url }
    }
    if(ecommerce === 'Yes'){
        postData = { ...postData, product_type, product_org, shipping, payment_method }
    }
    try{
      loader(true)
      const response = await axios.post('website_quote',postData,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Your request has been submitted!')
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while creating the request!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while creating the request!")
      loader(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Website Quote | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Formik
                initialValues={{
                    business_name: '',
                    have_website: '',
                    website_url: '',
                    pages: '',
                    ecommerce: '',
                    cms: '',
                    product_type: '',
                    product_org: '',
                    shipping: '',
                    payment_method: '',
                    additional_notes: ''
                }}
                validationSchema={Yup.object().shape({
                    business_name: Yup.string().max(100).required('Company Name is required'),
                    have_website: Yup.string().required('This field is required'),
                    website_url: Yup.string().when('have_website',{
                        is: val => val === 'Yes',
                        then: Yup.string().matches(regexURL,'Enter a valid website URL').required('Website URL is required')
                    }),
                    pages: Yup.number().positive().integer().required('Number of pages is required'),
                    cms: Yup.string().required('This field is required'),
                    ecommerce: Yup.string().required('This field is required'),
                    product_type: Yup.string().when('ecommerce',{
                        is: val => val === 'Yes',
                        then: Yup.string().required('This field is required')
                    }),
                    product_org: Yup.string().when('ecommerce',{
                        is: val => val === 'Yes',
                        then: Yup.string().max(255).required('This field is required')
                    }),
                    shipping: Yup.string().when('ecommerce',{
                        is: val => val === 'Yes',
                        then: Yup.string().required('This field is required')
                    }),
                    payment_method: Yup.string().when('ecommerce',{
                        is: val => val === 'Yes',
                        then: Yup.string().required('This field is required')
                    }),
                    additional_notes: Yup.string().max(NOTE_MAX_LIMIT)
                })}
                onSubmit={submitQuoteRequest}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                    onSubmit={handleSubmit}
                    >
                    <Card sx={{p: 2}}>
                        <Typography variant='h2' textAlign='center' color={'primary.main'}>Website Quote Request</Typography>
                        <Typography variant='body1' textAlign='center'>Please fill out the client's details</Typography>
                        <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                            item
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Business Name"
                                name="business_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.business_name}
                                variant="outlined"
                                error={Boolean(touched.business_name && errors.business_name)}
                                helperText={touched.business_name && errors.business_name}
                            />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl required component="fieldset">
                                    <FormLabel component="legend">Have a current website?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="website"
                                    >
                                        <FormControlLabel name="have_website" value="Yes" control={<Field required type='radio' component={MyRadioInput} />} label="Yes" />
                                        <FormControlLabel name="have_website" value="No" control={<Field required type='radio' component={MyRadioInput} />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            { values.have_website === 'Yes' &&
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Website URL"
                                    name="website_url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.website_url}
                                    variant="outlined"
                                    error={Boolean(touched.website_url && errors.website_url)}
                                    helperText={touched.website_url && errors.website_url}
                                />
                            </Grid>}
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Number of pages"
                                    name="pages"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.pages}
                                    variant="outlined"
                                    error={Boolean(touched.pages && errors.pages)}
                                    helperText={touched.pages && errors.pages}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl required fullWidth>
                                    <InputLabel id="cms">Preferred CMS</InputLabel>
                                    <Select
                                        labelId="cms"
                                        id="cms"
                                        name='cms'
                                        value={values.cms}
                                        label="Preferred CMS"
                                        onChange={handleChange}
                                        required
                                    >
                                        <MenuItem value='Wordpress'>Wordpress</MenuItem>
                                        <MenuItem value='Magento'>Magento</MenuItem>
                                        <MenuItem value='Shopify'>Shopify</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl required component="fieldset">
                                    <FormLabel component="legend">Looking for an e-commerce site?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="e-commerce site"
                                    >
                                        <FormControlLabel name="ecommerce" value="Yes" control={<Field required type='radio' component={MyRadioInput} />} label="Yes" />
                                        <FormControlLabel name="ecommerce" value="No" control={<Field required type='radio' component={MyRadioInput} />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            { values.ecommerce === 'Yes' &&
                            <>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl required fullWidth>
                                    <InputLabel id="product_type">Product Type</InputLabel>
                                    <Select
                                        labelId="product_type"
                                        id="product_type"
                                        name='product_type'
                                        value={values.product_type}
                                        label="Product Type"
                                        onChange={handleChange}
                                        required
                                    >
                                        <MenuItem value='Simple'>Simple</MenuItem>
                                        <MenuItem value='Variable'>Variable</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography variant='subtitle2'><b>* Simple products</b> have one SKU, are shipped, and have no variations/options. For example, a book.</Typography>
                                <Typography variant='subtitle2'><b>* Variable products</b> can have multiple variations/options, each of which may have a different SKU or price. For example, a piece of clothing that is available in a variety of colors and sizes.</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Product Organization"
                                    name="product_org"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.product_org}
                                    variant="outlined"
                                    error={Boolean(touched.product_org && errors.product_org)}
                                    helperText={touched.product_org && errors.product_org}
                                />
                                <Typography variant='subtitle2'>Please advise if the client will be prepared to provide all the products on a spreadsheet with high resolution images.</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Shipping Conditions"
                                    name="shipping"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.shipping}
                                    variant="outlined"
                                    error={Boolean(touched.shipping && errors.shipping)}
                                    helperText={touched.shipping && errors.shipping}
                                />
                                <Typography variant='subtitle2' my={1}>Below are some shipping options:</Typography>
                                <Typography variant='subtitle2'>* <b>Free shipping:</b> we don't need a plugin for this method</Typography>
                                <Typography variant='subtitle2'>* <b>Flat rate:</b> for example, $15 per order - we don't need a plugin for this method</Typography>
                                <Typography variant='subtitle2'>* <b>Local pickup:</b> we don't need a plugin for this method</Typography>
                                <Typography variant='subtitle2'>* <b>Canada Post:</b> it will generate shipping fees based on weight and dimensions. In order to implement this, we need to purchase a plugin (~ USD 79). Also the client needs to create a Canada Post account and provide us weight (mandatory) and dimensions (optional) for each product.</Typography>
                                <Typography variant='subtitle2'>* <b>UPS:</b> it will generate shipping fees based on weight and dimensions. In order to implement this, we need to purchase a plugin (~ USD 79). Also the client needs to create a UPS account and provide us weight (mandatory) and dimensions (optional) for each product.</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Payment Method"
                                    name="payment_method"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.payment_method}
                                    variant="outlined"
                                    error={Boolean(touched.payment_method && errors.payment_method)}
                                    helperText={touched.payment_method && errors.payment_method}
                                />
                                <Typography variant='subtitle2' my={1}>For example, <b>PayPal, Stripe, Moneris, Square</b></Typography>
                            </Grid>
                            </>}
                            <Grid
                                item
                                xs={12}
                            >
                                <TextField
                                    multiline
                                    fullWidth
                                    inputProps={{maxLength: NOTE_MAX_LIMIT}}
                                    InputProps={{sx: {minHeight: '80px'}}}
                                    label="Additional Notes"
                                    name="additional_notes"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.additional_notes}
                                    variant="outlined"
                                    error={Boolean(touched.additional_notes && errors.additional_notes)}
                                    helperText={touched.additional_notes && errors.additional_notes}
                                />
                                <Typography variant='caption'>{`${values.additional_notes.length}/${NOTE_MAX_LIMIT}`}</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                        >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit Request
                        </Button>
                        </Box>
                    </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>
  );
};

export default WebsiteQuote;
