import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import BreezeReportsSideBar from './BreezeReportsSideBar';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowLeft } from 'react-feather'
import Footer from './Footer'

const BreezeReportsLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const BreezeReportsLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const BreezeReportsLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const BreezeReportsLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const BreezeReportsLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate()

  return <>
  <BreezeReportsLayoutRoot>
    <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
    <BreezeReportsSideBar
      onMobileClose={() => setMobileNavOpen(false)}
      openMobile={isMobileNavOpen}
    />
    <BreezeReportsLayoutWrapper>
      <BreezeReportsLayoutContainer>
        <BreezeReportsLayoutContent>
          <Box sx={{display: 'flex', alignItems:'center',p:1}}>
            <IconButton
              sx={{color:'primary.main'}}
              onClick={() => navigate('/hub/reports', { replace: true })}
              size="large">
              <ArrowLeft />
            </IconButton>
            <Typography variant='h2' sx={{p:1, my: 1}}>{localStorage.profileName}</Typography>
          </Box>
          <Outlet />
          <Footer />
        </BreezeReportsLayoutContent>
      </BreezeReportsLayoutContainer>
    </BreezeReportsLayoutWrapper>
  </BreezeReportsLayoutRoot>
  </>;
};

export default BreezeReportsLayout;
