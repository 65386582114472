import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Checkbox
} from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold'
  },
}))(TableCell);

const ProfileList = ({ profiles, selectedRows, onSelectClick, onSelectAllClick, numSelected, rowCount, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(event)
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      style={{color: '#fff'}}
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                    Profile Id
                  </StyledTableCell>
                  <StyledTableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Profile Name
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles.slice(page * limit, page * limit + limit).map((profile, index) => {
                  const isItemSelected = selectedRows.includes(profile.profile_id);
                  const labelId = `checkbox-${index}`;
                  return(
                  <TableRow
                    hover
                    key={profile.profile_id}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    onClick={ ev => onSelectClick(ev, profile.profile_id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {profile.profile_id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                          >
                          {profile.business_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={profiles.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ProfileList.propTypes = {
  profiles: PropTypes.array.isRequired
};

export default ProfileList;
