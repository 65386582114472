import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Box, Container, Typography } from '@mui/material';
import LightBox from 'src/components/auxiliary/lightBox';

const BreezeHeat = () => {
    const [loading, loader] = useState(false)
    const [file, setFile] = useState('')

    const fetchImage = async() => {
        loader(true);
        try{
            const response = await axios.post('heatmap',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setFile(response.data.data.file)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    useEffect(() => {
        fetchImage();
    }, [])

    return(
        <>
            <Helmet>
                <title>BreezeHeat | BreezeDash</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'BreezeHeat (Heat Mapping)'}</Typography>
                <LightBox>
                    <Box sx={{p: 1, width:1}}>
                        {file ? <img src={file} alt='' width='100%' style={{objectFit: 'cover'}} /> : <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>}
                    </Box>
                </LightBox>
            </Container>
            </Box>
            {loading && <Loader />}
        </>
        )

}

export default BreezeHeat;