import { useState, useEffect } from 'react';
import Loader from 'src/components/auxiliary/loader/loader'
import axios from 'src/axios'
import {toaster} from 'src/components/auxiliary/toaster'
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';
import { Box, Typography } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import Dashboard from 'src/components/breezewho/dashboard';
import Leads from 'src/components/breezewho/leads'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`breezewho-tabpanel-${index}`}
        aria-labelledby={`breezewho-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2, height: '100%' }}>
              {children}
          </Box>
        )}
      </div>
    );
}


const BreezeWho = () => {

    const [loading, loader] = useState(false)
    const {authToken} = useSelector(state => state.auth);
    const [active, setActive] = useState(false)
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    }

    const handleTabPanelChange = (index) => {
        setTab(index);
    }

    useEffect(() => {
        const fetchData = async() => {
            try{
                loader(true);
                const response = await axios.post('breezewho_check',{profile_id: localStorage.profileId},{headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    setActive(response.data.account);
                  }else{
                    toaster(false, response.data.message)
                  }
                }else{
                  toaster(false, "An error occurred while fetching the status!")
                }
              }catch(err){
                toaster(false, "An error occurred while fetching the status!")
              }finally{
                loader(false);
              }
        }
        fetchData();
    }, [authToken])

    return(
    <>
        <Helmet>
            <title>BreezeWho | BreezeDash</title>
        </Helmet>
        <Typography variant='h2' textAlign={'center'} sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>BreezeWho (Lead Generation)</Typography>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
          }}
        >
        {
          active ? 
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="BreezeWho Tabs"
                    textColor='primary'
                    indicatorColor='primary'
                >
                    <Tab label="Overview" sx={{fontWeight: 700}} icon={<DashboardOutlinedIcon />} iconPosition='start' />
                    <Tab label="Leads" sx={{fontWeight: 700}} icon={<EqualizerOutlinedIcon />} iconPosition='start' />
                </Tabs>
            </Box>
            <SwipeableViews
                axis='x'
                index={tab}
                onChangeIndex={handleTabPanelChange}
            >
                <TabPanel value={tab} index={0}>
                    <Dashboard />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Leads />
                </TabPanel>
            </SwipeableViews>
          </> :
          <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>
        }
        </Box>
        {loading && <Loader />}
    </>
    )
};

export default BreezeWho;