import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Card, CardContent, Select, FormControl, InputLabel, MenuItem, Typography, Button, Grid } from '@mui/material';
import ProfileList from '../../components/manageAccounts/ProfileList';
import Loader from '../../components/auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../../components/auxiliary/toaster'

const ManageAccounts = () => {

    const [loading, loader] = useState(true);
    const {authToken} = useSelector(state => state.auth);
    const [reps, setReps] = useState([]);
    const [selectedRep, setSelectedRep] = useState(0);
    const [clientProfiles, setClientProfiles] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRecipient, setSelectedRecipient] = useState(0);

    useEffect(() => {
        const fetchReps = async() => {
            try{
                const response = await axios.get('reps',{headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    setReps(response.data.data)
                  }else{
                    toaster(false, response.data.message)
                  }
                  loader(false);
                }else{
                  toaster(false, "An error occurred while fetching the details!")
                  loader(false);
                }
              }catch(err){
                toaster(false, "An error occurred while fetching the details!")
                loader(false);
              }
        }
        fetchReps();
    }, [authToken])

    const fetchClientsByRepId = useCallback(async() => {
        try{
            loader(true);
            const response = await axios.get('rep_clients/'+selectedRep,{headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                setClientProfiles(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the linked profiles!")
              loader(false);
            }
          }catch(err){
            toaster(false, "An error occurred while fetching the linked profiles!")
            loader(false);
          }
    },[selectedRep, authToken])

    const handleRepChange = (ev) => {
        setSelectedRep(ev.target.value);
    }

    const handleRecipientChange = (ev) => {
        setSelectedRecipient(ev.target.value)
    }

    useEffect(() => {
        if(selectedRep){
            fetchClientsByRepId();
        }else{
            setClientProfiles([]);
        }
    },[selectedRep, fetchClientsByRepId])

    const handleSelectAllRows = (ev) => {
        if(ev.target.checked){
            const selectedRows = clientProfiles.map(profile => profile.profile_id);
            setSelectedRows(selectedRows);
            return
        }
        setSelectedRows([]);
    }

    const handleRowSelect = (ev, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
        );
        }
        setSelectedRows(newSelectedRows);
    }

    const clearSelections = () => {
        setSelectedRows([]);
        setSelectedRecipient(0);
    }

    const transferAccounts = async() => {
        try{
            loader(true);
            let postData = {
                rep_id: selectedRecipient,
                profile_ids: selectedRows
            }
            const response = await axios.post('change_profile_rep', postData, {headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                toaster(true, "Accounts transferred successfully!");
                fetchClientsByRepId();
                clearSelections();
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while transferring the accounts!")
              loader(false);
            }
          }catch(err){
            toaster(false, "An error occurred while transferring the accounts!")
            loader(false);
          }
    }

    return (
        <>
        <Helmet>
            <title>Manage Accounts | BreezeDash</title>
        </Helmet>
        <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
        >
        <Container maxWidth={false}>
            <Box sx={{ mt: 3 }}>
                <Card>
                    <CardContent>
                        <Box sx={{ maxWidth: 500 }}>
                            <FormControl sx={{width: 1}} variant="outlined">
                                <InputLabel id="rep_select_label">Select Account Manager</InputLabel>
                                <Select
                                    labelId="rep_select_label"
                                    id="rep_select"
                                    value={selectedRep}
                                    onChange={handleRepChange}
                                    label="Select Account Manager"
                                >
                                    <MenuItem value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        reps.map(rep => (
                                            <MenuItem key={rep.id} value={rep.id}>{rep.first_name+' '+rep.last_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ pt: 3 }}>
                <ProfileList 
                    profiles={clientProfiles} 
                    selectedRows = {selectedRows}
                    onSelectClick={handleRowSelect} 
                    onSelectAllClick={handleSelectAllRows} 
                    rowCount={clientProfiles.length} 
                    numSelected={selectedRows.length}
                />
            </Box>
            <Box sx={{ mt: 3 }}>
                <Card>
                    <CardContent>
                        <Typography variant='h5' mb={2}>
                            Transfer Accounts
                        </Typography>
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item lg={6} md={12} xs={12}>
                                <Box sx={{ maxWidth: 500 }}>
                                    <FormControl sx={{width: 1}} variant="outlined">
                                        <InputLabel id="recipient_select_label">Select Recipient</InputLabel>
                                        <Select
                                            labelId="recipient_select_label"
                                            id="recipient_select"
                                            value={selectedRecipient}
                                            onChange={handleRecipientChange}
                                            label="Select Recipient"
                                            disabled={selectedRows.length === 0 || selectedRep === 0}
                                        >
                                            <MenuItem value={0}>
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                reps.map(rep => (
                                                    <MenuItem key={rep.id} value={rep.id}>{rep.first_name+' '+rep.last_name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={12} xs={12}>
                                <Box sx={{ maxWidth: 500, width: 1 }}>
                                    <Button 
                                        type='button' 
                                        variant='contained'
                                        disabled={selectedRows.length === 0 || selectedRep === 0 || selectedRecipient === 0}
                                        onClick={transferAccounts}
                                    >
                                        Transfer
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Container>
        <Outlet />
        </Box>
        {loading && <Loader />}
    </>
    )
}

export default ManageAccounts;