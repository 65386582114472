import { useState, useEffect } from 'react'
import Loader from '../auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../auxiliary/toaster'
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,   
  TableRow,
  Typography,
  Paper,
  Avatar,
  Grid,
  TextField
} from '@mui/material';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ContactModal from './contactModal';
import IconButton from '@mui/material/IconButton'
import ViewIcon from '@mui/icons-material/Visibility';

const Leads = () => {

    const [loading, loader] = useState(false)
    const [leads, setLeads] = useState([])
    const {authToken} = useSelector(state => state.auth)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedLead, setSelectedLead] = useState({})
    const [contacts, setContacts] = useState([])

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [endDate, setEndDate] = useState(new Date());

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleViewBtnClick = (lead) => {
        setSelectedLead(lead);
        setModalOpen(true);
    }

    useEffect(() => {
        const fetchData = async() => {
            try{
                loader(true);
                const response = await axios.post('breezewho_lead',
                {
                    profile_id: localStorage.profileId,
                    start_date: startDate.toMySqlDate(),
                    end_date: endDate.toMySqlDate()
                },
                {headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    setLeads(response.data.data);
                  }else{
                    toaster(false, response.data.message)
                  }
                  loader(false);
                }else{
                  toaster(false, "An error occurred while fetching the leads!")
                  loader(false);
                }
              }catch(err){
                toaster(false, "An error occurred while fetching the leads!")
                loader(false);
              }
        }
        fetchData();
    }, [authToken, startDate, endDate])

    useEffect(() => {
        if(selectedLead.id){
            const fetchContacts = async() => {
                try{
                    const response = await axios.post('breezewho_contacts',
                    {
                        id: selectedLead.id,
                    },
                    {headers: {'Authorization': 'Bearer '+ authToken}});
                    if(response?.data){
                      if(response.data.success){
                        setContacts(response.data.data);
                      }else{
                        toaster(false, response.data.message)
                      }
                    }else{
                      toaster(false, "An error occurred while fetching the contacts!")
                    }
                }catch(err){
                    toaster(false, "An error occurred while fetching the contacts!")
                }
            }
            fetchContacts();
        }
    },[selectedLead.id, authToken])

    return <>
    <Card>
        <PerfectScrollbar>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={3} sx={{p:2}}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                            setStartDate(newValue);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} helperText='mm/dd/yyyy' />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => {
                            setEndDate(newValue);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} helperText='mm/dd/yyyy' />}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Typography variant='h4' mb={2} px={2}>{'Displaying data of: '+ startDate.toMySqlDate() + ' to ' + endDate.toMySqlDate()}</Typography>
            <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                            Logo
                        </TableCell>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                            Name
                        </TableCell>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                            Last Visit
                        </TableCell>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                            Time Spent
                        </TableCell>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                        Source / Medium
                        </TableCell>
                        <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                            View Info
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {leads.slice(page * limit, page * limit + limit).map((lead) => (
                    <TableRow
                        hover
                        key={lead.id}
                    >
                        <TableCell>
                        <Box
                            sx={{
                            alignItems: 'center',
                            display: 'flex'
                            }}
                        >
                            <Avatar alt='Company logo' src={lead.logo} sx={{width: 50, height: 50}} imgProps={{objectFit: 'contain'}} />                                
                        </Box>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                {lead.lead_name}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {lead.city + ', ' + lead.state + ', '+ lead.country}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {lead.last_visit}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {lead.time_spent}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {lead.source}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleViewBtnClick(lead)} size="large">
                                <ViewIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </PerfectScrollbar>
        <TablePagination
            component="div"
            count={leads.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
        />
    </Card>
    <ContactModal open={modalOpen} handleClose={() => setModalOpen(false)} lead={selectedLead} contacts={contacts} />
    {loading && <Loader />}
    </>;   
}

export default Leads;