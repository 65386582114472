import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SummarizeOUtlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ProspectList = ({ prospects, editLead, deleteLead, createProposal, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                    Prospect Name
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Prospect Email
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prospects.slice(page * limit, page * limit + limit).map((prospect) => (
                  <TableRow
                    hover
                    key={prospect.id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {prospect.client_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                          >
                          {prospect.client_email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Edit prospect'>
                        <IconButton sx={{mx: 1}} onClick={() => editLead(prospect)} size="large">
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete prospect'>
                        <IconButton sx={{mx: 1}} onClick={() => deleteLead(prospect)} size="large">
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Create proposal'>
                        <IconButton sx={{mx: 1}} onClick={() => createProposal(prospect)} size="large">
                          <SummarizeOUtlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={prospects.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ProspectList.propTypes = {
    prospects: PropTypes.array.isRequired
};

export default ProspectList;
