import { SRLWrapper } from "simple-react-lightbox";

const lightboxOptions = {
    settings:{
        boxShadow: '0px 10px 40px 10px #aaa',
        overlayColor: 'rgba(250,250,250,0.9)',
        slideAnimationType:'both'
    },
    buttons:{
        backgroundColor: '#082032',
        showDownloadButton: false,
        showThumbnailsButton: false
    },
    caption: {
        captionColor: "#082032",
        captionFontSize:'1rem',
        captionFontWeight: "bold",
        captionContainerPadding:'0px 15px 20px'
    }
}

const LightBox = (props) => (
    <SRLWrapper options={lightboxOptions}>
        {props.children}
    </SRLWrapper>
)

export default LightBox;