const initialState={
    profileId: localStorage.profileId || 0,
    profileName: localStorage.profileName || ''
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case 'SET': {
            localStorage.profileId = action.data.profileId;
            localStorage.profileName = action.data.profileName;
            return ({
                profileId: action.data.profileId,
                profileName: action.data.profileName
            })
        }

        default: return state;
    }
}

export default reducer;