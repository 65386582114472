import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  Container,
  Button,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';
import { ArrowLeft } from 'react-feather'

const FileInput = styled('input')({
    display: 'none',
});

const MAX_FILE_UPLOAD_LIMIT = 3;

const AttachmentBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: '5px',
    margin: '4px 0px'
})

const SupportTicket = () => {

    const navigate = useNavigate()

    const [loading, loader] = useState(false);
    const { authToken } = useSelector(state => state.auth);
    const [attachments, setAttachments] = useState([]);

    const fileInputRef = useRef(null);

    const clearFileInput = () => {
        if(fileInputRef.current){
            fileInputRef.current.value = null;
        }
    }

    const fileUploadHandler = (ev) => {
        const files = Array.from(ev.target.files);
        let verifiedfiles = [...attachments];
        for(let file of files){
            if( verifiedfiles.length < MAX_FILE_UPLOAD_LIMIT ){
                if(file.size < (5*1024*1000)){
                    verifiedfiles.push(file);
                }else{
                    toaster(false,`${file.name} exceeds the maximum upload size of 5MB`)
                }
            }else{
                toaster(false,`You can attach a maximum of only ${MAX_FILE_UPLOAD_LIMIT} files`);
                break;
            }
        }
        setAttachments(verifiedfiles);
        clearFileInput();
    }

    const removeAttachmentHandler = (index) => {
        const files = [...attachments];
        const newFiles = files.filter((file,i) => i !== index);
        setAttachments(newFiles);
    }

    const handleSubmit = async(values, {resetForm}) => {
        loader(true);

        let postData = new FormData();
        postData.append('profile_id', localStorage.profileId);
        postData.append('support_category', values.support_category);
        postData.append('issue', values.issue)
        if(attachments.length){
            attachments.forEach(file => postData.append('files[]', file))
        }

        try{
            const response = await axios.post('support_ticket',postData,{
                headers: {
                    'Authorization': 'Bearer '+ authToken,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response?.data){
              if(response.data.success){
                toaster(true,'The support ticket has been created!')
                resetForm();
                setAttachments([]);
              }else{
                toaster(false, response.data.message)
              }
            }else{
              toaster(false, "An error occurred while creating the ticket!")
            }
        }catch(err){
            toaster(false, "An error occurred while creating the ticket!")
        }finally{
            loader(false);
        }
    }

    return <>
    <Helmet>
        <title>Support Ticket | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Box sx={{display: 'flex', alignItems:'center',p:1}}>
                <IconButton
                    sx={{color:'primary.main'}}
                    onClick={() => navigate('/hub/reports', { replace: true })}
                    size="large">
                    <ArrowLeft />
                </IconButton>
                <Typography variant='h2' sx={{p:1, my: 1}}>{localStorage.profileName}</Typography>
            </Box>
            <Formik
                initialValues={{
                    support_category: '',
                    issue: ''
                }}
                validationSchema={Yup.object().shape({
                    support_category: Yup.string().required('Category is required'),
                    issue: Yup.string().max(1000).required('Please describe the issue'),
                })}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        onSubmit={handleSubmit}
                    >
                        <Card sx={{p: 2}}>
                            <Typography variant='h4' textAlign='center' color={'primary.main'}>Create a support ticket</Typography>
                            <Typography variant='body1' textAlign='center'>Please select the category of issue and describe the concern.</Typography>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <FormControl required fullWidth>
                                            <InputLabel id="cms">Support Category</InputLabel>
                                            <Select
                                                labelId="support_category"
                                                id="support_category"
                                                name='support_category'
                                                value={values.support_category}
                                                label="Support Category"
                                                onChange={handleChange}
                                                required
                                            >
                                                <MenuItem value='sem'>SEM</MenuItem>
                                                <MenuItem value='seo'>SEO</MenuItem>
                                                <MenuItem value='smm'>SMM</MenuItem>
                                                <MenuItem value='websites'>Websites</MenuItem>
                                                <MenuItem value='network'>Network Solutions</MenuItem>
                                                <MenuItem value='labs'>Breeze products</MenuItem>
                                                <MenuItem value='creative'>Creative</MenuItem>
                                                <MenuItem value='content'>Content</MenuItem>
                                                <MenuItem value='general'>General</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            multiline
                                            label="Comments"
                                            name="issue"
                                            InputProps={{sx: {minHeight: '80px'}}}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            type="text"
                                            value={values.issue}
                                            variant="outlined"
                                            error={Boolean(touched.issue && errors.issue)}
                                            helperText={touched.issue && errors.issue}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                    >
                                        {attachments.map((file, i) => (
                                            <AttachmentBox key={file.name+i}>
                                                <Typography variant='body2' noWrap>{file.name}</Typography>
                                                <IconButton
                                                    sx={{p:0}}
                                                    aria-label="remove"
                                                    onClick={() => removeAttachmentHandler(i)}
                                                    size="large">
                                                    <CloseOutlinedIcon />
                                                </IconButton>
                                            </AttachmentBox>
                                        ))}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <label htmlFor='attachments'>
                                            <FileInput ref={fileInputRef} onChange={fileUploadHandler} accept="image/*, application/pdf" id="attachments" multiple type="file" />
                                            <Button
                                                variant="contained"
                                                component="span"
                                                startIcon={<AttachFileOutlinedIcon />}
                                            >
                                                Attach files
                                            </Button>
                                        </label>
                                        <Typography my={1} variant='body2'>* You can attach a maximum of 3 files (image or pdf). Maximum file size is 5MB.</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Create Ticket
                                </Button>
                            </Box>
                        </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>;
}

export default SupportTicket;