import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';

const regexPhone = "^([+]?[0-9]{1,2})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$";
const regexURL = '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

const SocialMediaAudit = (props) => {

  const [loading, loader] = useState(false)

  const submitAuditRequest = async(values) => {
    try{
      loader(true)
      const response = await axios.post('social_media_audit',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Your request has been submitted!')
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while creating the request!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while creating the request!")
      loader(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Social Media Audit | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Formik
                initialValues={{
                    name: '',
                    website_url: '',
                    phone: '',
                    email: '',
                    fb_handle : '',
                    insta_handle: '',
                    linkedin_handle: '',
                    ad_account_no: '',
                    quote_required: false
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(100).required('Name is required'),
                    website_url: Yup.string().matches(regexURL,'Enter a valid website URL').required('Website URL is required'),
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    phone: Yup.string().matches(regexPhone,'Enter a valid phone number').min(8).required('Phone number is required'),
                    fb_handle: Yup.string().max(255),
                    insta_handle: Yup.string().max(255),
                    linkedin_handle: Yup.string().max(255),
                    ad_account_no: Yup.string().max(15),
                    quote_required: Yup.boolean().required()
                })}
                onSubmit={submitAuditRequest}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                    onSubmit={handleSubmit}
                    >
                    <Card sx={{p: 2}}>
                        <Typography variant='h2' textAlign='center' color={'primary.main'}>Social Media Audit</Typography>
                        <Typography variant='body1' textAlign='center'>Please fill out the client's details</Typography>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                item
                                md={6}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.name}
                                    variant="outlined"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                </Grid>
                                <Grid
                                item
                                md={6}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    label="Website URL"
                                    name="website_url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.website_url}
                                    variant="outlined"
                                    error={Boolean(touched.website_url && errors.website_url)}
                                    helperText={touched.website_url && errors.website_url}
                                />
                                </Grid>
                                <Grid
                                item
                                md={6}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    value={values.email}
                                    variant="outlined"
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                                </Grid>
                                <Grid
                                item
                                md={6}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    type="text"
                                    value={values.phone}
                                    variant="outlined"
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Facebook handle"
                                        name="fb_handle"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        value={values.fb_handle}
                                        variant="outlined"
                                        error={Boolean(touched.fb_handle && errors.fb_handle)}
                                        helperText={touched.fb_handle && errors.fb_handle}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Instagram handle"
                                        name="insta_handle"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        value={values.insta_handle}
                                        variant="outlined"
                                        error={Boolean(touched.insta_handle && errors.insta_handle)}
                                        helperText={touched.insta_handle && errors.insta_handle}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Linkedin handle"
                                        name="linkedin_handle"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        value={values.linkedin_handle}
                                        variant="outlined"
                                        error={Boolean(touched.linkedin_handle  && errors.linkedin_handle)}
                                        helperText={touched.linkedin_handle  && errors.linkedin_handle}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Ad account ID"
                                        name="ad_account_no"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        value={values.ad_account_no}
                                        variant="outlined"
                                        error={Boolean(touched.ad_account_no && errors.ad_account_no)}
                                        helperText={touched.ad_account_no && errors.ad_account_no }
                                    />
                                </Grid>
                                <Grid 
                                item
                                xs={12}
                                >
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name='quote_required' value={values.quote_required} onChange={handleChange} />} label="Include an ideal budget" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                        >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit Request
                        </Button>
                        </Box>
                    </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>
  );
};

export default SocialMediaAudit;
