import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios'
import { toaster } from '../../components/auxiliary/toaster';
import Loader from '../../components/auxiliary/loader/loader';

const regexPhone = "^([+]?[0-9]{1,2})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$";
const regexURL = '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

const ManualSeoAudit = (props) => {

  const [loading, loader] = useState(false)

  const submitAuditRequest = async(values) => {
    try{
      loader(true)
      const response = await axios.post('seo_audit',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true,'Your request has been submitted!')
        }else{
          toaster(false, response.data.message)
        }
        loader(false);
      }else{
        toaster(false, "An error occurred while creating the request!")
        loader(false);
      }
    }catch(err){
      toaster(false, "An error occurred while creating the request!")
      loader(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Manual SEO Audit | BreezeDash</title>
    </Helmet>
    <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
    >
        <Container maxWidth="lg">
            <Formik
                initialValues={{
                    name: '',
                    website_url: '',
                    phone: '',
                    email: '',
                    keyword_ideas: ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(100).required('Name is required'),
                    website_url: Yup.string().matches(regexURL,'Enter a valid website URL').required('Website URL is required'),
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    phone: Yup.string().matches(regexPhone,'Enter a valid phone number').min(8).required('Phone number is required'),
                    keyword_ideas: Yup.string().max(255).required('Please mention some keywords.'),
                })}
                onSubmit={submitAuditRequest}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                    onSubmit={handleSubmit}
                    >
                    <Card sx={{p: 2}}>
                        <Typography variant='h2' textAlign='center' color={'primary.main'}>Manual SEO / Website Audit</Typography>
                        <Typography variant='body1' textAlign='center'>Please fill out the client's details</Typography>
                        <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.name}
                                variant="outlined"
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            </Grid>
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Website URL"
                                name="website_url"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.website_url}
                                variant="outlined"
                                error={Boolean(touched.website_url && errors.website_url)}
                                helperText={touched.website_url && errors.website_url}
                            />
                            </Grid>
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.email}
                                variant="outlined"
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                            </Grid>
                            <Grid
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                label="Phone Number"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                type="text"
                                value={values.phone}
                                variant="outlined"
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                            />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                            <TextField
                                fullWidth
                                multiline
                                label="Keyword ideas"
                                name="keyword_ideas"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                type="text"
                                value={values.keyword_ideas}
                                variant="outlined"
                                error={Boolean(touched.keyword_ideas && errors.keyword_ideas)}
                                helperText={touched.keyword_ideas && errors.keyword_ideas}
                            />
                            </Grid>
                        </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                        >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit Request
                        </Button>
                        </Box>
                    </Card>
                    </form>
                )}
            </Formik>
        </Container>
    </Box>
    {loading && <Loader />}
    </>
  );
};

export default ManualSeoAudit;
