import { Box, Container, Typography, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Loader from '../auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../auxiliary/toaster'
import ProductsForm from './proposalForms/ProductsForm';
import OneTimeFeesForm from './proposalForms/OneTimeFeesForm';
import OneTimeAndMonthlyForm from './proposalForms/OneTimeAndMonthlyForm';
import MediaBuyForm from './proposalForms/MediaBuyForm';
import TotalSummaryForm from './proposalForms/TotalSummaryForm';

const CreateProposal = ({lead, ...props}) => {

    const [loading, loader] = useState(false);
    const { authToken } = useSelector(state => state.auth);

    const [expanded, setExpanded] = useState('0');
    const [products, setProducts] = useState([]);

    const navigate = useNavigate();

    const proposalData = useRef({leads_id: lead.id, onetimefessArray: []})

    useEffect(() => {
        if(!lead.id){
            navigate('/hub/breezepro')
        }
    }, [lead.id, navigate])

    useEffect(() => {
        const getProducts = async() => {
            try{
                loader(true);
                const response = await axios.post('package_products',{id: 1},{headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    setProducts(response.data.data)
                  }else{
                    toaster(false, response.data.message)
                  }
                }else{
                  toaster(false, "An error occurred while fetching the products!")
                }
            }catch(err){
                toaster(false, "An error occurred while fetching the products!")
            }finally{
                loader(false);
            }
        }
        getProducts();
    },[authToken])

    const handleForm1Submit = (values) => {
        proposalData.current = {
            ...proposalData.current,
            ...values
        }
        let selectedProducts = products.filter(product => values['products'].includes(product.product_name));
        proposalData.current['products'] = selectedProducts;
        console.log(proposalData.current);
        setExpanded('1');
    }

    const handleForm2Submit = (values) => {
        let feesArray = [values];
        proposalData.current = {
            ...proposalData.current,
            onetimefessArray: feesArray
        }
        console.log(proposalData.current);
        setExpanded('2');
    }

    const handleForm3Submit = (values) => {
        let feesArray = [...proposalData.current.onetimefessArray];
        feesArray[1] = values;
        proposalData.current = {
            ...proposalData.current,
            onetimefessArray: feesArray
        }
        console.log(proposalData.current);
        setExpanded('3');
    }

    const handleForm4Submit = (values) => {
        let feesArray = [...proposalData.current.onetimefessArray];
        feesArray[2] = values;
        proposalData.current = {
            ...proposalData.current,
            onetimefessArray: feesArray
        }
        console.log(proposalData.current);
        setExpanded('4');
    }

    const handleForm5Submit = async(values) => {
        let feesArray = [...proposalData.current.onetimefessArray];
        feesArray[3] = values;
        proposalData.current = {
            ...proposalData.current,
            onetimefessArray: feesArray
        }

        try{
            loader(true);
            const response = await axios.post('proposal', proposalData.current, {headers: {'Authorization': 'Bearer '+ authToken}});
            if(response?.data){
              if(response.data.success){
                toaster(true, 'Business proposal created successfully!')
              }else{
                toaster(false, response.data.message)
              }
            }else{
              toaster(false, "An error occurred while creating the proposal!")
            }
        }catch(err){
            toaster(false, "An error occurred while creating the proposal!")
        }finally{
            loader(false);
        }
    }

    return (
        <>
        <Container maxWidth={false}>
            <Typography variant='h2' textAlign={'center'}>{'Create Proposal'}</Typography>
            <Typography variant='h5'><b>Prospect: </b>{lead.client_name}</Typography>
            <Typography variant='h5'><b>Email: </b>{lead.client_email}</Typography>
            <Box sx={{p:2, my:2}}>
                <Accordion expanded={expanded === '0'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' sx={{color: expanded === '0' ? 'primary.main' : 'text.secondary', fontSize: '18px'}}>1. Select package and products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductsForm products={products} handleSubmit={handleForm1Submit} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === '1'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' sx={{color: expanded === '1' ? 'primary.main' : 'text.secondary', fontSize: '18px'}}>2. One Time (Agency Fees)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OneTimeFeesForm handleSubmit={handleForm2Submit} handleBack={() => setExpanded('0')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === '2'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' sx={{color: expanded === '2' ? 'primary.main' : 'text.secondary', fontSize: '18px'}}>3. One Time and Monthly (Agency Fees)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OneTimeAndMonthlyForm handleSubmit={handleForm3Submit} handleBack={() => setExpanded('1')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === '3'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' sx={{color: expanded === '3' ? 'primary.main' : 'text.secondary', fontSize: '18px'}}>4. One Time and Monthly (Media Buy + Agency Fees)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MediaBuyForm handleSubmit={handleForm4Submit} handleBack={() => setExpanded('2')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === '4'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' sx={{color: expanded === '4' ? 'primary.main' : 'text.secondary', fontSize: '18px'}}>5. Total Summary and Agency Fees</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TotalSummaryForm handleSubmit={handleForm5Submit} handleBack={() => setExpanded('3')} />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button                            
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => navigate('/hub/breezepro')}
                >
                    Home/Create new Proposal
                </Button>
            </Box>
        </Container>
        {loading && <Loader />}
        </>
    );
}

export default CreateProposal;