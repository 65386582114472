import { useFormikContext, Field } from 'formik';
import {
    Box,
    Card,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const BreezeTrackProducts = [
    {
        name: 'BreezeTag',
        description: 'Google Analytics and Search console',
        payment_type: 'One-time Payment',
        cost: '$499'
    },
    {
        name: 'BreezeTrail',
        description: 'Recorded call tracking',
        payment_type: 'Monthly Payment',
        cost: '$75/m'
    },
    {
        name: 'BreezeHeat',
        description: 'Heat Map Tracking',
        payment_type: 'Monthly Payment',
        cost: '$99/m'
    },
    {
        name: 'BreezeWho',
        description: 'B2B Website Tracking',
        payment_type: 'Monthly Payment',
        cost: '$199/m'
    }
]

const BoxLabel = ({name, description, payment_type, cost}) => (
    <Box py={1}>
        <Typography variant='body1'>{name+'® '+cost}</Typography>
        <Typography variant='subtitle2'>{description}</Typography>
        <Typography variant='caption'>{'('+payment_type+')'}</Typography>
    </Box>
)

const BreezeSearch = ({noteMaxLimit}) => {
    const { errors, touched, values, handleBlur, handleChange } = useFormikContext();
    const { breezesearch: breezesearchValues } = values;
    const breezesearchErrors = errors.breezesearch || {};
    const breezesearchTouched = touched.breezesearch || {};
    
    return (
        <Card sx={{my: 2, p:2, boxShadow: 2}} variant='outlined'>
            <Typography my={1} variant='h3' fontWeight={700}>BreezeSearch</Typography>
            <Typography my={1} variant='h5'>Keyword Phrases must be city/area specific, e.g. Toronto Roofers</Typography>
            <Grid container spacing={3}>
                {
                    Array.from({length: 10}, (el, i) => i+1).map(elem => (
                        <Grid key={elem} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={"Keyword Phrase "+ elem}
                                name={"breezesearch.keywordPhrase"+elem}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={breezesearchValues['keywordPhrase'+elem]}
                                variant="outlined"
                                error={Boolean(breezesearchTouched['keywordPhrase'+elem] && breezesearchErrors['keywordPhrase'+elem])}
                                helperText={breezesearchTouched['keywordPhrase'+elem] && breezesearchErrors['keywordPhrase'+elem]}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Additional Notes"
                        InputProps={{sx: {minHeight: '80px'}}}
                        inputProps={{maxLength: noteMaxLimit}}
                        name="breezesearch.search_additional_notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesearchValues.search_additional_notes}
                        variant="outlined"
                        error={Boolean(breezesearchTouched.search_additional_notes && breezesearchErrors.search_additional_notes)}
                        helperText={breezesearchTouched.search_additional_notes && breezesearchErrors.search_additional_notes}
                    />
                    <Typography variant='caption'>{`${breezesearchValues.search_additional_notes.length}/${noteMaxLimit}`}</Typography>
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>BreezeTrack</Typography>
            <FormControl fullWidth required variant="outlined">
                <Grid container>
                {
                    BreezeTrackProducts.map(product => (
                        <Grid key={product.name} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                sx={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox name={'breezesearch.search_breezetrack'} onChange={handleChange} value={product.name} />
                                }
                                label={<BoxLabel {...product} />}
                            />
                        </Grid>
                    ))
                }
                </Grid>
            </FormControl>
            <Typography my={1} variant='h5'>Creative and Research Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Setup Fee"
                        name="breezesearch.search_setup_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesearchValues.search_setup_fee}
                        variant="outlined"
                        error={Boolean(breezesearchTouched.search_setup_fee && breezesearchErrors.search_setup_fee)}
                        helperText={breezesearchTouched.search_setup_fee && breezesearchErrors.search_setup_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        label="Monthly Fee"
                        name="breezesearch.search_monthly_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesearchValues.search_monthly_fee}
                        variant="outlined"
                        error={Boolean(breezesearchTouched.search_monthly_fee && breezesearchErrors.search_monthly_fee)}
                        helperText={breezesearchTouched.search_monthly_fee && breezesearchErrors.search_monthly_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        label="Term (minimum 1 year)"
                        name="breezesearch.search_term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesearchValues.search_term}
                        variant="outlined"
                        error={Boolean(breezesearchTouched.search_term && breezesearchErrors.search_term)}
                        helperText={breezesearchTouched.search_term && breezesearchErrors.search_term}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Payment Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Payment Type"
                        >
                            <FormControlLabel name="breezesearch.search_payment_type" value="Credit Card" control={<Field required type='radio' component={MyRadioInput} />} label="Credit Card" />
                            <FormControlLabel name="breezesearch.search_payment_type" value="Void Cheque" control={<Field required type='radio' component={MyRadioInput} />} label="Void Cheque" />
                            <FormControlLabel name="breezesearch.search_payment_type" value="e-Transfer" control={<Field required type='radio' component={MyRadioInput} />} label="e-Transfer" />
                            <FormControlLabel name="breezesearch.search_payment_type" value="Term Paid in Full" control={<Field required type='radio' component={MyRadioInput} />} label="Term Paid in Full" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BreezeSearch;