import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Box, Container, Typography, Card, CardContent } from '@mui/material';

const BreezeGuest = () => {
    const [loading, loader] = useState(false)
    const [blogs, setBlogs] = useState([])

    const fetchBlogs = async() => {
        loader(true);
        try{
            const response = await axios.post('gblog',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setBlogs(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    useEffect(() => {
        fetchBlogs();
    }, [])

    return(
        <>
            <Helmet>
                <title>GuestBlogs | BreezeDash</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'GuestBlogs (Blog)'}</Typography>
                <Box sx={{p: 1, width:1}}>
                    {blogs.length ? blogs.map((blog, i) => (
                        <Card key={i}>
                            <CardContent>
                                <Typography variant='h4' sx={{textAlign: 'center', my:1}}>{blog.title}</Typography>
                                <Typography variant='body1' sx={{textAlign: 'left', my:1}}>{blog.btext}</Typography>
                            </CardContent>
                        </Card>
                    )) : <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>}
                </Box>
            </Container>
            </Box>
            {loading && <Loader />}
        </>
        )

}

export default BreezeGuest;