const initialState={
    isAuthenticated: !!localStorage.authToken,
    authToken: localStorage.authToken || '',
    name: localStorage.name || '',
    role: localStorage.role || '',
}

const reducer = (state=initialState,action) => {
    switch(action.type){
        case 'authenticate': {
            localStorage.authToken = action.data.authToken;
            localStorage.name = action.data.name;
            localStorage.role = action.data.role;

            return ({
                isAuthenticated : action.data.isAuthenticated,
                authToken: action.data.authToken,
                name: action.data.name,
                role: action.data.role,
            })
        }  
        case 'updateProfile': {
            localStorage.userName = action.data.userName;
            localStorage.image = action.data.image;
            localStorage.status = action.data.status;
            return({
                ...state,
                name: action.data.userName,
                image: action.data.image,
                status: action.data.status
            })
        }
        default:
            return state;  
    }
}

export default reducer;