import { forwardRef, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import {Card, CardContent } from '@mui/material'
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@mui/styles';
import ContactsList from './contactsList';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledCard = withStyles(theme => ({
    root:{
        borderLeft: '3px solid',
        borderColor: theme.palette.primary.main,
        height: '100%'
    }
}))(Card);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`breezewho-tabpanel-${index}`}
        aria-labelledby={`breezewho-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2, height: '100%' }}>
              {children}
          </Box>
        )}
      </div>
    );
}

const ContactModal = ({open, handleClose, lead, contacts}) => {

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    }

    const handleTabPanelChange = (index) => {
        setTab(index);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="Leads Contacts Modal"
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                    <Box sx={{display: 'flex'}}>
                        <Avatar alt='Company logo' src={lead.logo} sx={{width: 50, height: 50, mr: 2}} imgProps={{objectFit: 'contain'}} />
                        <Box>
                            <Typography variant='h3'>{lead.lead_name}</Typography>
                            <Typography variant='body1'><b>Visitor Location: </b>{lead.city + ', ' + lead.state + ', '+ lead.country}</Typography>
                            <Typography variant='body1'><b>Industry: </b>{lead.industry}</Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="Contact Details Tabs"
                        textColor='primary'
                        indicatorColor='primary'
                    >
                        <Tab label="Visit Details" sx={{fontWeight: 700}} />
                        <Tab label="Company Info" sx={{fontWeight: 700}} />
                        <Tab label="Contacts" sx={{fontWeight: 700}} />
                    </Tabs>
                </Box>
                <SwipeableViews
                    axis='x'
                    index={tab}
                    onChangeIndex={handleTabPanelChange}
                >
                    <TabPanel value={tab} index={0}>
                       <Grid container spacing={3}>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Date</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.last_visit}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>                           
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Source/Medium</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.source}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Pages views</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.page_views}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Time spent</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.time_spent}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Visited Pages</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.visited_pages}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                       </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                    <Grid container spacing={3}>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Company Name</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.lead_name}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>                           
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Approx. employees</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.approximate_employees}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Website</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.website}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12} md={6}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Phones</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.phones}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Headquarter Address</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.headquarter_address}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                           <Grid item xs={12}>
                                <StyledCard elevation={3}>
                                    <CardContent>
                                        <Typography variant='body1' sx={{fontWeight: 600}}>Overview</Typography>
                                        <Typography variant='h6' sx={{mt:2}}>{lead.overview}</Typography>
                                    </CardContent>
                                </StyledCard>
                           </Grid>
                       </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <ContactsList contacts={contacts} />
                    </TabPanel>
                </SwipeableViews>
            </DialogContent>
        </Dialog>
    );
}

export default ContactModal;