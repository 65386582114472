import { Card, useTheme } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

const SourceBreakdownChart = ({data}) => {

    const theme = useTheme();

    const options = {
        animation: {
            animateRotate: true
        },
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        plugins:{
            tooltip: {
                backgroundColor: theme.palette.background.paper,
                bodyColor: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                enabled: true,
                footerFontColor: theme.palette.text.secondary,
                intersect: true,
                mode: 'index',
                titleFontColor: theme.palette.text.primary,
                callbacks: {
                    label: function(context){
                        let allData = context.dataset.data;
                        let total = allData.reduce((tot, num) => (tot+num), 0);
                        let percent = (context.raw / total *100).toFixed(2)
                        return context.label+': '+context.formattedValue+' ('+ percent +'%)';
                    }
                }
            },
        }
    };

    return(
        <Card elevation={3} sx={{minHeight: 500, p:2}}>
            <Doughnut
                data={data}
                options={options}
            />
        </Card>
    )
}

export default SourceBreakdownChart;