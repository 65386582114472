import { useState, useReducer } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios';
import { toaster } from '../auxiliary/toaster';
import Loader from '../auxiliary/loader/loader';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ChangePassword = () => {

    const [loading, loader] = useState(false);
    const [showCurrPassword, setShowCurrPassword] = useReducer(state => !state, false)
    const [showNewPassword, setShowNewPassword] = useReducer(state => !state, false)
    const [showConfirmPassword, setShowConfirmPassword] = useReducer(state => !state, false)

    const updatePassword = async(values, {resetForm}) => {
        try{
            loader(true)
            const response = await axios.post('change_password',values,{headers: {'Authorization': 'Bearer '+ localStorage.authToken}});
            if(response?.data){
              if(response.data.success){
                toaster(true,'Your Password has been changed!')
                resetForm();
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while changing the password!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while changing the password!")
            loader(false);
        }
    }

    return <>
    <Formik
        initialValues={{
            current_password: '',
            new_password: '',
            confirm_pass: ''
        }}
        validationSchema={Yup.object().shape({
            current_password: Yup.string().min(8,"Password must contain minimum 8 characters").max(255).required('Enter your current password'),
            new_password: Yup.string().min(8,"Password must contain minimum 8 characters").max(255).required('Enter a new password'),
            confirm_pass: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match').required('Re-enter the new password')
        })}
        onSubmit={updatePassword}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
        }) => (
            <form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader
                        title="Change Password"
                    />
                    <Divider />
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            my={1}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel htmlFor="current_password">Current password</InputLabel>
                                    <OutlinedInput
                                        id='current_password'
                                        name="current_password"
                                        label="Current password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        type={showCurrPassword ? 'text' : 'password'}
                                        value={values.current_password}
                                        error={Boolean(touched.current_password && errors.current_password)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={setShowCurrPassword}
                                                  edge="end"
                                                  size="large">
                                                {showCurrPassword ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error={Boolean(touched.current_password && errors.current_password)} id="current_password">{touched.current_password && errors.current_password}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            my={1}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel htmlFor="new_password">New password</InputLabel>
                                    <OutlinedInput
                                        id='new_password'
                                        name="new_password"
                                        label="New password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        type={showNewPassword ? 'text' : 'password'}
                                        value={values.new_password}
                                        error={Boolean(touched.new_password && errors.new_password)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={setShowNewPassword}
                                                  edge="end"
                                                  size="large">
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error={Boolean(touched.new_password && errors.new_password)} id="new_password">{touched.new_password && errors.new_password}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel htmlFor="confirm_pass">Re-enter password</InputLabel>
                                    <OutlinedInput
                                        id='confirm_pass'
                                        name="confirm_pass"
                                        label="Re-enter password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirm_pass}
                                        error={Boolean(touched.confirm_pass && errors.confirm_pass)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={setShowConfirmPassword}
                                                  edge="end"
                                                  size="large">
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error={Boolean(touched.confirm_pass && errors.confirm_pass)} id="confirm_pass">{touched.confirm_pass && errors.confirm_pass}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Change password
                        </Button>
                    </Box>
                </Card>
            </form>
        )}
    </Formik>
    {loading && <Loader />}
    </>;
}

export default ChangePassword;