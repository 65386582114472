import { useState, useEffect } from 'react'
import Loader from '../auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../auxiliary/toaster'
import { useSelector } from 'react-redux';
import { Typography, Grid, Card, CardHeader, CardContent, colors } from '@mui/material';
import SourceBreakdownChart from './sourceBreakdownChart';
import VisitorsBarChart from './visitorsBarChart';

const data = {
    datasets: [
      {
        data: [25, 25, 25, 25],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600],
          colors.green[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Direct', 'Google CPC', 'Google Organic', 'Referral']
};

const barData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
        {
            label: 'No. of visitors',
            data: [300,250,180,600,240,550],
            backgroundColor: colors.indigo[500],
            borderWidth: 2,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
        }
    ]
}

const Dashboard = () => {

    const [loading, loader] = useState(false)
    const [stats, setStats] = useState({})
    const {authToken} = useSelector(state => state.auth);
    const [chartData, setChartData] = useState('');
    const [barChartData, setBarChartData] = useState('');

    const constructBarChartData = (stats) => {
        let newData = {...barData};
        let labels = [stats.five_month_ago, stats.four_month_ago, stats.three_month_ago, stats.two_month_ago, stats.one_month_ago, stats.current_month];
        let data = [stats.five_month_ago_leads, stats.four_month_ago_leads, stats.three_month_ago_leads, stats.two_month_ago_leads, stats.one_month_ago_leads, stats.month_leads];
        newData.labels = labels;
        let newDataSet = {...newData.datasets[0]}
        newDataSet.data = data;
        newData.datasets=[newDataSet];
        setBarChartData(newData);
    }

    useEffect(() => {
        const fetchData = async() => {
            try{
                loader(true);
                const response = await axios.post('breezewho_dashboard',{profile_id: localStorage.profileId},{headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    let result = response.data.data;
                    setStats(result);
                    let newData = {...data};
                    newData.datasets[0].data = [result.direct, result.google_cpc, result.google_organic, result.referral];
                    setChartData(newData);
                    constructBarChartData(result);
                  }else{
                    toaster(false, response.data.message)
                  }
                  loader(false);
                }else{
                  toaster(false, "An error occurred while fetching the statistics!")
                  loader(false);
                }
              }catch(err){
                toaster(false, "An error occurred while fetching the statistics!")
                loader(false);
              }
        }
        fetchData();
    }, [authToken])

    return(
        <>
        <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={3}>
                <Card elevation={3}>
                    <CardHeader
                        title="Visitors (All-time)"
                    />
                    <CardContent>
                        <Typography variant='h1'>{stats.total_leads}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Card elevation={3}>
                    <CardHeader
                        title="Visitors (Last 30 days)"
                    />
                    <CardContent>
                        <Typography variant='h1'>{stats.last_30_days_leads}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Card elevation={3}>
                    <CardHeader
                        title="Visitors (Last 7 days)"
                    />
                    <CardContent>
                        <Typography variant='h1'>{stats.week_leads}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Card elevation={3}>
                    <CardHeader
                        title="Visitors (Yesterday)"
                    />
                    <CardContent>
                        <Typography variant='h1'>{stats.yesterday_leads}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{my:2}}>
            <Grid item xs={12} md={6}>
                {barChartData && <VisitorsBarChart data={barChartData} />}
            </Grid>
            <Grid item xs={12} md={6}>
                {chartData && <SourceBreakdownChart data={chartData} />}
            </Grid>
        </Grid>
        {loading && <Loader />}
        </>
    )   
}

export default Dashboard;