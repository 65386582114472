import { useEffect, useState, useCallback } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Card, CardContent, TextField, InputAdornment, SvgIcon, FormControl, RadioGroup, Radio } from '@mui/material';
import ProfileList from '../../components/reports/ProfileList';
import Loader from '../../components/auxiliary/loader/loader'
import axios from '../../axios'
import {toaster} from '../../components/auxiliary/toaster'
import { Search as SearchIcon } from 'react-feather';
import { setProfile } from '../../redux/actionCreator'
import { FormControlLabel } from '@mui/material';


const Reports = () => {
    const [loading, loader] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const {authToken, role} = useSelector(state => state.auth);
    const [accountsFilter, setAccountsFilter] = useState('self');
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [params] = useSearchParams();

    const filterProfiles = (profile) => {
        return profile.business_name.toLowerCase().includes(searchKey.toLowerCase());
    }

    const viewReportsHandler = useCallback((id, name) => {
        dispatch(setProfile(id, name));
        navigate('/reports/profile', { replace: true })
    }, [dispatch, navigate])

    const viewNotesHandler = (id, name) => {
        dispatch(setProfile(id, name));
        navigate('/hub/notes', { replace: true })
    }

    const supportTicketHandler = (id, name) => {
        dispatch(setProfile(id, name));
        navigate('/hub/support', { replace: true })
    }

    useEffect(() => {
        const fetchProfiles = async() => {
            let apiKey = 'user_profile';
            if(role === 'manager'){
                apiKey = apiKey + '?option=' + accountsFilter;
            }
            try{
                loader(true);
                const response = await axios.get(apiKey,{headers: {'Authorization': 'Bearer '+ authToken}});
                if(response?.data){
                  if(response.data.success){
                    setProfiles(response.data.data)
                  }else{
                    toaster(false, response.data.message)
                  }
                  loader(false);
                }else{
                  toaster(false, "An error occurred while fetching the linked profiles!")
                  loader(false);
                }
              }catch(err){
                toaster(false, "An error occurred while fetching the linked profiles!")
                loader(false);
              }
        }
        fetchProfiles()
    }, [authToken, role, accountsFilter])

    useEffect(() => {
        if(role === 'client' && profiles.length === 1 && params.get('first')){
            viewReportsHandler(profiles[0].profile_id, profiles[0].business_name)
        }
    },[role, profiles, params, viewReportsHandler])

    return (
    <>
        <Helmet>
        <title>Reports | BreezeDash</title>
        </Helmet>
        <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
        >
        <Container maxWidth={false}>
            <Box sx={{ mt: 3 }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6} width={1}>
                                <Box>
                                    <TextField
                                    value = {searchKey}
                                    onChange = {ev => setSearchKey(ev.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon
                                            fontSize="small"
                                            color="action"
                                            >
                                            <SearchIcon />
                                            </SvgIcon>
                                        </InputAdornment>
                                        )
                                    }}
                                    placeholder="Search profiles"
                                    variant="outlined"
                                    />
                                </Box>
                            </Grid>
                            {role === 'manager' && 
                            <Grid item sm={12} md={6} width={1}>
                                <Box>
                                    <FormControl>
                                        <RadioGroup row aria-label='accounts' name="accounts-filter" value={accountsFilter} onChange={ev => setAccountsFilter(ev.target.value)}>
                                            <FormControlLabel value='self' control={<Radio />} label='My accounts' />
                                            <FormControlLabel value='all' control={<Radio />} label='All accounts' />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ pt: 3 }}>
                <ProfileList 
                    profiles={profiles.filter(filterProfiles)} 
                    viewReports={viewReportsHandler} 
                    viewNotes={viewNotesHandler}
                    createTicket={supportTicketHandler} 
                    showAccManager={accountsFilter === 'all' || role === 'admin'} 
                    showNotes = {role !== 'client'}
                    showTicket = {role !== 'client'}
                />
            </Box>
        </Container>
        <Outlet />
        </Box>
        {loading && <Loader />}
    </>
)};

export default Reports;
