import { useFormikContext, Field } from 'formik';
import {
    Card,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

const MyRadioInput = ({ field, form, ...props }) => {
    return <Radio {...field} {...props} />
}

const RequiredServices = ['BreezeSite', 'BreezeSite Rental', 'BreezeSite Finance', 'BreezeApp', 'BreezeApp Rental', 'Creative/Pictures'];

const AddOns = ['Forms', 'Creative/Pictures', 'Content Writing', 'Plugins', 'SSL Certificate', 'Domain Name', 'Network Solutions', 'Feasibility Study', 'Diagnostics Fee'];

const MonthlyAddOns = ['BreezeChat', 'BreezeApp Rental', 'BreezeCloud', 'BreezeMail Starter $15/m', 'BreezeMail Standard $25/m', 'BreezeMail Plus $40/m']

const BreezeSiteRental = ['4-Pages = $99/m', '6-Pages = $149/m', '10-Pages = $199/m', 'E-comm = $349/m']

const BreezeSiteFinance = ['4-Pages = $149/m', '6-Pages = $199/m', '10-Pages = $249/m', 'E-comm = $499/m']

const BreezeSites = ({noteMaxLimit}) => {
    const { errors, touched, values, handleBlur, handleChange } = useFormikContext();
    const { breezesites: breezesitesValues } = values;
    const breezesitesErrors = errors.breezesites || {}
    const breezesitesTouched = touched.breezesites || {};
    
    return (
        <Card sx={{my: 2, p:2, boxShadow: 2}} variant='outlined'>
            <Typography my={1} variant='h3' fontWeight={700}>BreezeSites</Typography>
            <Typography my={1} variant='h5'>Required Services</Typography>
            <Grid container spacing={3} sx={{mb:2}}>
                {
                    RequiredServices.map(service => (
                        <Grid key={service} item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesites.required_services'} onChange={handleChange} value={service} />
                                }
                                label={service}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={6} lg={2}>
                    <TextField
                        fullWidth
                        label="Other"
                        name="breezesites.other_required_service"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.other_required_service}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.other_required_service && breezesitesErrors.other_required_service)}
                        helperText={breezesitesTouched.other_required_service && breezesitesErrors.other_required_service}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Current Domain"
                        name="breezesites.current_domain"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.current_domain}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.current_domain && breezesitesErrors.current_domain)}
                        helperText={breezesitesTouched.current_domain && breezesitesErrors.current_domain}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Domain to purchase"
                        name="breezesites.domain_to_purchase"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.domain_to_purchase}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.domain_to_purchase && breezesitesErrors.domain_to_purchase)}
                        helperText={breezesitesTouched.domain_to_purchase && breezesitesErrors.domain_to_purchase}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Number of Pages"
                        name="breezesites.no_of_pages"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.no_of_pages}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.no_of_pages && breezesitesErrors.no_of_pages)}
                        helperText={breezesitesTouched.no_of_pages && breezesitesErrors.no_of_pages}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Logo Design ($599)"
                        name="breezesites.logo_design"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.logo_design}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.logo_design && breezesitesErrors.logo_design)}
                        helperText={breezesitesTouched.logo_design && breezesitesErrors.logo_design}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Describe the business"
                        InputProps={{sx: {minHeight: '80px'}}}
                        name="breezesites.business_description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.business_description}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.business_description && breezesitesErrors.business_description)}
                        helperText={breezesitesTouched.business_description && breezesitesErrors.business_description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Admired websites in the industry"
                        InputProps={{sx: {minHeight: '80px'}}}
                        name="breezesites.admired_websites"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.admired_websites}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.admired_websites && breezesitesErrors.admired_websites)}
                        helperText={breezesitesTouched.admired_websites && breezesitesErrors.admired_websites}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        label="Additional project info"
                        InputProps={{sx: {minHeight: '80px'}}}
                        inputProps={{maxLength: noteMaxLimit}}
                        name="breezesites.additional_info"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.additional_info}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.additional_info && breezesitesErrors.additional_info)}
                        helperText={breezesitesTouched.additional_info && breezesitesErrors.additional_info}
                    />
                    <Typography variant='caption'>{`${breezesitesValues.additional_info.length}/${noteMaxLimit}`}</Typography>
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Platform</Typography>
            <Grid container spacing={3} sx={{mb: 2}}>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControlLabel
                        control={
                            <Checkbox name={'breezesites.platform'} onChange={handleChange} value={'WordPress'} />
                        }
                        label={'WordPress'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControlLabel
                        control={
                            <Checkbox name={'breezesites.platform'} onChange={handleChange} value={'Shopify'} />
                        }
                        label={'Shopify'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        fullWidth
                        label="Other"
                        name="breezesites.other_platform"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.other_platform}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.other_platform && breezesitesErrors.other_platform)}
                        helperText={breezesitesTouched.other_platform && breezesitesErrors.other_platform}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Paid BreezeSite Development or Software/ BreezeApp Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Development or Software/App Fee"
                        name="breezesites.development_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.development_fee}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.development_fee && breezesitesErrors.development_fee)}
                        helperText={breezesitesTouched.development_fee && breezesitesErrors.development_fee}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Add-Ons (One-Time Payment Only)</Typography>
            <Grid container spacing={3} sx={{mb: 2}}>
                {
                    AddOns.map(service => (
                        <Grid key={service} item xs={6} md={4} lg={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesites.add_ons'} onChange={handleChange} value={service} />
                                }
                                label={service}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Total Fee"
                        name="breezesites.add_on_total_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.add_on_total_fee}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.add_on_total_fee && breezesitesErrors.add_on_total_fee)}
                        helperText={breezesitesTouched.add_on_total_fee && breezesitesErrors.add_on_total_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Term (minimum 1 year)"
                        name="breezesites.add_on_term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.add_on_term}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.add_on_term && breezesitesErrors.add_on_term)}
                        helperText={breezesitesTouched.add_on_term && breezesitesErrors.add_on_term}
                    />
                </Grid>
            </Grid>
            <Typography my={1} variant='h5'>Monthly Payment & Add-Ons</Typography>
            <Grid container spacing={3}>
                {
                    MonthlyAddOns.map(service => (
                        <Grid key={service} item xs={12} md={6} lg={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesites.monthly_payment_addons'} onChange={handleChange} value={service} />
                                }
                                label={service}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="BreezeMail Quantity"
                        name="breezesites.breezemail_quantity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezemail_quantity}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezemail_quantity && breezesitesErrors.breezemail_quantity)}
                        helperText={breezesitesTouched.breezemail_quantity && breezesitesErrors.breezemail_quantity}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel id="BreezeAccess" component="legend">BreezeAccess</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="BreezeAccess"
                        >
                            <FormControlLabel name='breezesites.breezeaccess' value="$150/m" control={<Field type='radio' component={MyRadioInput} />} label="$150/m" />
                            <FormControlLabel name='breezesites.breezeaccess' value="$1500/year" control={<Field type='radio' component={MyRadioInput} />} label="$1500/year" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="start">/m</InputAdornment>
                        }}
                        label="BreezeBlast Fee (Email Marketing)"
                        name="breezesites.breezeblast_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezeblast_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezeblast_cost && breezesitesErrors.breezeblast_cost)}
                        helperText={breezesitesTouched.breezeblast_cost && breezesitesErrors.breezeblast_cost}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="E-blasts per month"
                        name="breezesites.breezeblast_quantity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezeblast_quantity}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezeblast_quantity && breezesitesErrors.breezeblast_quantity)}
                        helperText={breezesitesTouched.breezeblast_quantity && breezesitesErrors.breezeblast_quantity}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="start">/m</InputAdornment>
                        }}
                        label="BreezeIT Fee (Tech support)"
                        name="breezesites.breezeIT_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezeIT_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezeIT_cost && breezesitesErrors.breezeIT_cost)}
                        helperText={breezesitesTouched.breezeIT_cost && breezesitesErrors.breezeIT_cost}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">hours</InputAdornment>
                        }}
                        label="BreezeIT Hours (Tech support)"
                        name="breezesites.breezeIT_hours"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezeIT_hours}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezeIT_hours && breezesitesErrors.breezeIT_hours)}
                        helperText={breezesitesTouched.breezeIT_hours && breezesitesErrors.breezeIT_hours}
                    />
                </Grid>
            </Grid>
            {/* <Typography my={1} variant='h5'>Website Maintenance</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="start">/m</InputAdornment>
                        }}
                        label="Maintenace Cost"
                        name="breezesites.web_maintenance_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.web_maintenance_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.web_maintenance_cost && breezesitesErrors.web_maintenance_cost)}
                        helperText={breezesitesTouched.web_maintenance_cost && breezesitesErrors.web_maintenance_cost}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">hours</InputAdornment>
                        }}
                        label="Maintenace Hours"
                        name="breezesites.web_maintenance_hours"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.web_maintenance_hours}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.web_maintenance_hours && breezesitesErrors.web_maintenance_hours)}
                        helperText={breezesitesTouched.web_maintenance_hours && breezesitesErrors.web_maintenance_hours}
                    />
                </Grid>
            </Grid> */}
            <Typography my={1} variant='h5'>BreezeSite Rental (Options)</Typography>
            <Grid container spacing={3}>
                {
                    BreezeSiteRental.map(service => (
                        <Grid key={service} item xs={12} md={6} lg={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesites.breezesite_rental_options'} onChange={handleChange} value={service} />
                                }
                                label={service}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Custom Fee"
                        name="breezesites.breezesite_rental_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezesite_rental_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezesite_rental_cost && breezesitesErrors.breezesite_rental_cost)}
                        helperText={breezesitesTouched.breezesite_rental_cost && breezesitesErrors.breezesite_rental_cost}
                    />
                </Grid>
            </Grid> 
            <Typography my={1} variant='h5'>BreezeSite Finance (Options)</Typography>
            <Grid container spacing={3}>
                {
                    BreezeSiteFinance.map(service => (
                        <Grid key={service} item xs={12} md={6} lg={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox name={'breezesites.breezesite_finance_options'} onChange={handleChange} value={service} />
                                }
                                label={service}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Custom Fee"
                        name="breezesites.breezesite_finance_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.breezesite_finance_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.breezesite_finance_cost && breezesitesErrors.breezesite_finance_cost)}
                        helperText={breezesitesTouched.breezesite_finance_cost && breezesitesErrors.breezesite_finance_cost}
                    />
                </Grid>
            </Grid>           
            <Typography my={1} variant='h5'>Creative and Research Fee</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Admin Fee"
                        name="breezesites.setup_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.setup_fee}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.setup_fee && breezesitesErrors.setup_fee)}
                        helperText={breezesitesTouched.setup_fee && breezesitesErrors.setup_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Monthly Fee"
                        name="breezesites.monthly_fee"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.monthly_fee}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.monthly_fee && breezesitesErrors.monthly_fee)}
                        helperText={breezesitesTouched.monthly_fee && breezesitesErrors.monthly_fee}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Term (minimum 1 year)"
                        name="breezesites.term"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.term}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.term && breezesitesErrors.term)}
                        helperText={breezesitesTouched.term && breezesitesErrors.term}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Buyout Amount"
                        name="breezesites.buyout_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={breezesitesValues.buyout_cost}
                        variant="outlined"
                        error={Boolean(breezesitesTouched.buyout_cost && breezesitesErrors.buyout_cost)}
                        helperText={breezesitesTouched.buyout_cost && breezesitesErrors.buyout_cost}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Payment Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="Payment Type"
                        >
                            <FormControlLabel name="breezesites.payment_type" value="Credit Card" control={<Field required type='radio' component={MyRadioInput} />} label="Credit Card" />
                            <FormControlLabel name="breezesites.payment_type" value="Void Cheque" control={<Field required type='radio' component={MyRadioInput} />} label="Void Cheque" />
                            <FormControlLabel name="breezesites.payment_type" value="e-Transfer" control={<Field required type='radio' component={MyRadioInput} />} label="e-Transfer" />
                            <FormControlLabel name="breezesites.payment_type" value="Term Paid in Full" control={<Field required type='radio' component={MyRadioInput} />} label="Term Paid in Full" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BreezeSites;