import { Box, Container, Typography, Link } from '@mui/material';
import { ExternalLink } from 'react-feather';

const ReportComponent = ({product, url, external}) => (
  <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', p:1, width:1}}>
      <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{product}</Typography>
      {external && <Link href={url} target='_blank' rel='noopener noreferer' sx={{ml:1}}><ExternalLink /></Link>}
    </Box>
    {url && <iframe title={product} src={url} width="100%" height='940' frameBorder="0" style={{border: 0, overflow: 'hidden'}} allowFullScreen />}
  </Container>
);

export default ReportComponent;