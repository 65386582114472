import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper
} from '@mui/material';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

const ProfileList = ({ profiles, viewReports, viewNotes, createTicket, showAccManager, showNotes, showTicket, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                    Profile Id
                  </TableCell>
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Profile Name
                  </TableCell>
                  {showAccManager && <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Account Manager
                  </TableCell>}
                  <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles.slice(page * limit, page * limit + limit).map((profile) => (
                  <TableRow
                    hover
                    key={profile.profile_id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {profile.profile_id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                          >
                          {profile.business_name}
                      </Typography>
                    </TableCell>
                    {showAccManager && 
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="body1"
                      >
                        {profile.account_manager}
                      </Typography>
                    </TableCell>}
                    <TableCell>
                      <Tooltip title='View report'>
                        <IconButton
                          sx={{mx: 1}}
                          onClick={() => viewReports(profile.profile_id, profile.business_name)}
                          size="large">
                          <InsertChartOutlinedRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      {showNotes && 
                      <Tooltip title='View Notes'>
                        <IconButton
                          sx={{mx: 1}}
                          onClick={() => viewNotes(profile.profile_id, profile.business_name)}
                          size="large">
                          <StickyNote2OutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      }
                      {showTicket &&
                      <Tooltip title='Support ticket'>
                        <IconButton
                          sx={{mx: 1}}
                          onClick={() => createTicket(profile.profile_id, profile.business_name)}
                          size="large">
                          <SupportOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={profiles.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ProfileList.propTypes = {
  profiles: PropTypes.array.isRequired
};

export default ProfileList;
