import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import { 
  InsertChartOutlinedRounded as BarChartIcon,
  PersonOutlineOutlined as UserIcon,
  DashboardOutlined as ManageIcon, 
  Web as WebsiteIcon, 
  RequestQuoteOutlined as QuoteIcon, 
  VerifiedUserOutlined as AuditIcon,
  TravelExploreOutlined as AutoAuditIcon,
  FacebookOutlined as SocialAuditIcon,
  ScreenSearchDesktopOutlined as ManualAuditIcon,
  GroupsOutlined as ClientsIcon,
  MenuBookOutlined as ResourcesIcon,
  SchoolOutlined as LearningIcon,
  SupportOutlined as SupportIcon,
  SummarizeOutlined as ProposalIcon,
  NoteAltOutlined as DocusignIcon
} from '@mui/icons-material'
import NavItem from './NavItem';

const ADMIN = 'admin';
const REP = 'rep';
const MANAGER = 'manager';
const CLIENT = 'client';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { name, role } = useSelector(state => state.auth);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const items = [
    {
      href: '/hub/reports',
      icon: BarChartIcon,
      title: 'Reports',
      grants: [ADMIN, MANAGER, REP, CLIENT]
    },
    // {
    //   href: '/hub/clients',
    //   icon: ClientsIcon,
    //   title: 'Clients',
    //   grants: [ADMIN, MANAGER, REP]
    // },
    {
      href: '/hub/manage',
      icon: ManageIcon,
      title: 'Manage Accounts',
      grants: [ADMIN, MANAGER]
    },
    {
      href: '/hub/auto_seo_audit',
      icon: AutoAuditIcon,
      title: 'Automatic SEO Audit',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/manual_seo_audit',
      icon: ManualAuditIcon,
      title: 'Manual SEO Audit',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/ads_audit',
      icon: AuditIcon,
      title: 'Google Ads Audit',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/search_ads_quote',
      icon: QuoteIcon,
      title: 'Search Ads Quote',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/social_media_audit',
      icon: SocialAuditIcon,
      title: 'Social Media Audit',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/website_quote',
      icon: WebsiteIcon,
      title: 'Website Quote',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/docusign',
      icon: DocusignIcon,
      title: 'Docusign Request',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/breezepro',
      icon:   ProposalIcon,
      title: 'Breeze Proposal',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/tickets_management',
      icon:   SupportIcon,
      title: 'Support Tickets',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/resources',
      icon:   ResourcesIcon,
      title: 'Resource Centre',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/learning',
      icon:   LearningIcon,
      title: 'Learning',
      grants: [ADMIN, MANAGER, REP]
    },
    {
      href: '/hub/account',
      icon: UserIcon,
      title: 'Profile',
      grants: [ADMIN, MANAGER, REP, CLIENT]
    },
  ];

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {'Hi,'}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {name}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.filter(item => (item.grants.includes(role))).map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 75,
            height: 'calc(100% - 75px)'
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
