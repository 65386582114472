import { toast } from 'react-toastify'

const hashCode = (str) => {
  var h = 0, l = str.length, i = 0;
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + str.charCodeAt(i++) | 0;
  return h;
}

export const toaster = (success,message) => {
    let toastOptions = {
      toastId: hashCode(message)
    };
    if(success){
      toast.success(message,toastOptions);
    }else{
      toast.error(message,toastOptions);
    }
}