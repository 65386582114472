import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid
} from '@mui/material';

const productsArray = [
    {
        id: 1,
        name: 'BreezeNet',
        inputName: 'BreezeNet',
        description: 'Google Ads Pay-Per- Click Management (SEM)',
        avgMediaBuy: 'Avg. $1,500',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 2,
        name: 'BreezeBanner',
        inputName: 'BreezeBanner',
        description: 'Google Display Ads',
        avgMediaBuy: 'Avg. $500',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 3,
        name: 'BreezeTarget',
        inputName: 'BreezeTarget',
        description: 'Google Re-Marketing Ads (REM)',
        avgMediaBuy: 'Avg. $300',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 4,
        name: 'BreezeTube',
        inputName: 'BreezeTube',
        description: 'Youtube Video Ads',
        avgMediaBuy: 'Avg. $500',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 5,
        name: 'BreezeShop',
        inputName: 'BreezeShop',
        description: 'Google Shopping Ads',
        avgMediaBuy: 'Avg. $3,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 6,
        name: 'BreezeWorld',
        inputName: 'BreezeWorld',
        description: 'Multicultural Marketing Services',
        avgMediaBuy: 'Avg. $5,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 7,
        name: 'BreezeLeads',
        inputName: 'BreezeLeads',
        description: 'Google Local Services Ads',
        avgMediaBuy: 'Avg. $9,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 8,
        name: 'BreezeFence',
        inputName: 'BreezeFence',
        description: 'Geo-Fence Targeted Ads',
        avgMediaBuy: 'Avg. $2,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 9,
        name: 'BreezeMatic',
        inputName: 'BreezeMatic',
        description: 'Programmatic Ads',
        avgMediaBuy: 'Avg. $20,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 10,
        name: 'BreezeSocial',
        inputName: 'BreezeSocial',
        description: 'Paid Social Media Ads',
        avgMediaBuy: 'Avg. $1,500',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 11,
        name: 'BreezePost',
        inputName: 'BreezePost',
        description: 'Social Media Management',
        avgMediaBuy: 'Avg. $2,000',
        mediaBuy: {
            dropdown: true,
            options: ['1 Post', '2 Posts', '3 Posts', '4 Posts', '6 Posts', '8 Posts', '10 Posts', '12 Posts'],
        }
    },
    {
        id: 12,
        name: 'BreezeSway',
        inputName: 'BreezeSway',
        description: 'Inﬂuencer Marketing',
        avgMediaBuy: 'Avg. $30,000',
        mediaBuy: {
            dropdown: false,
            options: [],
        }
    },
    {
        id: 13,
        name: 'Creative Pack',
        inputName: 'creative',
        description: 'Creative Package',
        avgMediaBuy: 'Avg. $599',
        mediaBuy: {
            dropdown: true,
            options: ['Monthly', 'Quarterly', 'Bi-annual', 'Annual'],
        }
    },
]

const MediaBuyForm = ({ handleBack, handleSubmit }) => {
    return(
        <Formik
            initialValues={{
                BreezeNet_mbuy: '',
                BreezeBanner_mbuy: '',
                BreezeTarget_mbuy: '',
                BreezeTube_mbuy: '',
                BreezeShop_mbuy: '',
                BreezeWorld_mbuy: '',
                BreezeLeads_mbuy: '',
                BreezeFence_mbuy: '',
                BreezeMatic_mbuy: '',
                BreezeSocial_mbuy: '',
                BreezeSway_mbuy: '',
                creative_mbuy: '',
                BreezePost_mbuy: '',
        
        
                BreezeNet_Agfee: '',
                BreezeBanner_Agfee: '',
                BreezeTarget_Agfee: '',
                BreezeTube_Agfee: '',
                BreezeShop_Agfee: '',
                BreezeWorld_Agfee: '',
                BreezeLeads_Agfee: '',
                BreezeFence_Agfee: '',
                BreezeMatic_Agfee: '',
                BreezeSocial_Agfee: '',
                BreezeSway_Agfee: '',
                creative_Agfee: '',
                BreezePost_Agfee: '',
        
                BreezeNet_res: '',
                BreezeBanner_res: '',
                BreezeTarget_res: '',
                BreezeTube_res: '',
                BreezeShop_res: '',
                BreezeWorld_res: '',
                BreezeLeads_res: '',
                BreezeFence_res: '',
                BreezeMatic_res: '',
                BreezeSocial_res: '',
                BreezeSway_res: '',
                creative_res: '',
                BreezePost_res: '',

                total_one_fee: '',
            }}
            validationSchema={Yup.object().shape({
                BreezeNet_mbuy: Yup.string(),
                BreezeBanner_mbuy: Yup.string(),
                BreezeTarget_mbuy: Yup.string(),
                BreezeTube_mbuy: Yup.string(),
                BreezeShop_mbuy: Yup.string(),
                BreezeWorld_mbuy: Yup.string(),
                BreezeLeads_mbuy: Yup.string(),
                BreezeFence_mbuy: Yup.string(),
                BreezeMatic_mbuy: Yup.string(),
                BreezeSocial_mbuy: Yup.string(),
                BreezeSway_mbuy: Yup.string(),
                creative_mbuy: Yup.string(),
                BreezePost_mbuy: Yup.string(),
        
        
                BreezeNet_Agfee: Yup.string(),
                BreezeBanner_Agfee: Yup.string(),
                BreezeTarget_Agfee: Yup.string(),
                BreezeTube_Agfee: Yup.string(),
                BreezeShop_Agfee: Yup.string(),
                BreezeWorld_Agfee: Yup.string(),
                BreezeLeads_Agfee: Yup.string(),
                BreezeFence_Agfee: Yup.string(),
                BreezeMatic_Agfee: Yup.string(),
                BreezeSocial_Agfee: Yup.string(),
                BreezeSway_Agfee: Yup.string(),
                creative_Agfee: Yup.string(),
                BreezePost_Agfee: Yup.string(),
        
                BreezeNet_res: Yup.string(),
                BreezeBanner_res: Yup.string(),
                BreezeTarget_res: Yup.string(),
                BreezeTube_res: Yup.string(),
                BreezeShop_res: Yup.string(),
                BreezeWorld_res: Yup.string(),
                BreezeLeads_res: Yup.string(),
                BreezeFence_res: Yup.string(),
                BreezeMatic_res: Yup.string(),
                BreezeSocial_res: Yup.string(),
                BreezeSway_res: Yup.string(),
                creative_res: Yup.string(),
                BreezePost_res: Yup.string(),

                total_one_fee: Yup.string(),
            })}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Box component='form' onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        #
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Product
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Description
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Creative + Research
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Average Media Buy
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Media Buy
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Agency Fee
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsArray.map((product) => (
                                    <TableRow
                                        hover
                                        key={product.id}
                                    >
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.description}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.inputName+'_res'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.inputName+'_res']}
                                                variant="outlined"
                                                error={Boolean(touched[product.inputName+'_res'] && errors[product.inputName+'_res'])}
                                                helperText={touched[product.inputName+'_res'] && errors[product.inputName+'_res']}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.avgMediaBuy}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                product.mediaBuy.dropdown ? 
                                                <FormControl fullWidth>
                                                    <Select
                                                        id={product.inputName+'_mbuy'}
                                                        name={product.inputName+'_mbuy'}
                                                        value={values[product.inputName+'_mbuy']}
                                                        onChange={handleChange}
                                                    >
                                                        {
                                                            product.mediaBuy.options.map((option, i) => (
                                                                <MenuItem key={i} value={option}>{option}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl> : 
                                                <TextField
                                                    fullWidth
                                                    name={product.inputName+'_mbuy'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[product.inputName+'_mbuy']}
                                                    variant="outlined"
                                                    error={Boolean(touched[product.inputName+'_mbuy'] && errors[product.inputName+'_mbuy'])}
                                                    helperText={touched[product.inputName+'_mbuy'] && errors[product.inputName+'_mbuy']}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.inputName+'_Agfee'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.inputName+'_Agfee']}
                                                variant="outlined"
                                                error={Boolean(touched[product.inputName+'_Agfee'] && errors[product.inputName+'_Agfee'])}
                                                helperText={touched[product.inputName+'_Agfee'] && errors[product.inputName+'_Agfee']}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{my:1, p:2}}>
                        <Typography variant='h5'>Total One Time Fee</Typography>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name={'total_one_fee'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.total_one_fee}
                                    variant="outlined"
                                    error={Boolean(touched.total_one_fee && errors.total_one_fee)}
                                    helperText={touched.total_one_fee && errors.total_one_fee}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{p:1, my: 1}}>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** DO NOT USE COMMAS IN NUMBERS.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** For any field left unfilled a N/A will appear automatically.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** In the total agency fees you can indicate a numerical value or the wording - FREE & Included if you choose.</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

export default MediaBuyForm;