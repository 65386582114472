import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductsForm = ({products, handleSubmit}) => {
    return(
        <Formik
            initialValues={{
                company_name: '',
                package_id: 1,
                products: []
            }}
            validationSchema={Yup.object().shape({
                company_name: Yup.string().max(100).required('Company Name is required'),
                package_id: Yup.number().required('This field is required'),
                products: Yup.array(Yup.string()).required('This field is required')
            })}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Company Name"
                                name="company_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.company_name}
                                variant="outlined"
                                error={Boolean(touched.company_name && errors.company_name)}
                                helperText={touched.company_name && errors.company_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="package_id">Select client package</InputLabel>
                                <Select
                                    labelId="package_id"
                                    id="package_id"
                                    name='package_id'
                                    value={values.package_id}
                                    label="Select client package"
                                    onChange={handleChange}
                                    required
                                >
                                    <MenuItem value={1}>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="products">Select the products</InputLabel>
                                <Select
                                    labelId="products"
                                    id="products"
                                    name='products'
                                    value={values.products}
                                    label="Select the products"
                                    onChange={handleChange}
                                    required
                                    multiple
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        products.map(product => (
                                            <MenuItem key={product.id} value={product.product_name}>
                                                <Checkbox checked={values.products.indexOf(product.product_name) > -1} />
                                                <ListItemText primary={product.product_name} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

export default ProductsForm;