import { useState, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import HubSideBar from './HubSideBar';
import Footer from './Footer';

const HubLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const HubLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const HubLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const HubLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const HubLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  
  const location = useLocation()
  const hubContentRef = useRef(null)

  useEffect(() => {
    if(hubContentRef?.current)
      hubContentRef.current.scrollTop = 0;
  },[location])

  return (
    <HubLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <HubSideBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <HubLayoutWrapper>
        <HubLayoutContainer>
          <HubLayoutContent ref={hubContentRef}>
            <Outlet />
            <Footer />
          </HubLayoutContent>
        </HubLayoutContainer>
      </HubLayoutWrapper>
    </HubLayoutRoot>
  );
};

export default HubLayout;
