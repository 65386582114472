import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card, CardContent,
} from '@mui/material';
import axios from '../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../redux/actionCreator'
import BreezeDashText from '../assets/BreezeDashText.png'
import GoogleLogin from 'react-google-login';
import Loader from '../components/auxiliary/loader/loader';

const AMLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector(state => state.auth)
  const [loading, loader] = useState(false)

  const googleLoginHandler = async(result) => {
    if(result.tokenId){
      try{
        loader(true);
        const response = await axios.post('google_login',{token_id: result.tokenId});
        if(response?.data){
          if(response.data.success){
            let {data} = response.data;
            dispatch(login(data.token, data.first_name+' '+data.last_name, data.role))
          }else{
            toaster(false, response.data.message)
          }
        }else{
          toaster(false, "An error occurred while logging in!")
        }
      }catch(err){
        toaster(false, "An error occurred while logging in!")
      }finally{
        loader(false);
      }
    }else{
      toaster(false, "Login attempt through Google failed!")
    }
  }

  const loginErrorHandler = (response) => {
    if(response.error){
      if(response.error === "access_denied"){
          toaster(false, "Failed to login: permission denied to the required scopes");
      }else{
        toaster(false, response.error);
      }
    }
  }

  useEffect(() => {
    if(isAuthenticated)
      navigate('/hub/reports?first=true', { replace: true });
  // eslint-disable-next-line
  },[isAuthenticated])

  return (
    <>
      <Helmet>
        <title>Login | BreezeDash</title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{backgroundColor: 'transparent'}}>
            <CardContent>
                <Box sx={{ mb: 3, display: 'flex', justifyContent:'center' }}>
                  <img src={BreezeDashText} alt='' style={{width: 'auto', height: '6.25rem'}} />
                </Box>
                <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                  <GoogleLogin
                    clientId="738742947505-00i07a00b4o1gtt22stls494gj39gi20.apps.googleusercontent.com"
                    buttonText="Sign-in with Google"
                    onSuccess={googleLoginHandler}
                    onFailure={loginErrorHandler}
                    cookiePolicy={'single_host_origin'}
                    className='GoogleLoginBtn'
                    isSignedIn={false}
                  />
                </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      {loading && <Loader />}
    </>
  );
};

export default AMLogin;
