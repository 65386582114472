import { Helmet } from 'react-helmet';
import ReportComponent from '../../components/reports/Report-iframeComponent'
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
//import { Typography, Box } from '@mui/material';
import { Box, Container, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ChevronDown as ExpandIcon } from 'react-feather'
import KeywordsRanking from 'src/components/ranking/KeywordsRanking';

const QuestRanking = () => {

    const [loading, loader] = useState(false)
   // const [url, setUrl] = useState('')
    const [campaigns, setCampaigns] = useState([])
    // const [campaignDetails, setCampaignDetails] = useState({});
    // const [expanded, setExpanded] = useState(false);
    // const [url_id, setUrlId] = useState(0);
    
    const fetchCampaigns = async() => {
        loader(true);
        try{
            const response = await axios.post('profile_keywords',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setCampaigns(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    // const fetchCampaignDetails = async(id) => {
    //     loader(true);
    //     try{
    //         const response = await axios.post('keyword_positions',
    //         {keyword_id: id,url_id: url_id, date_to: new Date().toMySqlDate(), date_from: new Date(new Date().setDate(new Date().getDate() - 30)).toMySqlDate()},
    //         {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
    //         );
    //         if(response?.data){
    //           if(response.data.success){
    //             setCampaignDetails(response.data.data)
    //           }else{
    //             toaster(false, response.data.message)
    //           }
    //           loader(false);
    //         }else{
    //           toaster(false, "An error occurred while fetching the reports!")
    //           loader(false);
    //         }
    //     }catch(err){
    //         toaster(false, "An error occurred while fetching the reports!")
    //         loader(false);
    //     }
    // }

    // const handleChange = (panel,url_id) => (event, newExpanded) => {
    //     setUrlId(url_id);
    //     setExpanded(newExpanded ? panel : false);
    // };

    // const extractKeywordPositions = () => {
    //     console.log(campaignDetails);
    //     if(campaignDetails?.keywords){
    //         return campaignDetails.keywords.map(keyword => {
    //             let positions = Object.keys(keyword.positions);
    //             let oldRank = keyword.positions[campaignDetails.date_from] || keyword.positions[positions[positions.length - 1]] || 100,
    //                 currRank = keyword.positions[campaignDetails.date_to] || keyword.positions[positions[0]] || 100
    //             return({
    //                 id: keyword.id,
    //                 name: keyword.name,
    //                 region: keyword.region,
    //                 currRank: currRank,
    //                 oldRank: oldRank,
    //                 difference: Math.abs(currRank - oldRank),
    //                 trend: currRank <= oldRank ? 'up' : 'down'
    //             })
    //         })
    //     }else{
    //         return []
    //     }
    // }

     const extractKeywordPositions = () => {
        if(campaigns.length){
            return campaigns.filter(keyword => {
                        if(keyword.tags.length > 0 ) {
                           if(!keyword.tags.some(tag => tag.name.toLowerCase() === 'breezeextra'))
                           {
                            return keyword;
                           }                    
                        }
                        else 
                            return keyword;
                   })
            .map(keyword => {
                let oldRank = keyword.historic_positions[keyword.historic_positions.length - 1] || 100,
                    currRank = keyword.position || 100
                return({
                    id: keyword.id,
                    name: keyword.query,
                    region: keyword.local_search,
                    currRank: currRank,
                    oldRank: oldRank,
                    difference: Math.abs(currRank - oldRank),
                    trend: currRank <= oldRank ? 'up' : 'down'
                })
            })
        }else{
            return []
        }
    }

    useEffect(() => {
        fetchCampaigns();
    }, [])

    // useEffect(() => {
    //     if(expanded){
    //         fetchCampaignDetails(expanded);
    //     }
    // }, [expanded])

     let keywords = extractKeywordPositions();

    // const fetchUrl = async() => {
    //     loader(true);
    //     try{
    //         const response = await axios.post('ranking',
    //         {profile_id: localStorage.profileId},
    //         {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
    //         );
    //         if(response?.data){
    //           if(response.data.success){
    //             setUrl(response.data.data.url)
    //           }else{
    //             toaster(false, response.data.message)
    //           }
    //           loader(false);
    //         }else{
    //           toaster(false, "An error occurred while fetching the reports!")
    //           loader(false);
    //         }
    //     }catch(err){
    //         toaster(false, "An error occurred while fetching the reports!")
    //         loader(false);
    //     }
    // }

    // useEffect(() => {
    //     fetchUrl();
    // }, [])
    return(
        <>
            <Helmet>
                <title>BreezeQuest Ranking | BreezeDash</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'BreezeQuest (Ranking)'}</Typography>
                <Box sx={{p: 1, width:1}}>
                    {campaigns.length ? <KeywordsRanking keywords={keywords} />
                        // campaigns.map(campaign => (
                        //     <Accordion key={campaign.id} expanded={expanded === campaign.id} onChange={handleChange(campaign.id,campaign.search_keyword_url_id)}>
                        //         <AccordionSummary expandIcon={<ExpandIcon />}>
                        //             <Typography variant='h5' sx={{color:'primary.main'}}>{campaign.query}{campaign.local_search ? ' ('+campaign.local_search+')':''}</Typography>
                        //         </AccordionSummary>
                        //         <AccordionDetails>
                        //             <Box sx={{display: 'flex', justifyContent:'space-around'}}>
                        //                 <Typography variant='body1' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>{'From: '+new Date(new Date().setDate(new Date().getDate() - 30)).toMySqlDate()}</Typography>
                        //                 <Typography variant='body1' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>{'To: '+new Date().toMySqlDate()}</Typography>
                        //             </Box>
                        //             <KeywordsRanking keywords={keywords} />
                        //         </AccordionDetails>
                        //     </Accordion>
                        // ))
                        : 
                        <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>
                    }
                     {/* <Typography variant='h3' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>We are currently upgrading our platform.</Typography>
                     <Typography variant='h3' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>In the interim, please manually request a report from <strong><a href='mailto:SEOteam@breezemaxweb.com'>SEOteam@breezemaxweb.com</a></strong> and we will send it over as soon as possible.</Typography>
                    <Typography variant='h3' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>Thanks for your patience during this transition.</Typography> */}
                </Box>
            </Container>
            {/* {!!url ? <ReportComponent product='BreezeQuest (Ranking)' url={url} /> : <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>} */}
            </Box>
            {loading && <Loader />}
        </>
        )
}

export default QuestRanking;