import { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import BreezeproLeads from 'src/components/breezepro/BreezeproLeads';
import CreateProposal from 'src/components/breezepro/CreateProposal';

const initLeadObj = {
  id:0,
  client_name: '',
  client_email: ''
}

const BreezePro = () => {

  const navigate = useNavigate();
  const [selectedLead, setSelectedLead] = useState(initLeadObj)

  const createProposalHandler = (prospect) => {
    setSelectedLead(prospect);
    navigate('create_proposal');
  }

  return (
      <>
      <Helmet>
        <title>BreezePro | BreezeDash</title>
      </Helmet>
      <Box
        sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
        }}
      >
        <Routes>
          <Route path='' element={<BreezeproLeads createProposal={createProposalHandler} />} />
          <Route path='create_proposal' element={<CreateProposal lead={selectedLead} />} />
        </Routes>
      </Box>
      </>
  )
}

export default BreezePro;