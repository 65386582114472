import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TextField
} from '@mui/material';

const productsArray = [
    {
        id: 1,
        name: 'BreezeTag',
        description: 'Google Analytics & Search Console',
        rate_card: '$499'
    },
    {
        id: 2,
        name: 'BreezePix',
        description: 'Pixel Tracking - Social Media',
        rate_card: '$499'
    },
    {
        id: 3,
        name: 'BreezeSite',
        description: 'Custom Website for Your Needs	',
        rate_card: 'Custom'
    },
    {
        id: 4,
        name: 'BreezeApp',
        description: 'Mobile App Development',
        rate_card: 'Custom'
    },
    {
        id: 5,
        name: 'BreezeVideo',
        description: 'Video Production Services',
        rate_card: 'Custom'
    },
    {
        id: 6,
        name: 'BreezeShots',
        description: 'Professional Photography',
        rate_card: 'Custom'
    },
]

const OneTimeFeesForm = ({ handleBack, handleSubmit }) => {
    return(
        <Formik
            initialValues={{
                BreezeTag_Discount: '',
                BreezeTag_Total: '',
                BreezePix_Discount: '',
                BreezePix_Total: '',
                BreezeSite_Discount: '',
                BreezeSite_Total: '',
                BreezeApp_Discount: '',
                BreezeApp_Total: '',
                BreezeVideo_Discount: '',
                BreezeVideo_Total: '',
                BreezeShots_Discount: '',
                BreezeShots_Total: '',
            }}
            validationSchema={Yup.object().shape({
                BreezeTag_Discount: Yup.string(),
                BreezeTag_Total: Yup.string(),
                BreezePix_Discount: Yup.string(),
                BreezePix_Total: Yup.string(),
                BreezeSite_Discount: Yup.string(),
                BreezeSite_Total: Yup.string(),
                BreezeApp_Discount: Yup.string(),
                BreezeApp_Total: Yup.string(),
                BreezeVideo_Discount: Yup.string(),
                BreezeVideo_Total: Yup.string(),
                BreezeShots_Discount: Yup.string(),
                BreezeShots_Total: Yup.string(),
            })}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Box component='form' onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        #
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Product
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Description
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}} >
                                        Rate Card
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Discount
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', fontWeight:'bold'}}>
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsArray.map((product) => (
                                    <TableRow
                                        hover
                                        key={product.id}
                                    >
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {product.description}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                defaultValue={product.rate_card}
                                                name={'rate_card'}
                                                required
                                                variant="outlined"
                                                disabled
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.name+'_Discount'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.name+'_Discount']}
                                                variant="outlined"
                                                error={Boolean(touched[product.name+'_Discount'] && errors[product.name+'_Discount'])}
                                                helperText={touched[product.name+'_Discount'] && errors[product.name+'_Discount']}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                name={product.name+'_Total'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values[product.name+'_Total']}
                                                variant="outlined"
                                                error={Boolean(touched[product.name+'_Total'] && errors[product.name+'_Total'])}
                                                helperText={touched[product.name+'_Total'] && errors[product.name+'_Total']}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{p:1, my: 1}}>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** DO NOT USE COMMAS IN NUMBERS.</Typography>
                        <Typography sx={{fontWeight: 700}} variant='body2'>** For any field left unfilled a N/A will appear automatically.</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

export default OneTimeFeesForm;