import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography, Card, CardContent
} from '@mui/material';
import { withStyles } from '@mui/styles';
import axios from '../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import { useSelector } from 'react-redux'
import BreezeDashText from '../assets/BreezeDashText.png'

const CustomTextField = withStyles({
  root:{
    '& label,& label.Mui-focused, &:hover label': {
      color: '#fff'
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -14px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    } 
  }
})(TextField)

const ForgotPassword = () => {
  const {authToken} = useSelector(state => state.auth)
  const navigate = useNavigate();

  const submitBtnHandler = async({email}) => {
    try{
      const response = await axios.post('forgot-password',{email},{headers: {'Authorization': 'Bearer '+ authToken}});
      if(response?.data){
        if(response.data.success){
          toaster(true, "A password reset link has been sent to your email address!")
          navigate('/login', { replace: true });
        }else{
          toaster(false, response.data.message)
        }
      }else{
        toaster(false, "An error occurred while sending the reset link!")
      }
    }catch(err){
      toaster(false, "An error occurred while sending the reset link!")
    }
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | BreezeDash</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              })}
              onSubmit={submitBtnHandler}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3, display: 'flex', justifyContent:'center' }}>
                      <img src={BreezeDashText} alt='' style={{width: 'auto', height: '6.25rem'}} />
                  </Box>
                  <CustomTextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <Typography
                    color="primary.contrastText"
                    gutterBottom
                    variant="body1"
                  >
                    You will receive an e-mail with the link to reset your password, in your registered email address.
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Button
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                  <Typography
                    color="primary.contrastText"
                    variant="body1"
                    textAlign='center'
                  >
                    <Link color='primary.contrastText' component={RouterLink} to="/login" variant="h6" underline="hover">
                      Back to Login
                    </Link>
                  </Typography>
                </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
