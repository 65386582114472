import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Stack,
} from '@mui/material';
import AccountProfileDetails from '../components/account/AccountProfileDetails';
import ChangePassword from 'src/components/account/ChangePassword';

const Account = () => (
  <>
    <Helmet>
      <title>Profile | Material Kit</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <AccountProfileDetails />
          <ChangePassword />
        </Stack>
      </Container>
    </Box>
  </>
);

export default Account;
