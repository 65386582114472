import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from '../../axios'
import { toaster } from 'src/components/auxiliary/toaster';
import Loader from 'src/components/auxiliary/loader/loader';
import { Box, Container, Typography, List, ListItem } from '@mui/material';

const QuestBacklinks = () => {
    const [loading, loader] = useState(false)
    const [links, setLinks] = useState([])

    const fetchLinks = async() => {
        loader(true);
        try{
            const response = await axios.post('backlinks',
            {profile_id: localStorage.profileId},
            {headers: {'Authorization': 'Bearer '+ localStorage.authToken}}
            );
            if(response?.data){
              if(response.data.success){
                setLinks(response.data.data)
              }else{
                toaster(false, response.data.message)
              }
              loader(false);
            }else{
              toaster(false, "An error occurred while fetching the reports!")
              loader(false);
            }
        }catch(err){
            toaster(false, "An error occurred while fetching the reports!")
            loader(false);
        }
    }

    useEffect(() => {
        fetchLinks();
    }, [])

    return(
        <>
            <Helmet>
                <title>Backlinks | BreezeDash</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <Typography variant='h2' sx={{color:'primary.main', fontWeight: 'medium',my: 2}}>{'BreezeQuest (Backlinks)'}</Typography>
                <Box sx={{p: 1, width:1}}>
                    <List component='ol'>
                        {
                            links.length ? links.map((link, i) => (
                                <ListItem key={i}>
                                    <Typography variant='body1'>{i+1 +'. '+ link.links}</Typography>
                                </ListItem>
                            )) : <Typography variant='h2' sx={{color:'text.primary', fontWeight: 'medium',my: 2, textAlign: 'center'}}>No data available!</Typography>
                        }
                    </List>
                </Box>
            </Container>
            </Box>
            {loading && <Loader />}
        </>
        )

}

export default QuestBacklinks;